import React from "react";
import { Box, Typography } from "@mui/material";
import { semibold12 } from "src/theme/customStyles";
import { formatDate } from "src/utils/helpers/helper";

export const TransactionCard = ({ icon, title, subtitle, time }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      mb: 2,
      mt: 1,
      flexDirection: "row",
      width: "550px",
    }}
  >
    <Box
      sx={{
        mr: 1,
        display: "flex",
        justifyContent: "center",
        width: "45px",
        height: "45px",
      }}
    >
      {icon}
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "center",
        width: "350px",
        gap: 3,
        flexWrap: "wrap",
      }}
    >
      <Typography>#{title}</Typography>
      <Typography
        sx={{
          ...semibold12,
          fontWeight: 400,
          width: "200px",
          textAlign: "left",
        }}
      >
        {subtitle}
      </Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        widt: "50px",
        height: "40px",
      }}
    >
      <Typography
        sx={{
          fontSize: "10px",
          fontWeight: 400,
        }}
      >
        {formatDate(time)}
      </Typography>
    </Box>
  </Box>
);
