import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useState } from "react";
import { CreateAdminUser, GetAdmins } from "src/services/api";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { pageContainer } from "src/utils/customStyles";

const AdminList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [value, setValue] = useState(
    Number(localStorage.getItem("tabValueAdmin")) || 0
  );
  const [open, setOpen] = React.useState(true);
  const [data, setData] = useState([]);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [admin, setAdmin] = useState({
    UserName: "",
    Email: "",
    Password: "",
  });
  const handleChangeAdmin = (prop) => (event) => {
    setAdmin({ ...admin, [prop]: event.target.value });
    console.log(admin);
    console.log("----");
  };
  const handleChange = (newValue) => {
    setValue(newValue);
    localStorage.setItem("tabValueAdmin", newValue);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Burada API çağrısını entegre edin
    console.log("Submitting", admin);
  };
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "UserName",
        header: "Kullanıcı Adı",
        size: 30,
      },
      {
        accessorKey: "Roles",
        header: "Roller",
        size: 120,
        Cell: ({ cell }) => cell.getValue().join(", "),
      },
    ],
    []
  );
  useEffect(() => {
    if (value === 0) {
      const getadmin = async () => {
        try {
          const response = await GetAdmins();
          console.log(response);
          setData(response.Data.userAdminRoles);
          if (response.Success === false) {
            setAlertStatus(false);
          } else {
            setAlertStatus(true);
          }
          setAlertMessage(response.Message);
          setOpenAlert(true);
        } catch (error) {
          setAlertMessage(error.Message);
          setAlertStatus(false);
          setOpenAlert(true);
          setOpen(false);
          console.error("Hata oluştu:", error);
        } finally {
          setTimeout(() => {
            setOpenAlert(false);
            setOpen(false);
          }, 1000);
        }
      };
      getadmin();
    } else {
      // value 0 değilse loading işaretini kapat
      setOpen(false);
    }
  }, [value]);

  const onClickCreateAdmin = async () => {
    if (admin.UserName !== "" && admin.Email !== "" && admin.Password !== "") {
      try {
        setOpen(true);
        const response = await CreateAdminUser(admin);
        console.log(response);
        response.Success == false
          ? setAlertStatus(false)
          : setAlertStatus(true);
        setAlertMessage(response.Message);
        setOpenAlert(true);
      } catch (error) {
        setAlertMessage(error.Message);
        setAlertStatus(false);
        setOpenAlert(true);
        console.error("Hata oluştu:", error);
      } finally {
        setTimeout(() => {
          setOpenAlert(false);
          setOpen(false);
        }, 1000);
      }
    } else {
      setAlertMessage("Boş alan bırakılamaz!");
      setAlertStatus(false);
      setOpenAlert(true);
    }
  };
  const table = useMaterialReactTable({
    columns,
    data,
    muiTableBodyCellProps: {
      sx: { backgroundColor: theme.palette.table },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.palette.table,
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: theme.palette.table,
      },
    },
    muiTopToolbarProps: {
      sx: {
        marginTop: "36px",
        backgroundColor: theme.palette.table,
      },
    },
    enableRowActions: true,

    renderRowActions: ({ row }) => {
      return (
        <Button
          sx={{ fontSize: "12px", width: "150px" }}
          onClick={(e) => {
            e.stopPropagation();
            navigate("/dashboard/rolIslemleri", {
              state: { userId: row.original.UserId },
            });
          }}
        >
          Rol Ekle
        </Button>
      );
    },
  });
  return (
    <Box sx={pageContainer}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>{" "}
      <Typography
        variant="h3"
        sx={{
          letterSpacing: "3px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
          paddingLeft: "3rem",
        }}
      >
        Yöneticiler
        <Divider
          sx={{
            width: "100px",
            backgroundColor: "primary.goldtag",
            height: 2,
          }}
        />
      </Typography>
      <Box
        sx={{
          justifyContent: "space-evenly",
          display: "flex",
          gap: 0.5,
        }}
      >
        <Button
          key={"btn0" + value}
          sx={{
            backgroundColor: value === 0 ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange(0)}
          variant="contained"
          fullWidth
        >
          Admin Listele
        </Button>
        <Button
          key={"btn1" + value}
          sx={{
            backgroundColor: value === 1 ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange(1)}
          variant="contained"
          fullWidth
        >
          Admin Ekle
        </Button>
      </Box>
      {data !== null && value == 0 && <MaterialReactTable table={table} />}
      {value == 1 && (
        <Card
          sx={{
            width: { md: "30rem", xs: "20rem" },
            mx: "auto",
            mt: 5,
          }}
        >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Admin Ekle
            </Typography>
            <Divider
              sx={{
                width: "10%",
                backgroundColor: "primary.goldtag",
                height: 3,
              }}
            />
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 4 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Kullanıcı Adı"
                    value={admin.UserName}
                    onChange={handleChangeAdmin("UserName")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="E-mail"
                    value={admin.Email}
                    onChange={handleChangeAdmin("Email")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Şifre"
                    value={admin.Password}
                    onChange={handleChangeAdmin("Password")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => onClickCreateAdmin()}
                  >
                    Admin Ekle
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      )}
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminList;
