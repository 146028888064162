import * as React from "react";
import { memo } from "react";
const UsersIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={16.059} {...props}>
    <path
      d="M27.588 14.912a3.706 3.706 0 1 0-1.124-7.239 6.1 6.1 0 0 1 1.112 3.533 6.215 6.215 0 0 1-1.112 3.533 3.693 3.693 0 0 0 1.124.173zm-6.176 0a3.706 3.706 0 1 0-3.706-3.706 3.69 3.69 0 0 0 3.706 3.706zm8.178 2.668a4.571 4.571 0 0 1 1.7 3.508v2.471H35v-2.471c0-1.902-2.928-3.076-5.411-3.508zm-8.178-.2c-2.471 0-7.412 1.235-7.412 3.706v2.471h14.824v-2.469c0-2.47-4.942-3.706-7.412-3.706z"
      data-name="Icon material-group-add"
      style={{
        fill: props.color ? props.color : props.darkMode ? "#383838" : "#fff",
      }}
      transform="translate(-14 -7.5)"
    />
  </svg>
);
const Memo = memo(UsersIcon);
export default Memo;
