import * as React from "react";
const Event = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} {...props}>
    <g transform="translate(12268 10871)">
      <rect
        width={45}
        height={45}
        fill={props.darkMode ? "#fff" : "#383838"}
        rx={22.5}
        transform="translate(-12268 -10871)"
      />
      <path
        fill="none"
        d="M-12259-10862h27v27h-27z"
        data-name="Rectangle 1412"
      />
      <g fill="#d6b87c" data-name="Group 42471">
        <path
          fillRule="evenodd"
          d="M-12251.547-10860.368a.889.889 0 0 1 .864.913v1.826h10.366v-1.826a.865.865 0 1 1 1.728 0v1.826h.864a3.558 3.558 0 0 1 3.455 3.651v13.694a3.558 3.558 0 0 1-3.455 3.652h-15.55a3.558 3.558 0 0 1-3.455-3.652v-13.694a3.558 3.558 0 0 1 3.455-3.651h.864v-1.826a.889.889 0 0 1 .864-.913Zm15.55 10.95a1.779 1.779 0 0 0-1.728-1.826h-15.55a1.779 1.779 0 0 0-1.727 1.826v9.129a1.779 1.779 0 0 0 1.727 1.831h15.55a1.779 1.779 0 0 0 1.728-1.826Z"
          data-name="Path 53344"
        />
        <path
          stroke="#d6b87c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M-12251.843-10844.486h2.967v2.967h-2.967z"
          data-name="Rectangle 1411"
        />
      </g>
    </g>
  </svg>
);
export default Event;
