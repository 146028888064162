import { Box, Button, Snackbar, Alert } from "@mui/material";
import React, { useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { handleExportData, handleExportRows } from "src/utils/helpers/helper";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import { GetUsersTransactions } from "src/services/AdminPanelApis/UsersTransactionsApi";
import DateRangePicker from "src/components/DatePicker/DateRangePicker";
import { formatDate } from "src/utils/helpers/helper";
import dayjs from "dayjs";
import {
  CommoditySuffixValue,
  TransactionTypesWithNum,
} from "src/utils/helpers";

const AllTransactionsTable = () => {
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [errorEnd, setErrorEnd] = useState(false);
  const [errorStart, setErrorStart] = useState(false);

  const [startDate, setStartDate] = useState(dayjs("01/01/2018"));
  const [endDate, setEndDate] = useState(dayjs("09/09/2024"));

  const [startDay, setStartDay] = React.useState("");
  const [startMonth, setStartMonth] = React.useState("");
  const [startYear, setStartYear] = React.useState("");

  const [endDay, setEndDay] = React.useState("");
  const [endMonth, setEndMonth] = React.useState("");
  const [endYear, setEndYear] = React.useState("");

  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
  });

  const [data, setData] = useState([]);
  const [boolean, setBoolean] = useState(false);

  const fetchUserTransactions = async () => {
    try {
      const response = await GetUsersTransactions({
        UserId: "-1",
        Page: pagination.page,
        PageSize: pagination.pageSize,
        StartDate: startDate
          ? startDate
          : `${startYear}-${startMonth}-${startDay}`,
        EndDate: endDate ? endDate : `${endYear}-${endMonth}-${endDay}`,
        TransactionType: 0,
      });
      if (response.Success) {
        const data = response.Data;
        const array = data.map((item) => ({
          UserId: item.Id,
          Name: item.SourceId,
          TransactionType: TransactionTypesWithNum[item.TransactionType],
          CommoditySuffix: CommoditySuffixValue[item.CommoditySuffix],
          CommodityAmount: `${item.CommodityAmount} gr`,
          TlAmount: `${item.TlAmount} TL`,
          TransactionDate: formatDate(item.TransactionDate),
        }));
        setData(array);
      } else {
        console.error("API error: ", response.Message);
        setOpenAlert(true);
      }
    } catch (error) {
      console.error("Error fetching GetAllUser:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlefetch = () => {
    if (startDate == null) {
      setOpenAlert(true);
    } else if (endDate == null) {
      setOpenAlert(true);
    } else {
      setLoading(true);
      setBoolean(true);
      fetchUserTransactions();
    }
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "UserId",
        header: "UserId",
        size: 120,
      },
      {
        accessorKey: "Name",
        header: "İsim",
        size: 180,
      },
      {
        accessorKey: "TransactionType",
        header: "İşlem Türü",
        size: 30,
      },
      {
        accessorKey: "CommoditySuffix",
        header: "Emtia Türü",
        size: 30,
      },
      {
        accessorKey: "CommodityAmount",
        header: "Miktar",
        size: 20,
      },
      {
        accessorKey: "TlAmount",
        header: "TL Miktarı",
        size: 30,
      },
      {
        accessorKey: "TransactionDate",
        header: "İşlem Zamanı",
        size: 30,
      },
    ],
    []
  );

  const handlePaginationChange = (pagination) => {
    setPagination(pagination);
  };

  const handleBack = () => {
    const newPage = pagination.page - 1;
    if (newPage > 0) {
      setPagination({ page: newPage, pageSize: 10 });
    }
    console.log(pagination.page);
  };
  const handleNext = () => {
    const newPage = pagination.page + 1;
    setPagination({ page: newPage, pageSize: 10 });
    console.log(pagination.page);
  };

  React.useEffect(() => {
    if (pagination.page) {
      handlefetch();
    }
  }, [pagination.page]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    state: { pagination },
    enableBottomToolbar: false,
    manualPagination: true,
    onPaginationChange: handlePaginationChange,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          marginBottom: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(data, "Transferler")}
          startIcon={<FileDownloadIcon />}
        >
          Tüm Verileri Dışa Aktar
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(
              table.getPrePaginationRowModel().rows,
              "Transferler"
            )
          }
          startIcon={<FileDownloadIcon />}
        >
          Tüm Satırları Dışa Aktar
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() =>
            handleExportRows(table.getRowModel().rows, "Transferler")
          }
          startIcon={<FileDownloadIcon />}
        >
          Bu Sayfadaki Satırları Dışa Aktar
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() =>
            handleExportRows(table.getSelectedRowModel().rows, "Transferler")
          }
          startIcon={<FileDownloadIcon />}
        >
          Seçilmiş Satırları Dışa Aktar
        </Button>
      </Box>
    ),
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
          marginBottom: 3,
        }}
      >
        {/* DATE PICKER */}
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setStartDay={setStartDay}
          setStartMonth={setStartMonth}
          setStartYear={setStartYear}
          setEndDay={setEndDay}
          setEndMonth={setEndMonth}
          setEndYear={setEndYear}
          errorStart={errorStart}
          errorEnd={errorEnd}
          setErrorStart={setErrorStart}
          setErrorEnd={setErrorEnd}
          type={"range"}
        />

        <Button
          variant="contained"
          onClick={handlefetch}
          sx={{ marginLeft: 3, height: 35 }}
        >
          Listele
        </Button>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "500px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingOverlay isOpen={loading} />
        </Box>
      ) : boolean ? (
        <>
          <MaterialReactTable table={table} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={handleBack}
              sx={{ marginLeft: 3, height: 35 }}
            >
              Geri
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ marginLeft: 3, height: 35 }}
            >
              İleri
            </Button>
          </Box>
        </>
      ) : null}

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          Tüm alanları doldurun!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AllTransactionsTable;
