import { generalAxiosInstance } from "../axios/axiosConfig";

export async function AppReport() {
  try {
    const response = await generalAxiosInstance.get("/AppReport");
    return response.data;
  } catch (error) {
    return error.response?.data || "An error occurred";
  }
}
