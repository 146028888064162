import { alpha } from "@mui/material/styles";

const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};
const palette = {
  common: { black: "#000", white: "#fff" },
  primary: {
    lighter: "#FFF7CD",
    light: "#FFE16A",
    main: "#d6b87c",
    dark: "#FFC107",
    darker: "#7A4F01",
    custom: "#36241C",
    contrastText: "#FFF",
    goldtag: "#d6b87c",
  },

  gold: {
    main: "#d6b87c",
  },
  silver: {
    main: "#B5B5B6",
  },

  platin: {
    main: "#ADADAD",
  },

  shadow: {
    main: "#383838",
  },

  svgBg: {
    main: "#f9f9f9f9",
  },

  secondary: {
    lighter: "#84A9FF",
    light: "#3366FF",
    main: "#1939B7",
    dark: "#091A7A",
    darker: "#04297A",
    contrastText: "#fff",
  },
  info: {
    lighter: "#74CAFF",
    light: "#1890FF",
    main: "#0C53B7",
    dark: "#04297A",
    darker: "#04297A",
    contrastText: "#fff",
  },
  success: {
    lighter: "#AAF27F",
    light: "#54D62C",
    main: "#229A16",
    dark: "#08660D",
    darker: "#08660D",
    contrastText: GREY[800],
  },
  warning: {
    lighter: "#FFE16A",
    light: "#FFC107",
    main: "#B78103",
    dark: "#7A4F01",
    darker: "#36241C",
    custom: "#36241C",
    contrastText: GREY[800],
  },
  error: {
    lighter: "#FFA48D",
    light: "#FF4842",
    main: "#B72136",
    dark: "#7A0C2E",
    darker: "#7A0C2E",
    contrastText: "#fff",
  },
  paper: {
    goldPaper: "#dfd9c4",
    silverPaper: "#D3D3D3",
    platinPaper: "#81d9dd",
  },
  paperText: {
    gold: "#a89061",
    silver: "#808080",
    platin: "#579194",
  },
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
    navsec: GREY[500],
  },
  background: {
    paper: "#f8f8f8",
    default: GREY[100],
    neutral: "#ffffff",
    card: "#fff",
    price: "#eaeaea",
  },
  action: {
    active: GREY[300],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  nav: "#fdfdfd",
  navText: "#383838",
  table: "#ffffff",
};
export default palette;
