import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
const EmtiaSelect = ({
  emtia,
  setEmtia,
  errorEmtia,
  setErrorEmtia,
  setSelectedEmtia,
}) => {
  const emtias = [
    { value: 1, label: "Altın" },
    { value: 2, label: "Gümüş" },
    { value: 3, label: "Platin" },
    { value: 101, label: "USD" },
  ];
  const handleChangeEmtia = (event) => {
    setEmtia(event.target.value);
    setErrorEmtia(false);
    setSelectedEmtia(true);
  };

  return (
    <FormControl sx={{ minWidth: 155 }} error={errorEmtia}>
      <InputLabel id="demo-simple-select-label">Emtia Seçiniz</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="emo-simple-select"
        value={emtia}
        onChange={handleChangeEmtia}
        label="Emtia Seçiniz"
      >
        {emtias.map((emtia) => (
          <MenuItem key={emtia.value} value={emtia.value}>
            {emtia.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EmtiaSelect;
