import * as React from "react";
const Users = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      d="M0 0h24v24H0z"
      data-name="Rectangle 677"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M10 18a5 5 0 1 0-5-5 5 5 0 0 0 5 5zm0 1.25c-5.887 0-10 3.083-10 7.5V28h20v-1.25c0-4.418-4.114-7.501-10-7.501z"
      data-name="Path 51372"
      style={{
        fill: props.color,
      }}
      transform="translate(2.001 -5.999)"
    />
  </svg>
);
export default Users;
