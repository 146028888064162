import React from "react";
import { useTheme } from "@mui/material/styles";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

function LoadingOverlay({ isOpen }) {
  const darkMode = useSelector((state) => state.darkMode.value);
  const theme = useTheme();

  return (
    <Backdrop
      open={isOpen}
      sx={{
        backgroundColor: darkMode ? "#ffffff83" : "#00000087",
        color: "black",
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
}

export default LoadingOverlay;
