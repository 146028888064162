import * as React from "react";
const Giftbox = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} {...props}>
    <g transform="translate(12268 10871)">
      <rect
        width={45}
        height={45}
        fill={props.darkMode ? "#fff" : "#383838"}
        rx={22.5}
        transform="translate(-12268 -10871)"
      />
      <path
        fill="none"
        d="M-12259-10862h27v27h-27z"
        data-name="Rectangle 1268"
      />
      <path
        fill="#d6b87c"
        d="M-12254.7-10844.412v6.025a1.15 1.15 0 0 0 1.15 1.137h7.025v-7.162Zm10.225 7.162h7.025a1.15 1.15 0 0 0 1.15-1.138v-6.024h-8.175Zm9.1-17.387h-2.3a3.525 3.525 0 0 0 .363-1.537 3.587 3.587 0 0 0-3.588-3.576 5.262 5.262 0 0 0-4.6 3.212 5.263 5.263 0 0 0-4.6-3.212 3.588 3.588 0 0 0-3.587 3.575 3.525 3.525 0 0 0 .362 1.537h-2.3c-.625.001-1.125.738-1.125 1.638v4.913c0 .9.5 1.637 1.125 1.637h9.1v-8.187h2.05v8.187h9.1c.625 0 1.125-.738 1.125-1.638v-4.912c0-.9-.5-1.637-1.125-1.637Zm-14.725 0a1.537 1.537 0 1 1 0-3.062c1.75 0 2.738 1.8 3.225 3.063Zm9.2 0h-3.225c.487-1.251 1.475-3.063 3.225-3.063a1.537 1.537 0 1 1 0 3.063Z"
        data-name="Layer 2"
      />
    </g>
  </svg>
);
export default Giftbox;
