// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Button, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";

// ----------------------------------------------------------------------

export default function App() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const HandleScrollButtonVisibility = () => {
      window.pageYOffset > 300 ? setShowButton(true) : setShowButton(false);
    };

    window.addEventListener("scroll", HandleScrollButtonVisibility);

    return () => {
      window.removeEventListener("scroll", HandleScrollButtonVisibility);
    };
  });

  const HandleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {}, [localStorage.getItem("fetchUrl")]);

  return (
    <Provider store={store}>
      <ThemeProvider>
        {/* {showButton && (
          <Box
            sx={{
              position: "absolute",
              bottom: 100,
              left: 100,
              webkitBoxShadow: " 10px 10px 30px 0px rgba(173,127,35,1)",
              mozBoxShadow: " 10px 10px 30px 0px rgba(173,127,35,1)",
              boxShadow: " 10px 10px 30px 0px rgba(173,127,35,1)",
            }}
          >
            <Button
              onClick={HandleScrollToTop}
              sx={{
                position: "fixed",
                zIndex: "22",
                opacity: "1",
              }}
            >
              <ArrowCircleUpIcon sx={{ width: "3rem", height: "3rem" }} />
            </Button>
          </Box>
        )} */}
        {/* <Typography>

        </Typography> */}

        <Router />
      </ThemeProvider>
    </Provider>
  );
}
