export const TransactionTypes = {
  BuyCommodity: "Emtia Alımı",
  SellCommodity: "Emtia Bozdurma",
  UserToUserEmtiaTransfer: "Kullanıcıdan Kullanıcıya Emtia Transferi",
  UserToUserEmtiaRequest: "Kullanıcıdan Kullanıcıya Emtia Talebi",
  UserToUserTlTransfer: "Kullanıcıdan Kullanıcıya TL Transferi",
  UserToGoldtagEventEmtiaTransfer: "Kullanıcıdan Etkinliğe Emtia Transferi",
  UserToGoldtagEventEmtiaWithdraw: "Etkinlikten Kullanıcıya Emtia Çekme",
  UserToGoldtagWeddingEmtiaTransfer: "Kullanıcıdan Düğüne Emtia Transferi",
  UserToGoldtagGiftboxEmtiaTransfer:
    "Kullanıcıdan Hediye Kutusuna Emtia Transferi",
  UserToGoldtagGolddayEmtiaTransfer:
    "Kullanıcıdan Altın Gününe Emtia Transferi",
  UserToGoldtagEventTlTransfer: "Kullanıcıdan Etkinliğe TL Transferi",
  UserToGoldtagEventTlWithdraw: "Etkinlikten Kullanıcıya TL Çekme",
  UserToSavingBoxTransfer: "Kumbaraya Emtia Transferi",
  UserToSavingBoxWithdraw: "Kumbaradan Emtia Çekme",
  GoldtagToUserEmtiaTransfer: "Etkinlikten Kullanıcıya Emtia Transferi",
  GoldtagToUserTlTransfer: "Etkinlikten Kullanıcıya TL Transferi",
  GoldtagGiveAway: "Çekiliş",
  TransferToIban: "IBAN'a Transfer",
  PayCargoFee: "Kargo Ücreti Ödeme",
};

export const TransactionTypesWithNum = {
  10: "Emtia Alımı",
  20: "Emtia Bozdurma",
  30: "Kullanıcıdan Kullanıcıya Emtia Transferi",
  31: "Kullanıcıdan Kullanıcıya Emtia Talebi",
  35: "Kullanıcıdan Kullanıcıya TL Transferi",
  40: "Kullanıcıdan Etkinliğe Emtia Transferi",
  41: "Kullanıcıdan Etkinliğe Emtia Çekme",
  42: "Kullanıcıdan Düğüne Emtia Transferi",
  43: "Kullanıcıdan Hediye Kutusuna Emtia Transferi",
  44: "Kullanıcıdan Altın Gününe Emtia Transferi",
  45: "Kullanıcıdan Etkinliğe TL Transferi",
  46: "Kullanıcıdan Etkinlikten TL Çekme",
  50: "Kumbaraya Emtia Transferi",
  51: "Kumbaradan Emtia Çekme",
  60: "Etkinlikten Kullanıcıya Emtia Transferi",
  65: "Etkinlikten Kullanıcıya TL Transferi",
  70: "Çekiliş",
  80: "IBAN'a Transfer",
  90: "Kargo Ücreti Ödeme",
};

export const CommoditySuffixValue = {
  1: "Altın",
  2: "Gümüş",
  3: "PLatin",
};

export const UserLevelStatus = {
  10: "Kayıtlı",
  20: "KYC Onaylı",
  30: "KYC Onayı Bekleyen",
  40: "KYC Reddedilmiş",
  50: "Engellenmiş",
};
