import * as React from "react";
const Goldday = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} {...props}>
    <g transform="translate(12268 10871)">
      <rect
        width={45}
        height={45}
        fill={props.darkMode ? "#fff" : "#383838"}
        rx={22.5}
        transform="translate(-12268 -10871)"
      />
      <path
        fill="none"
        d="M-12259-10862h27v27h-27z"
        data-name="Rectangle 1267"
      />
      <path
        fill="#d6b87c"
        fillRule="evenodd"
        d="m-12247.975-10847.261 1.862 3.727a1.4 1.4 0 0 1-1.249 2.023h-10.238a1.4 1.4 0 0 1-1.249-2.023l1.862-3.727a1.4 1.4 0 0 1 1.249-.772h6.517a1.4 1.4 0 0 1 1.246.772Zm13.966 0 1.862 3.727a1.4 1.4 0 0 1-1.249 2.023h-10.242a1.4 1.4 0 0 1-1.249-2.023l1.862-3.727a1.4 1.4 0 0 1 1.249-.772h6.517a1.4 1.4 0 0 1 1.249.772Zm-6.983-7.454 1.862 3.727a1.4 1.4 0 0 1-1.249 2.023h-10.242a1.4 1.4 0 0 1-1.249-2.023l1.862-3.727a1.4 1.4 0 0 1 1.249-.773h6.517a1.4 1.4 0 0 1 1.25.772Z"
        data-name="Path 53107"
      />
    </g>
  </svg>
);
export default Goldday;
