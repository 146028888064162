import * as React from "react";
import { memo } from "react";
const Add = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    data-name="24 / basic / circle-plus"
    {...props}
  >
    <path
      d="M15 29a14 14 0 1 1 14-14 14 14 0 0 1-14 14zm0-2.545A11.455 11.455 0 1 0 3.545 15 11.454 11.454 0 0 0 15 26.455zm6.364-12.727h-5.091V8.636h-2.546v5.091H8.636v2.545h5.091v5.091h2.545v-5.09h5.091z"
      style={{
        fill: "#d6b87c",
        fillRule: "evenodd",
      }}
      transform="translate(-1 -1)"
    />
  </svg>
);
const AddIcon = memo(Add);
export default AddIcon;
