import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";

// ----------------------------------------------------------------------

const StyledContent = styled("div")(({ theme }) => ({
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <>
      <Helmet>
        <title> 404 Sayfa Bulunamadı </title>
      </Helmet>

      <Container>
        <StyledContent
          sx={{
            textAlign: "center",
            alignItems: "center",
            letterSpacing: "2px",
          }}
        >
          <Typography variant="h3" paragraph>
            Şuan da bu sayfaya erişilemiyor.
          </Typography>

          <Typography sx={{ color: "text.secondary", letterSpacing: "1px" }}>
            Aradığınız sayfa kaldırılmış, giriş kodunuzun süresi bitmiş ve ya
            yetkiniz bulunmuyor olabilir.
          </Typography>

          {/* <Box
            component="img"
            src="/assets/images/404image.jpg"
            sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
          /> */}

          <Box sx={{ display: "flex", gap: "2rem" }}>
            <Button
              to="/dashboard"
              size="large"
              variant="outlined"
              component={RouterLink}
              sx={{ marginTop: "64px" }}
            >
              Geri Dön
            </Button>
            <Button
              to="/"
              size="large"
              variant="contained"
              component={RouterLink}
              sx={{ marginTop: "64px" }}
            >
              Giriş Yap
            </Button>
          </Box>
        </StyledContent>
      </Container>
    </>
  );
}
