import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import { useNavigate } from "react-router-dom";
import NearMeIcon from "@mui/icons-material/NearMe";
import { useEffect, useState } from "react";
import { lightTitle14, underlineBold18 } from "src/utils/customStyles";
import { useDispatch, useSelector } from "react-redux";
import { setNav } from "src/Redux/slices/navSlice";
import MenuIcon from "@mui/icons-material/Menu";
// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const pathName = window.location.pathname;
  const parts = pathName.split("/");
  const navigate = useNavigate();
  const [url, setUrl] = useState(localStorage.getItem("FetchUrl"));
  const nav = useSelector((state) => state.nav.value);
  const dispatch = useDispatch();

  const StyledRoot = styled(AppBar)(({ theme }) => ({
    boxShadow: "none",

    [theme.breakpoints.up("lg")]: {
      width: !nav ? "100vw" : `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
    zIndex: 10,
    backgroundColor: theme.palette.background.neutral, // Arka plan rengine erişim
  }));
  useEffect(() => {
    console.log(nav);
  }, [nav]);
  return (
    <StyledRoot>
      {nav == false && (
        <IconButton
          sx={{
            position: "absolute",
            zIndex: 444,
            visibility: nav ? "hidden" : "visible",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "common.black",
            left: 30,
            top: 25,
            visibility: { xs: "hidden", md: "hidden", lg: "visible" },
          }}
          onClick={() => dispatch(setNav(!nav))}
        >
          <MenuIcon sx={{ color: "common.black" }} />
        </IconButton>
      )}

      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1, justifyContent: "center", display: "flex" }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
