import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CardUser from "src/assets/Icons/AppIcons/CardUser";
import G from "src/assets/Icons/AppIcons/G";
import CustomCards from "src/components/Cards/AppPanelCards/CustomCards";
import {
  dividerLongGrey,
  flxclmncntr,
  titledvdr,
  whiteCard,
  cardDivider,
  semibold18,
} from "src/theme/customStyles";
import { pageContainer } from "src/utils/customStyles";
import Gold from "../../assets/Images/AppImages/gold.png";
import Silver from "src/assets/Images/AppImages/silver.png";
import Platin from "src/assets/Images/AppImages/pllatin.png";
import Tc from "src/assets/Images/AppImages/tc.png";
import Wedding from "src/assets/Icons/AppIcons/Wedding";
import Giftbox from "src/assets/Icons/AppIcons/Giftbox";
import Goldday from "src/assets/Icons/AppIcons/Goldday";
import Event from "src/assets/Icons/AppIcons/Event";
import Moneybox from "src/assets/Icons/AppIcons/Moneybox";
import CollabMoneybox from "src/assets/Icons/AppIcons/CollabMoneybox";
import { TransactionCard } from "../../components/Cards/AppPanelCards/TransactionCard";
import { useSelector } from "react-redux";
import { KycStatus } from "src/components/Cards/AppPanelCards/KycStatus";
import PriceCard from "src/components/Cards/AppPanelCards/PriceCard";
import { Last10UserTransaction } from "src/services/AdminPanelApis/LastTransactionsApi";
import GoldCircle from "src/assets/Images/AppImages/goldCircle.webp";
import SilverCircle from "src/assets/Images/AppImages/silverCircle.webp";
import PlatinCircle from "src/assets/Images/AppImages/platinCircle.webp";
import { useTheme } from "@mui/material";
import bgStyles from "src/utils/cssStyles";
import { GetKycStatus } from "src/services/AdminPanelApis/KycStatusApi";
import { AppReport } from "src/services/AdminPanelApis/AppReportApi";
import { TransactionTypes } from "src/utils/helpers";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";

const visible = {
  visibility: {
    xs: "visible",
    sm: "hidden",
    md: "visible",
    lg: "visible",
  },
};
const HomePage = () => {
  const darkMode = useSelector((state) => state.darkMode.value);

  const [appReport, setAppReport] = useState([]);
  const [kyc, setKyc] = useState({
    labels: [
      "Kayıtlı",
      "KYC yapılan",
      "KYC sonucu bekleyen",
      "KYC sonucu reddedilen",
    ],
    series: [25000, 25000, 25000, 25000],
    colors: ["#d6b87c", "#000000", "#B0B0B0", "#88857c"],
    details: [
      { color: "#d6b87c", value: "boş" },
      { color: "#000000", value: "boş" },
      { color: "#B0B0B0", value: "boş" },
      { color: "#B0B0B0", value: "boş" },
    ],
  });
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactons] = useState([
    {
      icon: (
        <img
          src={Gold}
          style={{
            width: "45px",
            height: "45px",
            minWidth: "45px",
            objectFit: "contain",
          }}
        />
      ),
      title: "İşlem #01223",
      subtitle: "Altın Alım - 9999,99₺",
      time: "ortalama 1 dakika önce",
    },
    {
      icon: (
        <img
          src={Silver}
          style={{
            width: "45px",
            height: "45px",
            minWidth: "45px",
            objectFit: "contain",
          }}
        />
      ),
      title: "İşlem #01223",
      subtitle: "Gümüş Alım - 9999,99₺",
      time: "ortalama 1 dakika önce",
    },
    {
      icon: <Moneybox darkMode={darkMode} />,
      title: "İşlem #01223",
      subtitle: "Kumbara Oluşturma",
      time: "ortalama 1 dakika önce",
    },
    {
      icon: <Goldday darkMode={darkMode} />,
      title: "İşlem #01223",
      subtitle: "ALtın Günü",
      time: "ortalama 1 dakika önce",
    },
  ]);

  const fetchAppReport = async () => {
    try {
      const response = await AppReport();

      if (response.Success) {
        const data = response.Data;
        console.log("appreport arrayi: " + data);
        const mappedData = [
          {
            title: "Kullanıcı Sayımız",
            num: `${data.UserCount} Kişi`,
            icon: (
              <Box
                sx={{
                  visible,
                }}
              >
                <G darkMode={darkMode} />
              </Box>
            ),
          },
          {
            title: "Günlük Yeni Kullanıcı",
            num: `${data.NewUserCount} Kişi`,
            icon: (
              <Box
                sx={{
                  visible,
                }}
              >
                <CardUser color={"#d6b77c"} darkMode={darkMode} />
              </Box>
            ),
          },
          {
            title: "Toplam Aktif Kullanıcı",
            num: `${data.ActiveUser} Kişi`,
            icon: (
              <Box
                sx={{
                  visible,
                }}
              >
                <CardUser color={"#d6b77c"} darkMode={darkMode} />
              </Box>
            ),
          },
          {
            title: "Toplam Satılan Altın",
            num: `${data.TransactionGoldTotalCount} gr`,
            icon: (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={GoldCircle}
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ),
          },
          {
            title: "Aylık Satılan Altın",
            num: `${data.TransactionGoldMonthlyCount} gr`,
            icon: (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={GoldCircle}
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ),
          },
          {
            title: "Günlük Satılan Altın",
            num: `${data.TransactionGoldDailyCount} gr`,
            icon: (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={GoldCircle}
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ),
          },
          {
            title: "Toplam Satılan Gümüş",
            num: `${data.TransactionSilverTotalCount} gr`,
            icon: (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={SilverCircle}
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ),
          },
          {
            title: "Aylık Satılan Gümüş",
            num: `${data.TransactionSilverMonthlyCount} gr`,
            icon: (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={SilverCircle}
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ),
          },
          {
            title: "Günlük Satılan Gümüş",
            num: `${data.TransactionSilverDailyCount} gr`,
            icon: (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={SilverCircle}
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ),
          },
          {
            title: "Toplam Satılan Platin",
            num: `${data.TransactionPlatinTotalCount} gr`,
            icon: (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={PlatinCircle}
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ),
          },
          {
            title: "Aylık Satılan Platin",
            num: `${data.TransactionPlatinMonthlyCount} gr`,
            icon: (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={PlatinCircle}
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ),
          },
          {
            title: "Günlük Satılan Platin",
            num: `${data.TransactionPlatinDailyCount} gr`,
            icon: (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={PlatinCircle}
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ),
          },
          {
            title: "Toplam Goldtag Etkinlik Sayısı",
            num: `${data.GoldtagEventTotalCount} Adet`,
            icon: <Event darkMode={darkMode} />,
          },
          {
            title: "Aktif Goldtag Etkinlik Sayısı",
            num: `${data.GoldtagEventActiveCount} Adet`,
            icon: <Event darkMode={darkMode} />,
          },
          {
            title: "Toplam Kumbara Sayısı",
            num: `${data.SavingBoxTotalCount} Adet`,
            icon: <Moneybox darkMode={darkMode} />,
          },
          {
            title: "Aktif Kumbara Sayısı",
            num: `${data.SavingBoxActiveCount} Adet`,
            icon: <Moneybox darkMode={darkMode} />,
          },
        ];
        setAppReport(mappedData);
        console.log("Mapped Data apprreport:", mappedData);
      }
    } catch (error) {
      console.error("Error fetching AppReport:", error);
    }
  };

  const fetchKyc = async () => {
    try {
      const response = await GetKycStatus();
      if (response.Success) {
        const data = response.Data;
        console.log("kyc arrayi: " + data.length);

        let registeredCount = 0;
        let approvedCount = 0;
        let waitingCount = 0;
        let rejectedCount = 0;

        data.forEach((item) => {
          if (item.Level === 10) {
            registeredCount++;
          } else if (item.Level === 20) {
            approvedCount++;
          } else if (item.Level === 30) {
            waitingCount++;
          } else if (item.Level === 40) {
            rejectedCount++;
          } else {
            console.log("veri yok");
          }
        });

        const total =
          registeredCount + approvedCount + waitingCount + rejectedCount;

        const kycData = {
          labels: [
            "Kayıtlı",
            "KYC sonucu yapılan",
            "KYC sonucu bekleyen",
            "KYC sonucu reddedilen",
          ],
          series: [
            (registeredCount / total) * 100,
            (approvedCount / total) * 100,
            (waitingCount / total) * 100,
            (rejectedCount / total) * 100,
          ],
          colors: ["#d6b87c", "#000000", "#B0B0B0", "#88857c"],
          details: [
            { color: "#d6b87c", value: registeredCount },
            { color: "#000000", value: approvedCount },
            { color: "#B0B0B0", value: waitingCount },
            { color: "#88857c", value: rejectedCount },
          ],
        };

        setKyc(kycData);
      }
    } catch (error) {
      console.error("Error fetching KycStatus:", error);
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await Last10UserTransaction("");
      if (response.Success) {
        const data = response.Data;
        console.log(data);
        const mappedData = data.map((item) => ({
          icon:
            item.TransactionType == "BuyCommodity" &&
            item.CommoditySuffix == 1 ? (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={GoldCircle}
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ) : item.TransactionType == "BuyCommodity" &&
              item.CommoditySuffix == 2 ? (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={SilverCircle}
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ) : item.TransactionType == "BuyCommodity" &&
              item.CommoditySuffix == 3 ? (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={PlatinCircle}
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ) : item.TransactionType == "UserToGoldtagWeddingEmtiaTransfer" ? (
              <Wedding darkMode={darkMode} />
            ) : item.TransactionType == "UserToSavingBoxTransfer" ? (
              <Giftbox darkMode={darkMode} />
            ) : item.TransactionType == "UserToGoldtagGolddayEmtiaTransfer" ? (
              <Goldday darkMode={darkMode} />
            ) : null,
          title: item.TransactionId,
          subtitle: `${TransactionTypes[item.TransactionType]} - ${
            item.CommodityAmount
          }gr`,
          time: item.TransactionDate,
        }));
        setTransactons(mappedData);
        console.log("Mapped Data Last10UserTransaction :", mappedData);
      }
    } catch (error) {
      console.error("Error fetching Last10UserTransaction:", error);
    }
  };
  async function getFetch() {
    await fetchAppReport();
    await fetchKyc();
    await fetchTransactions();
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    getFetch();
  }, []);

  return (
    <Box sx={[pageContainer]}>
      <Box style={flxclmncntr}>
        <Typography variant="h3" sx={{ letterSpacing: "3px" }}>
          Anasayfa
        </Typography>
        <Divider sx={titledvdr} />
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: "1.5rem",
          paddingLeft: { xs: "1.5rem", md: null },
        }}
      >
        <Grid container spacing={2}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "500px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingOverlay isOpen={loading} />
            </Box>
          ) : (
            <>
              {appReport?.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <CustomCards item={item} index={index} />
                </Grid>
              ))}
              <Grid
                container
                spacing={1}
                sx={{
                  ...whiteCard,
                  padding: "15px",
                  backgroundColor: "background.card",
                  mt: "1.5rem",
                }}
              >
                <Grid item xs={12}>
                  <Typography sx={semibold18}>Son İşlemler</Typography>
                  <Box sx={{ ...cardDivider, bgcolor: "#d6b87c" }} />
                </Grid>
                {transactions?.map((transaction, index) => (
                  <Grid item xs={12} sm={12} md={6} key={index}>
                    <TransactionCard {...transaction} />
                    <Box sx={[dividerLongGrey, { backgroundColor: "black" }]} />
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={3} sx={{ marginTop: "16px" }}>
                <Grid item xs={12} md={6}>
                  {kyc && <KycStatus title="Kullanıcı KYC Durumu" data={kyc} />}
                </Grid>
                <Grid item xs={12} md={6}>
                  <PriceCard title="Fiyatlarımız" />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePage;
