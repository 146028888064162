import * as React from "react";
const RolesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17.193} height={21} {...props}>
    <path
      fill={props.darkMode ? "#383838" : "#fff"}
      d="M0 13.363V3.818c0-.733.281-.9 1.059-1.374.236-.142.517-.312.85-.535A15.3 15.3 0 0 1 8.59 0a12.372 12.372 0 0 1 6.683 1.909c.332.222.614.392.85.535.778.472 1.059.642 1.059 1.374.019.208.01 4.618 0 7.441v2.1q0 3.819-8.591 7.637Q0 17.172 0 13.363ZM8.591 18.9a23.1 23.1 0 0 0 5.009-2.987 10.46 10.46 0 0 0-5-.878 10.43 10.43 0 0 0-5 .877A23.228 23.228 0 0 0 8.591 18.9ZM2.968 3.5c-.226.151-.628.4-.937.592l-.122.076v9.2a2.129 2.129 0 0 0 .353 1.093c1.3-.923 3.5-1.33 6.332-1.33-2.137 0-3.819-1.5-3.819-4.773A3.677 3.677 0 0 1 8.3 4.535h.291a3.819 3.819 0 0 1 3.825 3.815v.194c0 3.13-1.7 4.582-3.818 4.582 2.827 0 5.031.406 6.328 1.328a2.134 2.134 0 0 0 .351-1.091V4.17l-.131-.081c-.309-.192-.711-.441-.937-.592A10.446 10.446 0 0 0 8.59 1.909 12.82 12.82 0 0 0 2.968 3.5Zm3.717 4.584a1.69 1.69 0 0 0 0 .27c0 2.165.78 2.864 1.909 2.864s1.909-.67 1.909-2.673a1.927 1.927 0 0 0-1.746-2.093c-.054 0-.109-.008-.163-.008s-.092-.005-.137-.005a1.78 1.78 0 0 0-1.772 1.645Z"
    />
  </svg>
);
export default RolesIcon;
