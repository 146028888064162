import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import EmtiaSelect from "src/components/Select/EmtiaSelect";
import VendorSelect from "src/components/Select/VendorSelect";

import {
  AddVendorBannedCommSuffix,
  RemoveVendorBannedCommSuffix,
  UpdateVendor,
  createVendor,
} from "src/services/api";
import { bgBlur } from "src/utils/cssStyles";
import { pageContainer } from "src/utils/customStyles";

const VendorOperations = () => {
  const location = useLocation();
  const initialTabValue =
    (location.state?.value ?? Number(localStorage.getItem("tabValueVendor"))) ||
    0;

  const [updateForm, setUpdateForm] = React.useState({
    BannedCommoditySuffixes: [],
    IsActive: false,
    VendorEmail: "",
    VendorLegalName: "",
    VendorName: "",
    VendorPhone: "",
    VendorSuffix: "",
  });
  useEffect(() => {}, []);

  const [vendor, setVendor] = useState({
    VendorName: "",
    VendorEmail: "",
    VendorPhone: "",
    VendorLegalName: "",
  });

  const [vendor2, setVendor2] = useState("");
  const [vendor3, setVendor3] = useState("");
  const [emtia, setEmtia] = useState("");
  const [emtia2, setEmtia2] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [errorVendor, setErrorVendor] = useState(false);
  const [errorVendor2, setErrorVendor2] = useState(false);
  const [errorVendor3, setErrorVendor3] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({});
  const [vendorInfo2, setVendorInfo2] = useState({});
  const [vendorInfo3, setVendorInfo3] = useState({});
  const [errorEmtia, setErrorEmtia] = useState(false);
  const [errorEmtia2, setErrorEmtia2] = useState(false);
  const [vendorKey, setVendorKey] = useState(0);
  const [vendorKey2, setVendorKey2] = useState(0);
  const [vendorKey3, setVendorKey3] = useState(0);
  const [value, setValue] = useState(initialTabValue);
  const [modalVisibility, setModalVisibility] = useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const handleChange = (newValue) => {
    setValue(newValue);
    localStorage.setItem("tabValueVendor", newValue);
  };
  const handleChangeVendor = (prop) => (event) => {
    setVendor({ ...vendor, [prop]: event.target.value });
    console.log(vendor);
    console.log("----");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Burada API çağrısını entegre edin
    console.log("Submitting", vendor);
  };

  const handleChangeActivePass = (event) => {
    setUpdateForm((prevState) => ({
      ...prevState,
      IsActive: event.target.value,
    }));
    setErrorEmtia(false);
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    console.log(`ID: ${id}, Value: ${value}`);
    setUpdateForm((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const handleModal = (e) => {
    setModalVisibility(false);
  };

  useEffect(() => {
    setUpdateForm(vendorInfo);
  }, [vendorInfo]);
  useEffect(() => {
    return () => {
      localStorage.removeItem("tabValueVendor");
    };
  }, []);
  const onClickUpdateVendor = async () => {
    try {
      setOpen(true);
      const response = await UpdateVendor(updateForm);
      console.log(response);
      response.Success == false ? setAlertStatus(false) : setAlertStatus(true);
      setAlertMessage(response.Message);
      setOpenAlert(true);
    } catch (error) {
      setAlertMessage(error.Message);
      setAlertStatus(false);
      setOpenAlert(true);
      console.error("Hata oluştu:", error);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setOpen(false);
      }, 1000);
    }
  };
  const onClickAddBannedCommSuffix = async (updateForm) => {
    try {
      setOpen(true);
      const response = await AddVendorBannedCommSuffix(updateForm);
      console.log(response);
      response.Success == false ? setAlertStatus(false) : setAlertStatus(true);
      setAlertMessage(response.Message);
      setOpenAlert(true);
    } catch (error) {
      setAlertMessage(error.Message);
      setAlertStatus(false);
      setOpenAlert(true);
      console.error("Hata oluştu:", error);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setOpen(false);
      }, 1000);
    }
  };
  const onClickRemoveBannedCommSuffix = async (updateForm) => {
    console.log("UPDATEEEEE");
    console.log(updateForm);
    try {
      setOpen(true);
      const response = await RemoveVendorBannedCommSuffix(updateForm);
      console.log("UPDATEEEEE____");
      console.log(updateForm);
      console.log(response);
      response.Success == false ? setAlertStatus(false) : setAlertStatus(true);
      setAlertMessage(response.Message);
      setOpenAlert(true);
    } catch (error) {
      setAlertMessage(error.Message);
      setAlertStatus(false);
      setOpenAlert(true);
      console.error("Hata oluştu:", error);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setOpen(false);
      }, 1000);
    }
  };

  useEffect(() => {
    console.log(emtia2, vendor3);
  }, [emtia2, vendor3]);
  const onClickCreateVendor = async () => {
    if (
      vendor.VendorName !== "" &&
      vendor.VendorLegalName !== "" &&
      vendor.VendorEmail !== "" &&
      vendor.VendorPhone !== ""
    ) {
      try {
        setOpen(true);
        const response = await createVendor(vendor);
        console.log(response);
        response.Success == false
          ? setAlertStatus(false)
          : setAlertStatus(true);
        setAlertMessage(response.Message);
        setOpenAlert(true);
      } catch (error) {
        setAlertMessage(error.Message);
        setAlertStatus(false);
        setOpenAlert(true);
        console.error("Hata oluştu:", error);
      } finally {
        setTimeout(() => {
          setOpenAlert(false);
          setOpen(false);
        }, 1000);
      }
    } else {
      setAlertMessage("Boş alan bırakılamaz!");
      setAlertStatus(false);
      setOpenAlert(true);
    }
  };
  function VendorUpdateForm() {
    return (
      <Card
        sx={{
          p: 3,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: { md: "30rem", xs: "20rem" },
          margin: "0 auto",
          marginTop: 5,
          minWidth: "20rem",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Vendor Güncelle
        </Typography>
        <Divider
          sx={{
            width: "10%",
            backgroundColor: "primary.goldtag",
            height: 3,
            mb: 3,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <VendorSelect
            key={vendorKey}
            vendor={updateForm.VendorSuffix}
            setVendor={(vendor) =>
              setUpdateForm((prevState) => ({
                ...prevState,
                VendorSuffix: vendor,
              }))
            }
            errorVendor={errorVendor}
            setErrorVendor={setErrorVendor}
            variant="outlined"
            setVendorInfo={setVendorInfo}
          />
          <FormControl fullWidth variant="outlined">
            <TextField
              label="Vendor adı giriniz"
              id="VendorName"
              variant="outlined"
              value={updateForm.VendorName}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: !!updateForm.VendorName, // Eğer değer varsa label'ı yukarıda tut
              }}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              label="E-mail giriniz"
              id="VendorEmail"
              variant="outlined"
              value={updateForm.VendorEmail}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: !!updateForm.VendorEmail, // Eğer değer varsa label'ı yukarıda tut
              }}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              type="number"
              label="Telefon numarası giriniz"
              id="VendorPhone"
              variant="outlined"
              value={updateForm.VendorPhone}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: !!updateForm.VendorPhone, // Eğer değer varsa label'ı yukarıda tut
              }}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              label="Resmi adını giriniz"
              id="VendorLegalName"
              variant="outlined"
              value={updateForm.VendorLegalName}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: !!updateForm.VendorLegalName, // Eğer değer varsa label'ı yukarıda tut
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Durumunu seçiniz
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={updateForm.IsActive}
              label="Durumunu seçiniz"
              onChange={handleChangeActivePass}
            >
              <MenuItem key={"active"} value={true}>
                Aktif
              </MenuItem>
              <MenuItem key={"passive"} value={false}>
                Pasif
              </MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={() => [
              onClickUpdateVendor(),
              setVendorKey((prevKey) => prevKey + 1),
            ]}
          >
            Güncelle
          </Button>
        </Box>
      </Card>
    );
  }
  function VendorAddBannedForm() {
    return (
      <Card
        sx={{
          p: 3,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: { md: "30rem", xs: "20rem" },
          margin: "0 auto",
          marginTop: 5,
          minWidth: "20rem",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Add Vendor Banned Commodity Suffixes
        </Typography>
        <Divider
          sx={{
            width: "10%",
            backgroundColor: "primary.goldtag",
            height: 3,
            mb: 3,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <VendorSelect
            key={vendorKey2}
            vendor={vendor2}
            setVendor={(vendor2) => setVendor2(vendor2)}
            errorVendor={errorVendor2}
            setErrorVendor={setErrorVendor2}
            variant="outlined"
            setVendorInfo={setVendorInfo2}
          />
          <EmtiaSelect
            emtia={emtia}
            setEmtia={setEmtia}
            errorEmtia={errorEmtia}
            setErrorEmtia={setErrorEmtia}
          />
          <Button
            variant="contained"
            onClick={() =>
              onClickAddBannedCommSuffix({
                VendorSuffix: vendor2,
                CommoditySuffix: emtia,
              })
            }
          >
            Ekle
          </Button>
        </Box>
      </Card>
    );
  }
  function VendorRemoveBannedForm() {
    return (
      <Card
        sx={{
          p: 3,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: { md: "30rem", xs: "20rem" },
          margin: "0 auto",
          marginTop: 5,
          minWidth: "20rem",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Remove Vendor Banned Commodity Suffixes
        </Typography>
        <Divider
          sx={{
            width: "10%",
            backgroundColor: "primary.goldtag",
            height: 3,
            mb: 3,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <VendorSelect
            key={vendorKey3}
            vendor={vendor3}
            setVendor={(vendor3) => setVendor3(vendor3)}
            errorVendor={errorVendor3}
            setErrorVendor={setErrorVendor3}
            variant="outlined"
            setVendorInfo={setVendorInfo3}
          />
          <EmtiaSelect
            emtia={emtia2}
            setEmtia={setEmtia2}
            errorEmtia={errorEmtia2}
            setErrorEmtia={setErrorEmtia2}
          />
          <Button variant="contained" onClick={() => setModalVisibility(true)}>
            Kaldır
          </Button>
        </Box>
      </Card>
    );
  }
  return (
    <Box sx={pageContainer}>
      <Modal
        open={modalVisibility}
        onClose={() => handleModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{
          backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.5)" } },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: 400,
            height: 200,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 3,
            p: 4,
            borderRadius: "13px",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            {vendor3} suffixli vendordan kaldırmak istediğinize emin misiniz?
          </Typography>
          <Box
            sx={{
              width: "85%",
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => [
                onClickRemoveBannedCommSuffix({
                  VendorSuffix: vendor3,
                  CommoditySuffix: emtia2,
                }),
                setModalVisibility(false),
                setVendor3(""),
                setEmtia2(""),
              ]}
            >
              Kaldır
            </Button>
            <Button
              variant="outlined"
              onClick={() => setModalVisibility(false)}
            >
              Geri Dön
            </Button>
          </Box>
        </Box>
      </Modal>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography
        variant="h3"
        sx={{
          letterSpacing: "3px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
          paddingLeft: "3rem",
        }}
      >
        Vendor İşlemleri
        <Divider
          sx={{
            width: "100px",
            backgroundColor: "primary.goldtag",
            height: 2,
          }}
        />
      </Typography>
      <Box
        sx={{
          justifyContent: "space-evenly",
          display: "flex",
          gap: 0.5,
        }}
      >
        <Button
          key={"btn0" + value}
          sx={{
            backgroundColor: value === 0 ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange(0)}
          variant="contained"
          fullWidth
        >
          vendor oluştur
        </Button>
        <Button
          key={"btn1" + value}
          sx={{
            backgroundColor: value === 1 ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange(1)}
          variant="contained"
          fullWidth
        >
          vendor güncelle
        </Button>
        <Button
          key={"btn2" + value}
          sx={{
            backgroundColor: value === 2 ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange(2)}
          variant="contained"
          fullWidth
        >
          <Typography sx={{ fontSize: "12px", fontWeight: "900" }}>
            Add Vendor Banned Commodity Suffixes
          </Typography>
        </Button>
        <Button
          key={"btn3" + value}
          sx={{
            backgroundColor: value === 3 ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange(3)}
          variant="contained"
          fullWidth
        >
          <Typography sx={{ fontSize: "11px", fontWeight: "900" }}>
            Remove Vendor Banned Commodity Suffixes
          </Typography>
        </Button>
      </Box>

      {value == 0 && (
        <Card
          sx={{
            width: { md: "30rem", xs: "20rem" },
            mx: "auto",
            mt: 5,
          }}
        >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Vendor Ekle
            </Typography>
            <Divider
              sx={{
                width: "10%",
                backgroundColor: "primary.goldtag",
                height: 3,
              }}
            />
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 4 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Vendor Adı"
                    value={vendor.VendorName}
                    onChange={handleChangeVendor("VendorName")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Vendor Resmi İsmi"
                    value={vendor.VendorLegalName}
                    onChange={handleChangeVendor("VendorLegalName")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Vendor Email"
                    value={vendor.VendorEmail}
                    onChange={handleChangeVendor("VendorEmail")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Vendor Telefon Numarası"
                    value={vendor.VendorPhone}
                    onChange={handleChangeVendor("VendorPhone")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => onClickCreateVendor()}
                    // onClick={() => {
                    //   if (
                    //     vendor.VendorName !== "" &&
                    //     vendor.VendorLegalName !== "" &&
                    //     vendor.VendorEmail !== "" &&
                    //     vendor.VendorPhone !== ""
                    //   ) {
                    //     createVendor({
                    //       vendor: vendor,
                    //       setOpen: setOpen,
                    //       setAlertMessage: setAlertMessage,
                    //       setAlertStatus: setAlertStatus,
                    //     });
                    //     setOpen(true);
                    //     setOpenAlert(true);
                    //   } else {
                    //     setAlertMessage("Boş alan bırakılamaz");
                    //     setAlertStatus(false);
                    //     setOpenAlert(true);
                    //   }
                    // }}
                  >
                    Vendor Ekle
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      )}

      {value == 1 && VendorUpdateForm()}
      {value == 2 && VendorAddBannedForm()}
      {value == 3 && VendorRemoveBannedForm()}
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VendorOperations;
