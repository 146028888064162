import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import { createVendor } from "src/services/api";

function CreateVendor() {
  const [vendor, setVendor] = useState({
    VendorName: "",
    VendorEmail: "",
    VendorPhone: "",
    VendorLegalName: "",
  });

  const handleChange = (prop) => (event) => {
    setVendor({ ...vendor, [prop]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Burada API çağrısını entegre edin
    console.log("Submitting", vendor);
  };

  return (
    <Card sx={{ maxWidth: 600, mx: "auto", mt: 5 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Vendor Ekle
        </Typography>
        <Divider
          sx={{ width: "10%", backgroundColor: "primary.goldtag", height: 3 }}
        />
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Vendor Name"
                value={vendor.VendorName}
                onChange={handleChange("VendorName")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Vendor Legal Name"
                value={vendor.VendorLegalName}
                onChange={handleChange("VendorLegalName")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Vendor Email"
                value={vendor.VendorEmail}
                onChange={handleChange("VendorEmail")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Vendor Phone"
                value={vendor.VendorPhone}
                onChange={handleChange("VendorPhone")}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => createVendor(setVendor)}
              >
                Vendor Ekle
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}

export default CreateVendor;
