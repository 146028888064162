import { generalAxiosInstance } from "../axios/axiosConfig";

export async function GetAllUser(Page, PageSize, IsAscending) {
  const raw = {
    Page: Page,
    PageSize: PageSize,
    IsAscending: IsAscending,
  };
  try {
    const response = await generalAxiosInstance.post("/GetAllUser", raw);
    return response.data;
  } catch (error) {
    return error.response?.data || "An error occurred";
  }
}
