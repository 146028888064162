import {
  Alert,
  Box,
  Button,
  Divider,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import {
  GetGatewayPrices,
  setFintagSourcePrice,
  setPrice,
} from "src/services/api";
import {
  dvdrprice,
  flldvdr,
  flxclmncntr,
  flxgp,
  ltrstyl,
  pricebtn,
  pricescont,
  tlt,
} from "src/theme/customStyles";

function PricesDetailsFintagSilver({ open, providerSuffixDynamic }) {
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [activeField, setActiveField] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    CommoditySuffix: 2,
    ProviderSuffix: providerSuffixDynamic,
    BuyProfitRate: -1,
    BuyProfitTl: -1,
    SellProfitRate: -1,
    SellProfitTl: -1,
    Bsmv: -1,
  });

  const [sourcePrice, setSourcePrice] = useState({
    CommoditySuffix: 2,
    SourceBuyPrice: -1,
    SourceSellPrice: -1,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = value.replace(",", ".");

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleChangeSource = (e) => {
    const { name, value } = e.target;
    const newValue = value.replace(",", ".");

    setSourcePrice({
      ...sourcePrice,
      [name]: newValue,
    });
  };

  useEffect(() => {
    GetGatewayPrices(setData);
  }, [loading]);

  useEffect(() => {
    if (data.length > 0) {
      const silverData = data.filter(
        (item) =>
          item.CommoditySuffix === 2 &&
          item.ProviderSuffix == providerSuffixDynamic
      );
      if (silverData.length > 0) {
        const silverItem = silverData[0];
        setFormData({
          ViewBuyPrice: silverItem.ViewBuyPrice,
          ViewSellPrice: silverItem.ViewSellPrice,
          CommoditySuffix: silverItem.CommoditySuffix,
          ProviderSuffix: silverItem.ProviderSuffix,
          BuyProfitRate: silverItem.BuyProfitRate,
          BuyProfitTl: silverItem.BuyProfitTl,
          SellProfitRate: silverItem.SellProfitRate,
          SellProfitTl: silverItem.SellProfitTl,
          Bsmv: silverItem.Bsmv,
        });

        setSourcePrice({
          CommoditySuffix: silverItem.CommoditySuffix,
          SourceBuyPrice: silverItem.SourceBuyPrice,
          SourceSellPrice: silverItem.SourceSellPrice,
        });
      }
    }
  }, [data, providerSuffixDynamic]);

  async function HandleFunction(data) {
    setLoading(true);
    try {
      const ans = await setPrice(data);
      console.log(ans);
      if (ans && ans.Success) {
        setAlertMessage(ans.Message);
        setAlertStatus(true);
        setOpenAlert(true);
      } else {
        setAlertMessage(ans.Message);
        setAlertStatus(false);
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertMessage("Bir şeyler ters gitti! | Catch ERROR");
      setAlertStatus(false);
      setOpenAlert(true);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setLoading(false);
      }, 1000);
    }
  }

  async function handleSourceFunction(data) {
    setLoading(true);
    try {
      const ans = await setFintagSourcePrice(data);
      if (ans && ans.Success) {
        setAlertMessage(ans.Message);
        setAlertStatus(true);
        setOpenAlert(true);
      } else {
        setAlertMessage(ans.Message);
        setAlertStatus(false);
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertMessage("Bir şeyler ters gitti! | Catch ERROR");
      setAlertStatus(false);
      setOpenAlert(true);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setLoading(false);
      }, 3000);
    }
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleFocus = (fieldName) => {
    setActiveField(fieldName);
  };

  const handleBlur = () => {
    setActiveField(null);
  };
  const getFormDataWithDefaults = (activeField) => {
    HandleFunction({
      CommoditySuffix: 2,
      ProviderSuffix: providerSuffixDynamic,
      BuyProfitRate:
        activeField === "BuyProfitRate" ? formData.BuyProfitRate : -1,
      BuyProfitTl: activeField === "BuyProfitTl" ? formData.BuyProfitTl : -1,
      SellProfitRate:
        activeField === "SellProfitRate" ? formData.SellProfitRate : -1,
      SellProfitTl: activeField === "SellProfitTl" ? formData.SellProfitTl : -1,
      Bsmv: activeField === "Bsmv" ? formData.Bsmv : -1,
    });

    setTimeout(() => {
      GetGatewayPrices(setData);
    }, 1000);
  };

  const getSourceDataWithDefaults = (activeField) => {
    handleSourceFunction({
      CommoditySuffix: 2,
      SourceBuyPrice:
        activeField === "SourceBuyPrice" ? sourcePrice.SourceBuyPrice : -1,
      SourceSellPrice:
        activeField === "SourceSellPrice" ? sourcePrice.SourceSellPrice : -1,
    });

    setTimeout(() => {
      GetGatewayPrices(setData);
    }, 1000);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const fields = [
    "SourceBuyPrice",
    "SourceSellPrice",
    "BuyProfitRate",
    "BuyProfitTl",
    "SellProfitRate",
    "SellProfitTl",
    "Bsmv",
  ];

  // Koşula bağlı olarak diziyi filtrele
  const filteredFields = fields.filter((field) => {
    if (providerSuffixDynamic !== 0) {
      return field !== "SourceBuyPrice" && field !== "SourceSellPrice";
    }
    return true;
  });

  return (
    <Box style={pricescont}>
      <Box sx={flxgp}>
        <Box sx={flxclmncntr}>
          <Typography sx={ltrstyl}> ViewBuyPrice</Typography>
          <Divider sx={[dvdrprice]} />
          <Typography sx={{ fontWeight: "bold" }}>
            {formData.ViewBuyPrice}
            <span style={tlt}>₺</span>
          </Typography>
        </Box>

        <Box sx={flxclmncntr}>
          <Typography sx={ltrstyl}>ViewSellPrice</Typography>
          <Divider sx={[dvdrprice]} />

          <Typography sx={{ fontWeight: "bold" }}>
            {formData.ViewSellPrice}
            <span style={tlt}>₺</span>
          </Typography>
        </Box>
      </Box>

      <Divider sx={flldvdr} />

      <Box component="form" sx={[formStyle]} noValidate autoComplete="off">
        {filteredFields.map((field) => (
          <Box key={field} sx={frameStyle}>
            <Typography sx={textStyle}>{field}</Typography>
            <TextField
              name={field}
              value={
                ["SourceSellPrice", "SourceBuyPrice"].includes(field)
                  ? sourcePrice[field]
                  : formData[field]
              }
              onChange={
                ["SourceSellPrice", "SourceBuyPrice"].includes(field)
                  ? handleChangeSource
                  : handleChange
              }
              onFocus={() => handleFocus(field)}
              onBlur={handleBlur}
              size="small"
              variant="filled"
              InputLabelProps={{
                sx: { color: "common.white" },
              }}
              InputProps={{
                sx: {
                  color: "common.white",
                },
              }}
              inputProps={{
                style: {
                  padding: 15,
                  height: "100%", // Input içeriğinin tam yükseklik kaplamasını sağlar
                  boxSizing: "border-box", // Padding ve border'ı dahil eder
                  display: "flex",
                  alignItems: "center", // Dikeyde ortalama
                },
              }}
            />
            {activeField === field && (
              <Button
                color="gold"
                variant="contained"
                sx={pricebtn}
                onMouseDown={handleMouseDown}
                onClick={() => {
                  if (
                    activeField === "SourceBuyPrice" ||
                    activeField === "SourceSellPrice"
                  ) {
                    console.log(activeField);
                    getSourceDataWithDefaults(activeField);
                  } else {
                    getFormDataWithDefaults(activeField);
                  }
                }}
              >
                <Typography sx={{ color: "#000" }}>Fiyatları Onayla</Typography>
              </Button>
            )}
          </Box>
        ))}
      </Box>
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        sx={{ left: { md: "50%", xs: "0%" }, top: { md: "20%", xs: "25%" } }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
      <LoadingOverlay isOpen={loading} />
    </Box>
  );
}

export default PricesDetailsFintagSilver;

const formStyle = {
  "& > :not(style)": { width: "15rem" },
  display: "flex",
  flexDirection: "column",
  gap: 2,
  backgroundColor: "#d3d3d368",
  borderRadius: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  webkitBoxShadow: " 8px 15px 26px 0px #4b4b4b8b",
  mozBoxShadow: "8px 15px 26px 0px #4b4b4b8b",
  boxShadow: "8px 15px 26px 0px #4b4b4b8b",
};

const textStyle = {
  fontWeight: "500",
  marginBottom: "0.3rem",
  textAlign: "center",
  letterSpacing: 1,
  color: "common.white",
};

const frameStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginBottom: "1rem",
};
