import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddBuySellTransactionCard from "src/components/TransactionOperationsCards/AddBuySellTransactionCard";
import AddTransferTransactionCard from "src/components/TransactionOperationsCards/AddTransferTransactionCard";
import CancelTransactionCard from "src/components/TransactionOperationsCards/CancelTransactionCard";
import { pageContainer } from "src/utils/customStyles";

const TransactionChanges = () => {
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [change, setChange] = useState(1);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleChange = (newValue) => {
    setChange(newValue);
  };

  return (
    <Box sx={pageContainer}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
      <Typography
        variant="h3"
        sx={{
          letterSpacing: "3px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
          paddingLeft: "3rem",
        }}
      >
        İşlem Değişimleri
        <Divider
          sx={{
            width: "100px",
            backgroundColor: "primary.goldtag",
            height: 2,
          }}
        />
      </Typography>

      <Box
        sx={{
          justifyContent: "space-evenly",
          display: "flex",
          gap: 0.5,
        }}
      >
        <Button
          sx={{
            backgroundColor: change === 1 ? "#7A4F01" : "default",
            // width: "585px",
          }}
          onClick={() => handleChange(1)}
          variant="contained"
          fullWidth
        >
          İptal İşlemleri
        </Button>
        <Button
          sx={{
            backgroundColor: change === 2 ? "#7A4F01" : "default",
            //width: "585px",
          }}
          onClick={() => handleChange(2)}
          variant="contained"
          fullWidth
        >
          Alım ve Bozdurma İşlemleri Ekle
        </Button>
        <Button
          sx={{
            backgroundColor: change === 3 ? "#7A4F01" : "default",
            //width: "585px",
          }}
          onClick={() => handleChange(3)}
          variant="contained"
          fullWidth
        >
          Transfer İşlemleri Ekle
        </Button>
      </Box>

      {change == 1 && (
        <>
          <CancelTransactionCard
            setLoading={setLoading}
            setOpenAlert={setOpenAlert}
            setAlertMessage={setAlertMessage}
            setAlertStatus={setAlertStatus}
          />
        </>
      )}

      {change == 2 && (
        <>
          <AddBuySellTransactionCard
            setLoading={setLoading}
            setOpenAlert={setOpenAlert}
            setAlertMessage={setAlertMessage}
            setAlertStatus={setAlertStatus}
          />
        </>
      )}

      {change == 3 && (
        <>
          <AddTransferTransactionCard
            setLoading={setLoading}
            setOpenAlert={setOpenAlert}
            setAlertMessage={setAlertMessage}
            setAlertStatus={setAlertStatus}
          />
        </>
      )}
    </Box>
  );
};

export default TransactionChanges;
