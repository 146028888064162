import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import LoginPage from "./sections/auth/login/LoginPage";
import Page404 from "./pages/Page404";
import Vendor from "./pages/Vendor";

import Authpage from "./sections/auth/login/Authpage";

import StartingPage from "./pages/StartingPage";

import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Logo from "./components/logo";
import Transactions from "./pages/Transactions";
import Menu from "./pages/Menu";
import Prices from "./pages/Prices";
import Transfers from "./pages/Transfers";
import CreateVendor from "./pages/CreateVendor";
import Accounts from "./pages/Accounts";
import VendorOperations from "./pages/VendorOperations";
import { UpdateVendor } from "./services/api";
import AdminList from "./pages/AdminList";
import RoleOperations from "./pages/RoleOperations";
import QRAuth from "./sections/auth/login/QRAuth";
import MainPage from "./pages/MainPage";
import Verify2FA from "./sections/auth/login/Verify2FA";
import HomePage from "./pages/AppPanelPages/HomePage";
import UsersPage from "./pages/AppPanelPages/UsersPage";
import TransactionsPage from "./pages/AppPanelPages/TransactionsPage";
import TransfersPage from "./pages/AppPanelPages/TransfersPage";
import VendorManagers from "./pages/VendorManagers";
import EventsPage from "./pages/AppPanelPages/EventsPage";
import TransactionChanges from "./pages/TransactionChanges";
import VendorFeature from "./pages/VendorFeature";

// ----------------------------------------------------------------------

export default function Router() {
  const [ipAddress, setIPAddress] = useState("");
  const myIP = "95.3.15.194";
  const myIP2 = "20.127.243.158";
  const myIP3 = "20.18.164.9";

  const [isLoading, setIsLoading] = useState(true);
  const [isAllowed, setIsAllowed] = useState(false); // Başlangıçta false olarak ayarlandı

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setIPAddress(data.ip);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!isLoading && ipAddress !== myIP && !isLoading && ipAddress !== myIP2) {
      showNotAllowed();
    }
  }, [isLoading, ipAddress, myIP, myIP2]);

  const showNotAllowed = () => {
    return (
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Typography sx={{ fontWeight: 600, color: "#383838", fontSize: 24 }}>
          Aradığınız sayfaya ulaşılamıyor.
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: "#383838",
            fontSize: 16,
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          Hata kodu : 403
        </Typography>
        <Logo />
      </Box>
    );
  };

  const routes = useRoutes([
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "auth",
      element: <Authpage />,
    },
    {
      path: "qrAuth",
      element: <QRAuth />,
    },
    {
      path: "verify2FA",
      element: <Verify2FA />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/Menu" />, index: true },
        { path: "Menu", element: <MainPage /> },
        { path: "Fiyatlar", element: <Prices /> },
        { path: "transferler", element: <Transfers /> },
        { path: "islemler", element: <Transactions /> },
        { path: "hesaplar", element: <Accounts /> },
        { path: "vendorekle", element: <CreateVendor /> },
        { path: "vendorIslemleri", element: <VendorOperations /> },
        { path: "vendorYoneticileri", element: <VendorManagers /> },
        { path: "adminListeleme", element: <AdminList /> },
        { path: "rolIslemleri", element: <RoleOperations /> },
        { path: "islemDegisimleri", element: <TransactionChanges /> },
        { path: "vendorFeature", element: <VendorFeature /> },

        { path: "appPanelAnasayfa", element: <HomePage /> },
        { path: "appPanelKullanicilar", element: <UsersPage /> },
        { path: "appPanelIslemler", element: <TransactionsPage /> },
        { path: "appPanelTransferler", element: <TransfersPage /> },
        { path: "appPanelEtkinlikler", element: <EventsPage /> },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/Menu" /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  // if (
  //   (isLoading || ipAddress !== myIP) &&
  //   (isLoading || ipAddress !== myIP2) &&
  //   (isLoading || ipAddress !== myIP3)
  // ) {
  //   return <Typography>...</Typography>;
  // }

  return routes;
}
