import {
  Box,
  Typography,
  Button,
  Divider,
  Menu,
  MenuItem,
  Modal,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  flxclmncntr,
  titledvdr,
  semibold12,
  bold14,
} from "src/theme/customStyles";
import { Close } from "@mui/icons-material";
import { pageContainer } from "src/utils/customStyles";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  formatDate,
  handleExportData,
  handleExportRows,
} from "src/utils/helpers/helper";
import { GetAllUser } from "src/services/AdminPanelApis/AllUsersApi";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import { UserLevelStatus } from "src/utils/helpers";

const UsersPage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([
    {
      MemberId: 3434454,
      FullName: "isim",
      Email: "mail@goldtag.org",
      PhoneNumber: "5462376232",
      Level: 10,
    },
    {
      MemberId: 1232039,
      FullName: "ikinci isim",
      Email: "ikinci mail@goldtag.org",
      PhoneNumber: "5464543232",
      Level: 20,
    },
  ]);
  const [mail, setMail] = useState("mail@gmail");
  const [balances, setBalances] = useState({
    tlBalance: 9999,
    goldAmount: 3233,
    silverAmount: 4342,
    platinumAmount: 2232,
  });
  const [loading, setLoading] = useState(false);

  const handleMailChange = (event) => {
    setMail(event.target.value);
  };

  const handleBalanceChange = (event) => {
    const { name, value } = event.target;
    setBalances((prevBalances) => ({
      ...prevBalances,
      [name]: value,
    }));
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchAllUsers = async () => {
    try {
      const response = await GetAllUser(1, 15, true);
      if (response.Success) {
        const data = response.Data;
        const array = data.map((item) => ({
          MemberId: item.MemberId,
          FullName: item.FullName,
          Email: item.Email,
          PhoneNumber: `+90 ${item.PhoneNumber}`,
          Level: UserLevelStatus[item.Level],
          CreatedAt: formatDate(item.CreatedAt),
        }));
        setData(array);
        console.log(data);
      }
    } catch (error) {
      console.error("Error fetching GetAllUser:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchAllUsers();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "MemberId",
        header: "GoldtagId",
        size: 30,
      },
      {
        accessorKey: "FullName",
        header: "İsim",
        size: 180,
      },
      {
        accessorKey: "Email",
        header: "Mail",
        size: 180,
      },
      {
        accessorKey: "PhoneNumber",
        header: "Telefon Numarası",
        size: 100,
      },
      {
        accessorKey: "Level",
        header: "Kullanıcı Hesap Durumu",
        size: 50,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          marginBottom: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(data, "Transferler")}
          startIcon={<FileDownloadIcon />}
        >
          Tüm Verileri Dışa Aktar
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(
              table.getPrePaginationRowModel().rows,
              "Transferler"
            )
          }
          startIcon={<FileDownloadIcon />}
        >
          Tüm Satırları Dışa Aktar
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() =>
            handleExportRows(table.getRowModel().rows, "Transferler")
          }
          startIcon={<FileDownloadIcon />}
        >
          Bu Sayfadaki Satırları Dışa Aktar
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() =>
            handleExportRows(table.getSelectedRowModel().rows, "Transferler")
          }
          startIcon={<FileDownloadIcon />}
        >
          Seçilmiş Satırları Dışa Aktar
        </Button>
      </Box>
    ),
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          borderRadius: "20px",
          width: "100%",
          padding: "30px",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box
            sx={{
              borderRadius: 40,
              width: "73px",
              height: "73px",
              bgcolor: "grey",
            }}
          />
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
            }}
          >
            <Button
              variant="outlined"
              id="basic-button"
              onClick={() => handleOpenModal()}
              sx={{ mt: "30px" }}
            >
              güncelle
              <ExpandMoreIcon
                sx={{
                  color: "transparent",
                  ml: 1,
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease-in-out",
                }}
              />
            </Button>
            <Button
              variant="outlined"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ mt: "10px" }}
            >
              Detaylar{" "}
              <ExpandMoreIcon
                sx={{
                  ml: 1,
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease-in-out",
                }}
              />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem variant="text" onClick={handleClose}>
                İşlemler
              </MenuItem>
              <MenuItem variant="text" onClick={handleClose}>
                Transferler
              </MenuItem>
              <MenuItem variant="text" onClick={handleClose}>
                Etkinlikler
              </MenuItem>
              <MenuItem variant="text" onClick={handleClose}>
                Talepler
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        <Box
          sx={{
            marginLeft: "2rem",
            width: "450px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: "16px",

                fontWeight: "bold",
                letterSpacing: "1px",
              }}
            >
              Kişisel Bilgiler
            </Typography>
            <Box
              sx={{
                width: 3,
                height: 40,
                backgroundColor: "gold.main",
                marginLeft: "10px",
              }}
            />
            <Box sx={{ marginLeft: "25px" }}>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={semibold12}> Ad - Soyad</Typography>
                <Typography sx={bold14}>{row.original.FullName}</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={semibold12}>Mail Adresi </Typography>
                <Typography sx={bold14}>{row.original.Email}</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={semibold12}>Telefon Numarası </Typography>
                <Typography sx={bold14}>
                  +90 {row.original.PhoneNumber}
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={semibold12}>Hesap Durumu </Typography>
                <Typography sx={bold14}>{row.original.Level}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginLeft: "1rem",
            width: "450px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
            >
              App Bilgileri
            </Typography>
            <Box
              sx={{
                width: 3,
                height: 40,
                backgroundColor: "gold.main",
                marginLeft: "10px",
              }}
            />
            <Box sx={{ marginLeft: "25px" }}>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={semibold12}> Goldtag Member Id</Typography>
                <Typography sx={bold14}>{row.original.MemberId}</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={semibold12}>
                  {" "}
                  Hesap Oluşturma Tarihi{" "}
                </Typography>
                <Typography sx={bold14}>{row.original.CreatedAt}</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={semibold12}> İşlem Sayısı </Typography>
                <Typography sx={bold14}>
                  {row.original.PlatinAmount}34
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={semibold12}>TL Hesap Bakiyesi </Typography>
                <Typography sx={bold14}>{row.original.TLAmount}150</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={semibold12}>Altın Miktarı </Typography>
                <Typography sx={bold14}>{row.original.TLAmount}150</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={semibold12}>Gümüş Miktarı</Typography>
                <Typography sx={bold14}>{row.original.TLAmount}150</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={semibold12}>Platin Miktarı </Typography>
                <Typography sx={bold14}>{row.original.TLAmount}150</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    ),
  });

  return (
    <>
      <Box sx={pageContainer}>
        <Box sx={flxclmncntr}>
          <Typography variant="h3" sx={{ letterSpacing: "3px" }}>
            Kullanıcılar
          </Typography>
          <Divider sx={titledvdr} />
        </Box>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "500px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingOverlay isOpen={loading} />
          </Box>
        ) : (
          <MaterialReactTable table={table} />
        )}
      </Box>
      <Modal open={openModal} sx={{ zIndex: 9999999999 }}>
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  visibility: { xs: "hidden", sm: "visible" },
                  borderRadius: 40,
                  width: "73px",
                  height: "73px",
                  bgcolor: "grey",
                }}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      letterSpacing: "1px",
                    }}
                  >
                    Kişisel Bilgiler
                  </Typography>
                  <Box
                    sx={{
                      width: 3,
                      height: 40,
                      backgroundColor: "gold.main",
                      marginLeft: "10px",
                    }}
                  />
                </Box>
                <Box>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={semibold12}>Ad</Typography>
                    <Typography sx={bold14}>aleyna</Typography>
                  </Box>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={semibold12}>Soyad</Typography>
                    <Typography sx={bold14}>soyad</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <TextField
                      label="Mail Adresi"
                      value={mail}
                      onChange={handleMailChange}
                      variant="outlined"
                      size="small"
                      sx={{ flexGrow: 1, minWidth: "200px", mr: 2 }}
                    />
                    <Button variant="contained" size="small">
                      Kaydet
                    </Button>
                  </Box>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={semibold12}>Telefon Numarası</Typography>
                    <Typography sx={bold14}>apide yok</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={5}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      letterSpacing: "1px",
                    }}
                  >
                    App Bilgileri
                  </Typography>
                  <Box
                    sx={{
                      width: 3,
                      height: 40,
                      backgroundColor: "gold.main",
                      marginLeft: "10px",
                    }}
                  />
                </Box>
                <Box>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={semibold12}>Goldtag Id</Typography>
                    <Typography sx={bold14}>34</Typography>
                  </Box>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={semibold12}>
                      Hesap Oluşturma Tarihi
                    </Typography>
                    <Typography sx={bold14}>4/01/2922</Typography>
                  </Box>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={semibold12}>İşlem Sayısı</Typography>
                    <Typography sx={bold14}>34</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <TextField
                      type="number"
                      label="TL Hesap Bakiyesi"
                      name="tlBalance"
                      value={balances.tlBalance}
                      onChange={handleBalanceChange}
                      variant="outlined"
                      size="small"
                      sx={{ flexGrow: 1, minWidth: "200px", mr: 2 }}
                    />
                    <Button variant="contained" size="small">
                      Kaydet
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <TextField
                      type="number"
                      label="Altın Miktarı"
                      name="goldAmount"
                      value={balances.goldAmount}
                      onChange={handleBalanceChange}
                      variant="outlined"
                      size="small"
                      sx={{ flexGrow: 1, minWidth: "200px", mr: 2 }}
                    />
                    <Button variant="contained" size="small">
                      Kaydet
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <TextField
                      type="number"
                      label="Gümüş Miktarı"
                      name="silverAmount"
                      value={balances.silverAmount}
                      onChange={handleBalanceChange}
                      variant="outlined"
                      size="small"
                      sx={{ flexGrow: 1, minWidth: "200px", mr: 2 }}
                    />
                    <Button variant="contained" size="small">
                      Kaydet
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <TextField
                      type="number"
                      label="Platin Miktarı"
                      name="platinumAmount"
                      value={balances.platinumAmount}
                      onChange={handleBalanceChange}
                      variant="outlined"
                      size="small"
                      sx={{ flexGrow: 1, minWidth: "200px", mr: 2 }}
                    />
                    <Button variant="contained" size="small">
                      Kaydet
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleCloseModal();
            }}
          >
            <Close />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UsersPage;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "row",
  borderRadius: "13px",
};
