import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import gsap from "gsap"; // GSAP kütüphanesini ekledik
import { useSelector } from "react-redux";
import { GetAllVendorsWithDetails } from "src/services/api";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { cardss } from "src/theme/customStyles";
import { getBackgroundColor } from "src/utils/helpers/helper";
const CustomCard = () => {
  const [data, setData] = useState([]);

  const darkMode = useSelector((state) => state.darkMode.value);

  const navigate = useNavigate();

  useEffect(() => {
    GetAllVendorsWithDetails(setData);
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      gsap.from(".custom-card", {
        duration: 1,
        opacity: 0,
        y: -40,
        ease: "power3.out",
        stagger: 0.2,
      });
      console.log(data);
    }
  }, [data]);

  const handleButtonClick = (selectedItem) => {
    navigate("/dashboard/hesaplar", { state: selectedItem });
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: { xs: "1.5rem", md: null },
      }}
    >
      <Grid container spacing={2}>
        {data
          ?.filter((item) => item.IsActive)
          .map((item, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Button
                className="custom-card"
                sx={[
                  cardss,
                  {
                    background: getBackgroundColor(item.VendorSuffix),
                    webkitBoxShadow: !darkMode
                      ? "0px 15px 26px 0px #76716a99"
                      : "0px 15px 26px 0px #f6f6f65c",
                    mozBoxShadow: !darkMode
                      ? "0px 15px 26px 0px #76716a99"
                      : "0px 15px 26px 0px #f6f6f65c",
                    boxShadow: !darkMode
                      ? "0px 15px 26px 0px #76716a99"
                      : "0px 15px 26px 0px #f6f6f65c",
                    width: { xs: "100%", sm: "100%", md: "100%" },
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    padding: "16px",
                    height: "140px",
                  },
                ]}
                onClick={() => handleButtonClick(item)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "common.white",
                      fontSize: "16px",
                      marginBottom: "8px",
                      wordWrap: "break-word",
                    }}
                  >
                    {item.VendorLegalName}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        letterSpacing: 1,
                        color: "common.white",
                        marginBottom: "4px",
                      }}
                    >
                      {item.VendorName}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "common.white",
                        letterSpacing: "1px",
                        opacity: 0.8,
                        textTransform: "lowercase",
                        marginBottom: "4px",
                        wordWrap: "break-word",
                      }}
                    >
                      {item.VendorEmail}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "common.white",
                        wordWrap: "break-word",
                      }}
                    >
                      +90{item.VendorPhone}
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default CustomCard;
