import { generalAxiosInstance } from "../axios/axiosConfig";

export async function GetUserEvents(body) {
  try {
    const response = await generalAxiosInstance.post("/GetUserEvents", body);
    return response.data;
  } catch (error) {
    return error.response?.data || "An error occurred";
  }
}
