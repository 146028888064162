import { generalAxiosInstance } from "../axios/axiosConfig";

export async function GetUsersTransactions(body) {
  try {
    console.log(body);
    const response = await generalAxiosInstance.post(
      "/GetUserTransactions",
      body
    );
    return response.data;
  } catch (error) {
    return error.response?.data || "An error occurred";
  }
}
