import * as React from "react";
import { memo } from "react";
const Check = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72} {...props}>
    <path
      d="M37 73a36 36 0 1 1 36-36 36 36 0 0 1-36 36zm0-6.545A29.455 29.455 0 1 0 7.545 37 29.454 29.454 0 0 0 37 66.455zm10.777-41.587L30.455 42.19l-7.5-7.5-4.628 4.628 12.128 12.128L52.405 29.5z"
      data-name="24 / basic / circle-checked"
      style={{
        fill: "#d6b87c",
        fillRule: "evenodd",
      }}
      transform="translate(-1 -1)"
    />
  </svg>
);
const Memo = memo(Check);
export default Memo;
