import { configureStore } from "@reduxjs/toolkit";
import navSlice from "./slices/navSlice";
import darkModeSlice from "./slices/darkModeSlice";

export const store = configureStore({
  reducer: {
    nav: navSlice,
    darkMode: darkModeSlice,
  },
});
