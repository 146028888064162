import { Box, Button, Typography } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useMemo, useState } from "react";
import VendorSelect from "src/components/Select/VendorSelect";
import { pageHeader } from "src/theme/customStyles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { handleExportData, handleExportRows } from "src/utils/helpers/helper";
import { pageContainer } from "src/utils/customStyles";
const Transfers = () => {
  const [vendor, setVendor] = React.useState("");
  const [errorVendor, setErrorVendor] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({});
  const [data, setData] = useState([
    {
      FromUserId: 1,
      ToUserId: 2,
      CommodityName: "Product A",
      Amount: 100,
      FromFee: 5,
      ToFee: 3,
      CreatedDate: "2024-05-13",
    },
    {
      FromUserId: 3,
      ToUserId: 4,
      CommodityName: "Product B",
      Amount: 150,
      FromFee: 7,
      ToFee: 4,
      CreatedDate: "2024-05-14",
    },
  ]);
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "FromUserId",
        header: "FromUserId",
        size: 30,
      },
      {
        accessorKey: "ToUserId",
        header: "ToUserId",
        size: 120,
      },
      {
        accessorKey: "CommodityName",
        header: "CommodityName",
        size: 120,
      },
      {
        accessorKey: "Amount",
        header: "Amount",
        size: 30,
      },
      {
        accessorKey: "FromFee",
        header: "FromFee",
        size: 30,
      },
      {
        accessorKey: "ToFee",
        header: "ToFee",
        size: 20,
      },
      {
        accessorKey: "CreatedDate",
        header: "CreatedDate",
        size: 30,
      },
    ],
    []
  );
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableRowActions: true,
    muiTableBodyRowProps: ({ rowData }) => ({
      onClick: () => {
        alert(`Clicked row: ${JSON.stringify(rowData)}`);
      },
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          marginBottom: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(data, "Transferler")}
          startIcon={<FileDownloadIcon />}
        >
          Tüm Verileri Dışa Aktar
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(
              table.getPrePaginationRowModel().rows,
              "Transferler"
            )
          }
          startIcon={<FileDownloadIcon />}
        >
          Tüm Satırları Dışa Aktar
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() =>
            handleExportRows(table.getRowModel().rows, "Transferler")
          }
          startIcon={<FileDownloadIcon />}
        >
          Bu Sayfadaki Satırları Dışa Aktar
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() =>
            handleExportRows(table.getSelectedRowModel().rows, "Transferler")
          }
          startIcon={<FileDownloadIcon />}
        >
          Seçilmiş Satırları Dışa Aktar
        </Button>
      </Box>
    ),
    renderRowActions: ({ row }) => (
      <Button
        sx={{ fontSize: "12px", width: "150px" }}
        onClick={(e) => {
          e.stopPropagation();
          alert("Test");
        }}
      >
        Kullanıcı Id'sine göre Transfer Listele
      </Button>
    ),
  });

  return (
    <Box sx={pageContainer}>
      <Typography sx={pageHeader}>Transferler</Typography>
      <VendorSelect
        vendor={vendor}
        setVendor={setVendor}
        errorVendor={errorVendor}
        setErrorVendor={setErrorVendor}
        setVendorInfo={setVendorInfo}
      />
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default Transfers;
