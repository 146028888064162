import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import Lottie from "react-lottie";
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from "react-router-dom";
import { Enable2FA } from "src/services/api";
import dashboardbackground from "../../../assets/Gifs/dashboardbackground";
import dashboardbackground_white from "../../../assets/Gifs/dashboardbackground_white";
import { useSelector } from "react-redux";
import { bglotcon, pageHeader, rzlt, rzltcrc } from "src/theme/customStyles";
import OtpInput from "./DigitBox";
import Lottie from "react-lottie";

function QRAuth(props) {
  const darkMode = useSelector((state) => state.darkMode.value);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [step, setStep] = useState(1);
  const [qrScanned, setQrScanned] = useState(false);

  useEffect(() => {
    if (id?.length > 0) {
      callAuth();
      console.log(id);
    }
  }, []);

  async function callAuth() {
    setLoading(true);

    try {
      const response = await Enable2FA(id);
      console.log("that the response:", response);

      if (response.Success) {
        setValue(response.Data);
      }
    } catch (error) {
      console.error("Error during authentication:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 6000);
    }
    console.log("new", value);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: !darkMode ? dashboardbackground : dashboardbackground_white,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box sx={bglotcon}>
      <Box sx={rzlt}>
        <>
          <Typography
            Typography
            sx={[pageHeader, { textDecoration: "underline" }]}
          >
            Microsoft Authenticator
          </Typography>
          <Box sx={rzltcrc}>
            <QRCode
              size={124}
              style={{ height: "300px", width: "300px" }}
              value={value}
              viewBox={`0 0 124 124`}
            />
          </Box>

          <Typography
            sx={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "300",
              padding: "1rem 3rem",
              marginBottom: "1rem",
            }}
          >
            Lütfen Microsoft Authenticator uygulamasına girip, "Kişisel Hesap"
            ekleyerek QR kodunuzu okutun.{" "}
          </Typography>
        </>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={qrScanned}
                onChange={(e) => setQrScanned(e.target.checked)}
              />
            }
            label="QR Kodu okuttum."
            style={{ fontStyle: "italic", opacity: 0.7 }}
          />
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Button variant="outlined">Geri Dön</Button>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/Verify2FA", { state: { id: id } });
              }}
              disabled={!qrScanned}
            >
              Devam Et
            </Button>
          </Box>
        </Box>
      </Box>

      <Lottie
        options={defaultOptions}
        style={{
          zIndex: "-1",
          position: "absolute",
          width: "100%",
        }}
      />
    </Box>
  );
}

export default QRAuth;
