import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
// @mui
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
//
import shadows from "./shadows";
import typography from "./typography";
import GlobalStyles from "./globalStyles";
import customShadows from "./customShadows";
import componentsOverride from "./overrides";
import { useDispatch, useSelector } from "react-redux";
import { DarkMode } from "@mui/icons-material";
import palettedark from "./palettedark";
import palette from "./palette";
import { setDarkMode } from "src/Redux/slices/darkModeSlice";

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const [currentPalette, setCurrentPalette] = useState(palettedark);
  const darkMode = useSelector((state) => state.darkMode.value);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentPalette(darkMode ? palette : palettedark);
  }, [darkMode]);

  useEffect(() => {
    console.log(localStorage.getItem("darkMode"));
    const isDarkMode = localStorage.getItem("darkMode") === "true";
    dispatch(setDarkMode(isDarkMode));
  }, []);

  const themeOptions = useMemo(
    () => ({
      palette: currentPalette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [currentPalette]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
