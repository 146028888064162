import * as React from "react";
const CardUser = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} {...props}>
    <g transform="translate(12268 10937)">
      <rect
        width={45}
        height={45}
        fill={props.fill ? props.fill : props.darkMode ? "#fff" : "#383838"}
        rx={22.5}
        transform="translate(-12268 -10937)"
      />
      <path
        fill={props.color ? props.color : props.darkMode ? "#fff" : "#383838"}
        d="M-12241.529-10915.294a4.765 4.765 0 1 0-1.445-9.307 7.837 7.837 0 0 1 1.429 4.542 7.991 7.991 0 0 1-1.429 4.542 4.749 4.749 0 0 0 1.445.223Zm-7.941 0a4.765 4.765 0 1 0-4.765-4.765 4.745 4.745 0 0 0 4.764 4.765Zm10.514 3.431a5.876 5.876 0 0 1 2.192 4.511v3.176h4.764v-3.176c0-2.446-3.764-3.955-6.956-4.511Zm-10.514-.254c-3.176 0-9.529 1.588-9.529 4.765v3.176h19.058v-3.176c0-3.177-6.353-4.765-9.53-4.765Z"
        data-name="Icon material-group-add"
      />
    </g>
  </svg>
);
export default CardUser;
