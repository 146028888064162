// src/axiosConfig.js

import axios from "axios";

// Custom function to redirect to login
const redirectToLogin = () => {
  window.location.href = "/"; // Use window.location.href to navigate
};

// Function to get baseURL from localStorage or use default
const getBaseURL = () => {
  const fetchUrl = localStorage.getItem("fetchUrl");
  return fetchUrl ? fetchUrl : "https://gatewaydev.goldtaggateway.com/Admin/";
};

// General axios instance for authenticated requests
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: getBaseURL(),
  timeout: 7000,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.baseURL = getBaseURL(); // Set baseURL dynamically
    const authCode = localStorage.getItem("authCode");
    if (authCode) {
      config.headers["Authorization"] = `Bearer ${authCode}`;
    } else {
      redirectToLogin();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      redirectToLogin();
    }
    return Promise.reject(error);
  }
);

// AXIOS CONFIG FOR LOGIN
const loginAxiosInstance = axios.create({
  withCredentials: true,
  baseURL: getBaseURL(),
  timeout: 7000,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

loginAxiosInstance.interceptors.request.use(
  (config) => {
    config.baseURL = getBaseURL(); // Set baseURL dynamically
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios config for General

const generalAxiosInstance = axios.create({
  withCredentials: true,
  baseURL: getBaseURL(),
  timeout: 7000,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

generalAxiosInstance.interceptors.request.use(
  (config) => {
    config.baseURL = getBaseURL(); // Set baseURL dynamically
    const authCode = localStorage.getItem("authCode");
    if (authCode) {
      config.headers["Authorization"] = `Bearer ${authCode}`;
    } else {
      redirectToLogin();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

generalAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      redirectToLogin();
    }
    return Promise.reject(error);
  }
);

export { axiosInstance, loginAxiosInstance, generalAxiosInstance };
