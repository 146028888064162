import React, { useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const RoleSelect = ({ label, value, onChange, roles, valueKey }) => {
  useEffect(() => {}, []);
  return (
    <FormControl>
      <InputLabel id="role-select-label">{label}</InputLabel>
      <Select
        labelId="role-select-label"
        id="role-select"
        value={value}
        label={label}
        onChange={onChange}
      >
        {roles.map((role) => (
          <MenuItem key={role.Id} value={role[valueKey]}>
            {role.Name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RoleSelect;
