import * as React from "react";
const PricesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} {...props}>
    <path
      fill={props.darkMode ? "#383838" : "#fff"}
      d="M9.641 17.182v-1.241a3.712 3.712 0 0 1-1.826-.966 4.233 4.233 0 0 1-1.038-1.849l1.575-.62a2.841 2.841 0 0 0 .895 1.468 2.166 2.166 0 0 0 1.4.489 2.393 2.393 0 0 0 1.348-.37 1.287 1.287 0 0 0 .561-1.157 1.33 1.33 0 0 0-.585-1.122 9.124 9.124 0 0 0-2.088-.978 5.247 5.247 0 0 1-2.064-1.193 2.527 2.527 0 0 1-.656-1.814 2.678 2.678 0 0 1 .68-1.778 3.175 3.175 0 0 1 1.849-1.038V3.82h1.67v1.193a2.657 2.657 0 0 1 1.563.692 3.2 3.2 0 0 1 .966 1.456l-1.527.62a2.1 2.1 0 0 0-.62-.919 1.771 1.771 0 0 0-1.193-.37 1.985 1.985 0 0 0-1.277.358 1.192 1.192 0 0 0-.441.978 1.112 1.112 0 0 0 .549.978 9.541 9.541 0 0 0 1.981.835A4.621 4.621 0 0 1 13.65 11.1a3.18 3.18 0 0 1 .573 1.838 2.915 2.915 0 0 1-.239 1.217 2.8 2.8 0 0 1-.632.895 3.155 3.155 0 0 1-.919.6 5.586 5.586 0 0 1-1.122.346v1.193Z"
    />
    <g fill="none">
      <path d="M10.5 0A10.5 10.5 0 1 1 0 10.5 10.5 10.5 0 0 1 10.5 0Z" />
      <path
        fill={props.darkMode ? "#383838" : "#fff"}
        d="M10.5 1.92c-4.731 0-8.58 3.849-8.58 8.58s3.849 8.58 8.58 8.58 8.58-3.849 8.58-8.58-3.849-8.58-8.58-8.58m0-1.92C16.299 0 21 4.701 21 10.5S16.299 21 10.5 21 0 16.299 0 10.5 4.701 0 10.5 0Z"
      />
    </g>
  </svg>
);
export default PricesIcon;
