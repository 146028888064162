import React, { useEffect } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PricesDetailsFintagAltin from "src/components/Cards/PriceCards/PricesDetailsFintagAltin";
import PricesDetailsFintagSilver from "src/components/Cards/PriceCards/PricesDetailsFintagSilver";
import PricesDetailsFintagPlatin from "src/components/Cards/PriceCards/PricesDetailsFintagPlatin";
import { pageContainer } from "src/utils/customStyles";
import { Helmet } from "react-helmet-async";
import gsap from "gsap";
import { useSelector } from "react-redux";
import { StartAllCommodities, StopAllCommodities } from "src/services/api";
import PricesDetailsFintagUSDT from "src/components/Cards/PriceCards/PricesDetailsFintagUSDT";
import PricesDetailsFintagIron from "src/components/Cards/PriceCards/PricesDetailsFintagIron";
import { buttonStyles, getBackgroundColor } from "src/utils/helpers/helper";

export default function Price() {
  const [open, setOpen] = React.useState(false);

  const [refresh, setRefresh] = React.useState(false);
  const [page, setPage] = React.useState(true);
  const [provider, setProvider] = React.useState(0);

  useEffect(() => {
    gsap.from(".custom-card", {
      duration: 1,
      opacity: 0,
      y: -100,
      ease: "power3.out",
      stagger: 0.3,
    });
  }, []);

  return (
    <Box sx={pageContainer}>
      <Helmet>
        <title>Dashboard | Fiyatlar</title>
      </Helmet>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "2rem",
        }}
      >
        <Typography variant="h3" sx={{ letterSpacing: "3px" }}>
          Fiyatlar
        </Typography>

        <Divider
          sx={{
            width: "100px",
            backgroundColor: "primary.goldtag",
            height: 2,
          }}
        />

        <Button sx={{}} onClick={() => setPage(!page)}>
          {page ? "Commodity" : "Dolar & Demir"}
        </Button>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
            marginBottom: "2.5rem",
          }}
        >
          <Button
            sx={buttonStyles(provider === 0)}
            onClick={() => setProvider(0)}
          >
            Fintag
          </Button>
          <Button
            sx={buttonStyles(provider === 1)}
            onClick={() => setProvider(1)}
          >
            KuveytTürk
          </Button>
          <Button
            sx={buttonStyles(provider === 2)}
            onClick={() => setProvider(2)}
          >
            Altınkaynak
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
            marginBottom: "2.5rem",
          }}
        >
          <Button
            variant="contained"
            color="success"
            sx={{ color: "white" }}
            onClick={() => StartAllCommodities()}
          >
            Commodityleri Başlat
          </Button>

          <Button
            variant="contained"
            color="error"
            onClick={() => StopAllCommodities()}
          >
            Commodityleri Durdur
          </Button>
        </Box>
      </Box>

      <>
        {provider == 2 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            <Box
              className="custom-card"
              component="form"
              sx={[
                formStyle,
                {
                  background:
                    "linear-gradient(to right bottom, #bfbfbf, #9e9e9e, #7e7e7e, #5f5f5f, #404040)",
                },
              ]}
              noValidate
              autoComplete="off"
            >
              <Typography
                sx={{
                  textAlign: "center",

                  color: "common.white",
                }}
                variant="h4"
              >
                Dolar
              </Typography>

              <Box style={{ width: "100%" }}>
                <PricesDetailsFintagUSDT
                  open={refresh}
                  providerSuffixDynamic={provider}
                />
              </Box>
            </Box>

            {/* Fintag Demir Fiyatları  */}
            {provider == 0 && (
              <Box
                className="custom-card"
                component="form"
                sx={[
                  formStyle,
                  {
                    background:
                      "linear-gradient(to right bottom, #bfbfbf, #9e9e9e, #7e7e7e, #5f5f5f, #404040)",
                  },
                ]}
                noValidate
                autoComplete="off"
              >
                <Typography
                  sx={{
                    textAlign: "center",

                    color: "common.white",
                  }}
                  variant="h4"
                >
                  Demir
                </Typography>

                <Box style={{ width: "100%" }}>
                  <PricesDetailsFintagIron
                    open={refresh}
                    providerSuffixDynamic={provider}
                  />
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <>
            {page ? (
              <>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                      gap: "1rem",
                    }}
                  >
                    <Box
                      className="custom-card"
                      component="form"
                      sx={[
                        formStyle,
                        {
                          background: getBackgroundColor("1"),
                        },
                      ]}
                      noValidate
                      autoComplete="off"
                    >
                      <Typography
                        sx={{
                          textAlign: "center",

                          color: "common.white",
                        }}
                        variant="h4"
                      >
                        Altın
                      </Typography>

                      <Box style={{ width: "100%" }}>
                        <PricesDetailsFintagAltin
                          open={refresh}
                          providerSuffixDynamic={provider}
                        />
                      </Box>
                    </Box>

                    {/* Fintag Gümüş Fiyatları  */}
                    <Box
                      className="custom-card"
                      component="form"
                      sx={[
                        formStyle,
                        {
                          background: getBackgroundColor("2"),
                        },
                      ]}
                      noValidate
                      autoComplete="off"
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "common.white",
                        }}
                        variant="h4"
                      >
                        Gümüş
                      </Typography>

                      <Box style={{ width: "100%" }}>
                        <PricesDetailsFintagSilver
                          open={refresh}
                          providerSuffixDynamic={provider}
                        />
                      </Box>
                    </Box>

                    {/* Fintag Platin Fiyatları  */}
                    <Box
                      className="custom-card"
                      component="form"
                      sx={[
                        formStyle,
                        {
                          background: getBackgroundColor("3"),
                        },
                      ]}
                      noValidate
                      autoComplete="off"
                    >
                      <Typography
                        sx={{
                          textAlign: "center",

                          color: "common.white",
                        }}
                        variant="h4"
                      >
                        Platin
                      </Typography>

                      <Box style={{ width: "100%" }}>
                        <PricesDetailsFintagPlatin
                          open={refresh}
                          providerSuffixDynamic={provider}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                        gap: "1rem",
                      }}
                    >
                      <Box
                        className="custom-card"
                        component="form"
                        sx={[
                          formStyle,
                          {
                            background:
                              "linear-gradient(to right bottom, #bfbfbf, #9e9e9e, #7e7e7e, #5f5f5f, #404040)",
                          },
                        ]}
                        noValidate
                        autoComplete="off"
                      >
                        <Typography
                          sx={{
                            textAlign: "center",

                            color: "common.white",
                          }}
                          variant="h4"
                        >
                          Dolar
                        </Typography>

                        <Box style={{ width: "100%" }}>
                          <PricesDetailsFintagUSDT
                            open={refresh}
                            providerSuffixDynamic={provider}
                          />
                        </Box>
                      </Box>

                      {/* Fintag Demir Fiyatları  */}
                      {provider == 0 && (
                        <Box
                          className="custom-card"
                          component="form"
                          sx={[
                            formStyle,
                            {
                              background:
                                "linear-gradient(to right bottom, #bfbfbf, #9e9e9e, #7e7e7e, #5f5f5f, #404040)",
                            },
                          ]}
                          noValidate
                          autoComplete="off"
                        >
                          <Typography
                            sx={{
                              textAlign: "center",

                              color: "common.white",
                            }}
                            variant="h4"
                          >
                            Demir
                          </Typography>

                          <Box style={{ width: "100%" }}>
                            <PricesDetailsFintagIron
                              open={refresh}
                              providerSuffixDynamic={provider}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </>
              </>
            )}
          </>
        )}
      </>

      <Box
        sx={{
          width: "97%",
          height: "0.1rem",
          backgroundColor: "divider",
          marginTop: "4rem",
          background: "rgb(183,129,3)",
          background:
            "linear-gradient(90deg, rgba(183,129,3,1) 0%, rgba(214,184,124,1) 20%, rgba(255,225,106,1)  40%,  rgba(255,193,7,1) 60%,rgba(214,184,124,1)  80%, rgba(183,129,3,1) 100%)",
        }}
      />
    </Box>
  );
}

const formStyle = {
  "& > :not(style)": { width: "15rem" },
  display: "flex",
  flexDirection: "column",
  backgroundColor: "rgba(232, 219, 176, 0.5)",
  borderRadius: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  webkitBoxShadow: " 0px 15px 45px 0px #6d6d6d91",
  mozBoxShadow: "0px 15px 45px 0px #6d6d6d91",
  boxShadow: "0px 15px 45px 0px #6d6d6d91",
};
