import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useState } from "react";
import { DeleteVendorFeatureAPI, GetVendorFeaturesAPI } from "src/services/api";
import { useTheme } from "@emotion/react";
import UpdateVendorFeature from "./UpdateVendorFeature";

const GetVendorFeatureList = () => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);
  const [data, setData] = useState([]);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [updateModal, setUpdateModal] = React.useState(false);

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "VendorId",
        header: "VendorId",
        size: 30,
      },
      {
        accessorKey: "CommodityId",
        header: "CommodityId",
        size: 120,
      },
      {
        accessorKey: "BuyPipe",
        header: "BuyPipe",
        size: 120,
      },
      {
        accessorKey: "SellPipe",
        header: "SellPipe",
        size: 120,
      },
      {
        accessorKey: "IsActive",
        header: "IsActive",
        size: 120,
      },
      {
        accessorKey: "CreatedDate",
        header: "CreatedDate",
        size: 120,
      },
      {
        accessorKey: "ModifiedDate",
        header: "ModifiedDate",
        size: 120,
      },
    ],
    []
  );
  useEffect(() => {
    const getadmin = async () => {
      try {
        const response = await GetVendorFeaturesAPI();
        if (response.Success === false) {
          setAlertStatus(false);
        } else {
          setAlertStatus(true);
          const array = data.map((item) => ({
            ...item,
            IsActive: item.IsActive ? "true" : "false",
          }));
          setData(array);
          setData(response.Data);
        }
        setAlertMessage(response.Message);
        setOpenAlert(true);
      } catch (error) {
        setAlertMessage(error.Message);
        setAlertStatus(false);
        setOpenAlert(true);
        setOpen(false);
        console.error("Hata oluştu:", error);
      } finally {
        setTimeout(() => {
          setOpenAlert(false);
          setOpen(false);
        }, 1000);
      }
    };
    getadmin();
  }, []);

  const table = useMaterialReactTable({
    columns,
    data,
    muiTableBodyCellProps: {
      sx: { backgroundColor: theme.palette.table },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.palette.table,
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: theme.palette.table,
      },
    },
    muiTopToolbarProps: {
      sx: {
        marginTop: "36px",
        backgroundColor: theme.palette.table,
      },
    },
    enableRowActions: true,

    renderRowActions: ({ row }) => {
      return (
        <Box sx={{ display: "flex", gap: "16px" }}>
          <Button
            sx={{ fontSize: "12px" }}
            variant={"outlined"}
            onClick={(e) => {
              e.stopPropagation();

              setUpdateModal(true);
            }}
            color={"success"}
          >
            Güncelle
          </Button>
          <Button
            sx={{ fontSize: "12px" }}
            variant={"outlined"}
            onClick={(e) => {
              e.stopPropagation();

              deletePip(row.original.VendorId, row.original.CommodityId);
            }}
            color={"error"}
          >
            Sil
          </Button>
        </Box>
      );
    },
  });

  const deletePip = async (vendor, emtia) => {
    try {
      const response = await DeleteVendorFeatureAPI({
        VendorSuffix: vendor,
        CommoditySuffix: emtia,
      });

      if (response.Success === false) {
        setAlertStatus(false);
      } else {
        setAlertStatus(true);
      }
      setAlertMessage(response.Message);
      setOpenAlert(true);
    } catch (error) {
      setAlertMessage(error.Message);
      setAlertStatus(false);
      setOpenAlert(true);
      setOpen(false);
      console.error("Hata oluştu:", error);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setOpen(false);
      }, 1000);
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>{" "}
      {data !== null && <MaterialReactTable table={table} />}
      {updateModal && (
        <UpdateVendorFeature open={updateModal} setOpen={setUpdateModal} />
      )}
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GetVendorFeatureList;
