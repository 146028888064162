import * as React from "react";
const AdminListing = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} {...props}>
    <path fill="none" d="M0 0h21v21H0z" />
    <path
      fill={props.darkMode ? "#383838" : "#fff"}
      fillRule="evenodd"
      d="M1.658 5.195a1.768 1.768 0 1 0 1.768-1.768 1.768 1.768 0 0 0-1.768 1.768Zm5.3-.884h8.473v1.768H6.958Zm12.377 5.3H6.958v1.768H19.34ZM6.958 16.689h6.387V14.92H6.958Zm-5.3-.885a1.768 1.768 0 1 0 1.768-1.768 1.768 1.768 0 0 0-1.768 1.768Zm1.768-3.536A1.768 1.768 0 1 1 5.194 10.5a1.768 1.768 0 0 1-1.768 1.768Z"
    />
  </svg>
);
export default AdminListing;
