import * as React from "react";
const Moneybox = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} {...props}>
    <g data-name="Group 42942" transform="translate(12268 10937)">
      <rect
        width={45}
        height={45}
        fill={props.darkMode ? "#fff" : "#383838"}
        rx={22.5}
        transform="translate(-12268 -10937)"
      />
      <g
        fill="#d6b77c"
        data-name={10139720911680190000}
        transform="translate(-12256.748 -10926.875)"
      >
        <path
          d="M17.137 6.888H5.358a5.612 5.612 0 0 0-.552 2.433v3.994h3.225a2.41 2.41 0 0 1 2.41-2.41h1.607a2.41 2.41 0 0 1 2.41 2.41h3.225V9.321a5.612 5.612 0 0 0-.544-2.433Z"
          data-name="Path 54258"
        />
        <path
          d="M4.823 22.5h12.853l.008-8.034h-3.223a2.41 2.41 0 0 1-2.41 2.41h-1.607a2.41 2.41 0 0 1-2.41-2.41H4.811Z"
          data-name="Path 54259"
        />
        <path
          d="M19.297 9.32v3.991h3.2v-1.607a4.819 4.819 0 0 0-3.56-4.648 7.238 7.238 0 0 1 .362 2.263Z"
          data-name="Path 54260"
        />
        <path
          d="M3.2 13.311V9.319a7.238 7.238 0 0 1 .362-2.263A4.817 4.817 0 0 0 0 11.704v1.607h3.2Z"
          data-name="Path 54261"
        />
        <path
          d="m19.291 14.466-.01 8.034h.8a2.41 2.41 0 0 0 2.41-2.41v-5.624h-3.2Z"
          data-name="Path 54262"
        />
        <path
          d="M3.2 14.466H0v5.624a2.41 2.41 0 0 0 2.41 2.41h.8Z"
          data-name="Path 54263"
        />
        <rect
          width={3.375}
          height={3.375}
          data-name="Rectangle 3066"
          rx={1}
          transform="translate(9.56 12.212)"
        />
        <path
          d="M11.244 4.017a.8.8 0 0 0 .8-.8V.8a.8.8 0 1 0-1.6 0v2.41a.8.8 0 0 0 .8.807Z"
          data-name="Path 54264"
        />
        <path
          d="M5.523 5.272a.803.803 0 0 0 1.316-.922L5.453 2.375a.804.804 0 1 0-1.317.921l1.387 1.975Z"
          data-name="Path 54265"
        />
        <path
          d="M16.315 5.615a.8.8 0 0 0 .659-.342l1.383-1.971a.804.804 0 1 0-1.317-.921l-1.383 1.97a.8.8 0 0 0 .657 1.265Z"
          data-name="Path 54266"
        />
      </g>
    </g>
  </svg>
);
export default Moneybox;
