import * as React from "react";
const MenuIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} {...props}>
    <path
      d="M4.714 8 10.5 2l5.786 6v10H4.715V8Zm-1.927 2-1.424 1.476L0 10.062 9.138.585a1.878 1.878 0 0 1 2.725 0L21 10.062l-1.363 1.413L18.213 10v8a1.965 1.965 0 0 1-1.928 2H4.715a1.965 1.965 0 0 1-1.928-2Z"
      data-name="Path 54267"
      style={{
        fill: props.darkMode ? "#383838" : "#fff",
        fillRule: "evenodd",
      }}
    />
  </svg>
);
export default MenuIcon;
