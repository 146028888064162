import {
  Box,
  Typography,
  Button,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import {
  bold14,
  flxclmncntr,
  pageHeader,
  semibold12,
  titledvdr,
} from "src/theme/customStyles";
import { pageContainer } from "src/utils/customStyles";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { handleExportData, handleExportRows } from "src/utils/helpers/helper";
const TransfersPage = () => {
  const [data, setData] = useState([
    {
      GoldtagId: 3,
      Name: "isim",
      Surname: "soyisim",
      TLAmount: 150,
      GoldAmount: 7,
      SilverAmount: 4,
      PlatinAmount: 34,
    },
  ]);
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "GoldtagId",
        header: "GoldtagId",
        size: 30,
      },
      {
        accessorKey: "Name",
        header: "Name",
        size: 120,
      },
      {
        accessorKey: "Surname",
        header: "Surname",
        size: 120,
      },
      {
        accessorKey: "TLAmount",
        header: "TLAmount",
        size: 30,
      },
      {
        accessorKey: "GoldAmount",
        header: "GoldAmount",
        size: 30,
      },
      {
        accessorKey: "SilverAmount",
        header: "SilverAmount",
        size: 20,
      },
      {
        accessorKey: "PlatinAmount",
        header: "PlatinAmount",
        size: 30,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          marginBottom: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(data, "Transferler")}
          startIcon={<FileDownloadIcon />}
        >
          Tüm Verileri Dışa Aktar
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(
              table.getPrePaginationRowModel().rows,
              "Transferler"
            )
          }
          startIcon={<FileDownloadIcon />}
        >
          Tüm Satırları Dışa Aktar
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() =>
            handleExportRows(table.getRowModel().rows, "Transferler")
          }
          startIcon={<FileDownloadIcon />}
        >
          Bu Sayfadaki Satırları Dışa Aktar
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() =>
            handleExportRows(table.getSelectedRowModel().rows, "Transferler")
          }
          startIcon={<FileDownloadIcon />}
        >
          Seçilmiş Satırları Dışa Aktar
        </Button>
      </Box>
    ),
  });
  return (
    <Box sx={pageContainer}>
      <Box style={flxclmncntr}>
        <Typography variant="h3" sx={{ letterSpacing: "3px" }}>
          Transferler
        </Typography>
        <Divider sx={titledvdr} />
      </Box>

      <MaterialReactTable table={table} />
    </Box>
  );
};

export default TransfersPage;
