import * as React from "react";
const GoldtagGateway = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={632} height={116} {...props}>
    <defs>
      <clipPath id="a">
        <path
          d="M0 0h398v116H0z"
          data-name="Rectangle 3114"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(0 -.434)"
        />
      </clipPath>
    </defs>
    <g data-name="Group 42937">
      <g
        data-name="Group 42785"
        style={{
          clipPath: "url(#a)",
        }}
        transform="translate(0 .434)"
      >
        <path
          d="M97.647 34.43v4.46a24.545 24.545 0 0 1-7.3 18.67 25.6 25.6 0 0 1-18.5 6.962A27.864 27.864 0 0 1 51.62 56.7a25.755 25.755 0 0 1-8.05-19.244 26.156 26.156 0 0 1 7.966-19.3 26.8 26.8 0 0 1 19.5-7.854 27.9 27.9 0 0 1 13.418 3.279 24.6 24.6 0 0 1 9.466 8.659l-10.155 5.805a12.873 12.873 0 0 0-5.108-4.4 16.733 16.733 0 0 0-7.676-1.732 14.876 14.876 0 0 0-11.024 4.4 15.271 15.271 0 0 0-4.387 11.258 14.734 14.734 0 0 0 4.474 11.075 16.675 16.675 0 0 0 11.976 4.287q10.39 0 13.389-8.053H67.778l7.474-10.1z"
          data-name="Path 54270"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(82.164 19.25)"
        />
        <path
          d="M97.223 49.12a19.819 19.819 0 0 1-33.879-13.98 18.809 18.809 0 0 1 5.771-13.979A19.823 19.823 0 0 1 97.223 49.12zm-20.2-7.652a8.35 8.35 0 0 0 6.119 2.474 8.194 8.194 0 0 0 6.119-2.474 9.311 9.311 0 0 0 0-12.657 8.352 8.352 0 0 0-6.119-2.474 8.2 8.2 0 0 0-6.119 2.474 9.31 9.31 0 0 0 0 12.657z"
          data-name="Path 54271"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(119.468 28.985)"
        />
        <path
          d="m78.756 64.293.344-47.338 10.85-7.078V64.3z"
          data-name="Path 54272"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(148.553 18.469)"
        />
        <path
          d="m113.445 15.482 11.111-4.832v52.183h-11.227v-3.51a14.033 14.033 0 0 1-11.14 4.546 16.558 16.558 0 0 1-12.639-5.7 21.309 21.309 0 0 1 0-27.962 16.555 16.555 0 0 1 12.639-5.7 13.991 13.991 0 0 1 11.14 4.546zm-15.44 35.354a9.607 9.607 0 0 0 12.871 0 10.136 10.136 0 0 0 0-13.278 9.607 9.607 0 0 0-12.871 0 10.137 10.137 0 0 0 0 13.278z"
          data-name="Path 54273"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(159.054 19.928)"
        />
        <path
          d="M124.453 33.368h-7.7v13.2a2.612 2.612 0 0 0 1.79 2.82 17.634 17.634 0 0 0 5.914.375v10.158q-10.69 1.123-14.8-2.044c-2.742-2.1-4.128-5.868-4.128-11.306v-13.2h-5.978V22.639h5.973v-7.078l11.228-3.361v10.439h7.7z"
          data-name="Path 54274"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(187.782 22.843)"
        />
        <path
          d="M137.972 16.5H149.2v37.285h-11.228v-3.51a14.03 14.03 0 0 1-11.139 4.546 16.56 16.56 0 0 1-12.64-5.7 21.309 21.309 0 0 1 0-27.962 16.558 16.558 0 0 1 12.64-5.7 13.992 13.992 0 0 1 11.139 4.546zm-15.347 25.289a9.607 9.607 0 0 0 12.871 0 10.137 10.137 0 0 0 0-13.278 9.606 9.606 0 0 0-12.871 0 10.227 10.227 0 0 0 0 13.278z"
          data-name="Path 54275"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(205.544 28.978)"
        />
        <path
          d="M153.4 16.5h10.908v35.41a15.909 15.909 0 0 1-5.77 13.318 22.114 22.114 0 0 1-13.994 4.517q-12.643 0-17.72-9.032l9.583-5.522a10.214 10.214 0 0 0 14.718 2.647 7.627 7.627 0 0 0 2.249-5.926V48.49a13.225 13.225 0 0 1-10.908 4.832 17.367 17.367 0 0 1-13.128-5.551 19.585 19.585 0 0 1 0-26.752 17.317 17.317 0 0 1 13.128-5.551 13.223 13.223 0 0 1 10.905 4.832l.029-3.8zm-15.558 24.162a9.173 9.173 0 0 0 6.492 2.387 9.075 9.075 0 0 0 6.492-2.387 9.011 9.011 0 0 0 0-12.542 10.021 10.021 0 0 0-12.983 0 9.018 9.018 0 0 0 0 12.542z"
          data-name="Path 54276"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(234.011 28.985)"
        />
        <path
          d="M35.559 22.83c0 .087-.029.2-.029.289a.983.983 0 0 0 .029-.289z"
          data-name="Path 54277"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(67.008 42.905)"
        />
        <path
          d="M85.222 56.377h2.945A42.844 42.844 0 0 1 70.535 83.1a44.32 44.32 0 0 1-8.135 4.4A42.774 42.774 0 1 1 37.985 5.979a42.191 42.191 0 0 1 8.136-.779 42.633 42.633 0 0 1 7.937.69v2.56a47.9 47.9 0 0 0-8.166-.577 42.011 42.011 0 0 0-7.647.719A40.285 40.285 0 0 0 8.666 32.728a39.477 39.477 0 0 0-2.279 7.623A40.13 40.13 0 0 0 38.24 87.32a39.392 39.392 0 0 0 7.647.719 41.753 41.753 0 0 0 7.647-.719 39.484 39.484 0 0 0 7.619-2.272 40.155 40.155 0 0 0 24.066-28.68z"
          data-name="Path 54278"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(6.09 9.648)"
        />
        <path
          d="M59.077 53.566v8.111h34.369a42.845 42.845 0 0 1-17.632 26.732 44.32 44.32 0 0 1-8.137 4.4 42.775 42.775 0 1 1-24.413-81.525 42.2 42.2 0 0 1 8.137-.776 42.639 42.639 0 0 1 7.937.69c.116.029.231.029.347.058l-.145-8.227a54.18 54.18 0 0 0-8.14-.638 50.765 50.765 0 0 0-50.326 42.72 53.673 53.673 0 0 0-.634 8.111 50.176 50.176 0 0 0 .634 8.111A50.955 50.955 0 0 0 43.3 103.418a54.151 54.151 0 0 0 8.137.632 50.666 50.666 0 0 0 8.137-.632 48 48 0 0 0 8.137-1.986 50.825 50.825 0 0 0 34.019-39.755.884.884 0 0 0 .029-.289 54.048 54.048 0 0 0 .605-7.825z"
          data-name="Path 54279"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(.809 4.346)"
        />
        <path
          d="M26.071 12.785h-8.136V20.9h8.137z"
          data-name="Path 54280"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(33.813 23.956)"
        />
        <path
          d="M31.721 22.93h-8.137v8.111h8.137z"
          data-name="Path 54281"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(44.471 43.093)"
        />
        <path
          d="M65.988 23.688H18.893a3.617 3.617 0 0 1-3.365-2.235 3.6 3.6 0 0 1-.272-1.39v-.546a3.923 3.923 0 0 1 3.924-3.911h45.045a40.116 40.116 0 0 1 1.268 4.947c.207 1.007.379 2.072.495 3.135z"
          data-name="Path 54282"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(28.76 29.276)"
        />
        <path
          d="M31.127 20.119v8.111h-11.6a4.252 4.252 0 0 1-4.271-4.258v-.029a3.831 3.831 0 0 1 3.837-3.825z"
          data-name="Path 54283"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(28.76 37.791)"
        />
        <path
          d="M31.721 12.785h-8.137v21.173h8.137z"
          data-name="Path 54284"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(44.471 23.956)"
        />
        <path
          d="M26.071 18.406h-8.136v21.173h8.137z"
          data-name="Path 54285"
          style={{
            fill: "#d6b87c",
          }}
          transform="translate(33.813 34.56)"
        />
      </g>
      <text
        style={{
          fontSize: 34,
          fontFamily: "IBMPlexSans-Medium,IBM Plex Sans",
          fontWeight: 500,
          fill: "#d6b87c",
        }}
        transform="translate(462 77)"
      >
        <tspan x={0} y={0}>
          {"Dashboard"}
        </tspan>
      </text>
      <path
        d="M0 0h3v38H0z"
        data-name="Rectangle 3115"
        style={{
          fill: "#d6b87c",
        }}
        transform="translate(431 46)"
      />
    </g>
  </svg>
);
export default GoldtagGateway;
