import React from "react";
import { TextField, Grid, Box } from "@mui/material";

const DigitBox = ({ validationCode, setValidationCode }) => {
  const handleChange = (index, value) => {
    // const newOtp = [...validationCode];
    // newOtp[index] = value;
    // setValidationCode(newOtp);

    // if (value && index < 5) {
    //   const nextInput = document.getElementById(`otp-input-${index + 1}`);
    //   if (nextInput) {
    //     nextInput.focus();
    //   }
    // }
    const newOtp = [...validationCode];
    newOtp[index] = value.slice(-1); // Yalnızca son karakteri kabul et
    setValidationCode(newOtp);

    if (value && index < 5) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    // if (event.key === "Backspace" && !validationCode[index] && index > 0) {
    //   const newOtp = [...validationCode];
    //   newOtp[index - 1] = "";
    //   setValidationCode(newOtp);
    //   const prevInput = document.getElementById(`otp-input-${index - 1}`);
    //   if (prevInput) {
    //     prevInput.focus();
    //   }
    // }
    if (event.key === "Backspace") {
      const newOtp = [...validationCode];
      if (newOtp[index]) {
        newOtp[index] = ""; // Mevcut kutuyu boşalt
      } else if (index > 0) {
        newOtp[index - 1] = ""; // Önceki kutuyu boşalt ve ona odaklan
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
      setValidationCode(newOtp);
    }
  };

  const handlePaste = (event) => {
    // event.preventDefault();
    // const pasteData = event.clipboardData.getData("text");
    // const pastedChars = pasteData.split("").slice(0, 6);
    // const newOtp = Array(6)
    //   .fill("")
    //   .map((_, index) => pastedChars[index] || "");
    // setValidationCode(newOtp);
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text");
    const pastedChars = pasteData.split("").slice(0, 6);
    const newOtp = Array(6)
      .fill("")
      .map((_, index) => pastedChars[index] || "");
    setValidationCode(newOtp);
    const nextInput = document.getElementById(
      `otp-input-${pastedChars.length - 1}`
    );
    if (nextInput) {
      nextInput.focus();
    }
  };
  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  return (
    <Box sx={{ gap: "0.5rem", display: "flex" }}>
      {validationCode.map((digit, index) => (
        <Grid
          item
          xs={1}
          key={index}
          sx={{
            width: "60px",
            height: "88px",
            borderRadius: "8px",
            marginTop: "48px",
          }}
        >
          <TextField
            autoFocus={index === 0}
            inputMode="numeric"
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            id={`otp-input-${index}`}
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onKeyPress={handleKeyPress}
            onPaste={handlePaste}
            inputProps={{
              maxLength: 1,
              style: { textAlign: "center" },
            }}
          />
        </Grid>
      ))}
    </Box>
  );
};

export default DigitBox;
