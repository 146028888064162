export const pageHeader = {
  fontSize: "25px",
  fontWeight: "600",
  marginBottom: 2,
};
export const semibold12 = {
  fontSize: "12px",
  fontWeight: "600",
};
export const semibold18 = {
  fontSize: "18px",
  fontWeight: "600",
};
export const semibold16 = {
  fontSize: "16px",
  fontWeight: "600",
};
export const bold14 = {
  fontSize: "14px",
  fontWeight: "800",
};
export const bold20 = {
  fontSize: "20px",
  fontWeight: "800",
};

export const rzlt = {
  width: "591px",
  height: "675px",
  borderRadius: "36px",
  backgroundColor: "background.paper",
  shadowColor: "#000000",
  shadowOffset: {
    width: 0,
    height: 3,
  },
  shadowRadius: 78,
  shadowOpacity: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  webkitBoxShadow: "10px 10px 42px 0px rgba(0,0,0,0.75)",
  mozBoxShadow: "10px 10px 42px 0px rgba(0,0,0,0.75)",
  boxShadow: "10px 10px 42px 0px rgba(0,0,0,0.75)",
};

export const rzltwhite = {
  width: "591px",
  height: "675px",
  borderRadius: "36px",
  backgroundColor: "background.paper",
  shadowColor: "#ffffff",
  shadowOffset: {
    width: 0,
    height: 3,
  },
  shadowRadius: 78,
  shadowOpacity: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  webkitBoxShadow: "10px 10px 42px 0px rgba(199, 199, 199, 0.75)",
  mozBoxShadow: "10px 10px 42px 0px rgba(199,199,199,0.75)",
  boxShadow: "10px 10px 42px 0px rgba(199,199,199,0.75)",
};

export const rzltcrc = {
  width: "350px",
  height: "350px",
  border: "5px solid #383838",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "6px",
  margin: "1rem",
};

export const bglotcon = {
  overflow: "hidden",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
};

export const cardss = {
  display: "flex",
  overflow: "hidden",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  borderRadius: "5px",
  borderStyle: "none",
  padding: "12px",
  transition: " padding 0.3s ease",
  width: "373px",
  cursor: "pointer",
  marginBottom: "1rem",
  height: "160px",
  "&:hover": {
    transition: " padding 0.5s ease",
    padding: "12px 16px",
  },
};

export const accountcards = {
  display: "flex",
  overflow: "hidden",
  justifyContent: "start",
  borderRadius: "5px",
  borderStyle: "none",
  padding: "12px",
  transition: " padding 0.3s ease",
  cursor: "pointer",
  marginBottom: "1rem",
  height: "100px",
  flexDirection: "row",
  alignItems: "center",
  width: "314px",
  marginLeft: "auto",
  marginRight: "auto",

  "&:hover": {
    transition: "padding 0.5s ease",
    padding: "12px 16px",
  },
};

export const cardt = {
  fontWeight: "600",
  color: "common.white",
  fontWeight: "bold",
  fontSize: "20px",
  letterSpacing: 1,
};

export const cardb = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "wrap",
  color: "common.white",
  fontSize: "16px",
  fontWeight: "bold",
};

export const svgBgstyle = {
  borderWidth: "1px",
  backgroundColor: "svgBg.main",
  width: "40px",
  height: "40px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  borderRadius: "999PX",
  marginRight: "1rem",
};

export const pageEndStyle = {
  width: "97%",
  height: "0.1rem",
  backgroundColor: "divider",
  marginTop: "4rem",
  background: "rgb(183,129,3)",
  background:
    "linear-gradient(90deg, rgba(183,129,3,1) 0%, rgba(214,184,124,1) 20%, rgba(255,225,106,1)  40%,  rgba(255,193,7,1) 60%,rgba(214,184,124,1)  80%, rgba(183,129,3,1) 100%)",
};

export const titledvdr = {
  width: "60px",
  backgroundColor: "primary.goldtag",
  height: 1,
  marginBottom: "1.5rem",
};

export const frm4123 = {
  display: "flex",
  gap: "3rem",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexDirection: { xs: "column", md: "row" },
};

export const flxclmncntr = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const pricescont = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  alignItems: "center",
  justifyContent: "space-around",
  marginTop: "2.5rem",
};

export const flxgp = {
  display: "flex",
  gap: "1rem",
  color: "common.white",
};

export const dvdrprice = {
  width: "60px",
  backgroundColor: "silver.main",
  height: "2px",
  marginBottom: "0.5rem",
};

export const tlt = {
  fontSize: "12px",
  paddingLeft: "2px",
};

export const ltrstyl = {
  letterSpacing: "1px",
  fontWeight: "bold",
};

export const flldvdr = {
  width: "100%",
  height: 1,
  backgroundColor: "divider.divider",
  marginBottom: "1rem",
};

export const pricebtn = {
  width: "15rem",
  alignSelf: "center",
  marginTop: "1rem",
  backgroundColor: "primary.lighter",
};
export const dividerLongGrey = {
  width: "100%",
  height: "1px",
  backgroundColor: "#eaeaea",
};
export const cardDivider = {
  width: "40px",
  height: "3px",
  mt: "10px",
};
export const whiteCard = {
  borderRadius: "13px",
  padding: "28px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
