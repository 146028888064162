import * as React from "react";
import { memo } from "react";
const Edit = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <path
      d="M30 27.2v-9.8h-2.8v9.8H4.8V4.8h9.8V2H4.8A2.8 2.8 0 0 0 2 4.8v22.4A2.8 2.8 0 0 0 4.8 30h22.4a2.8 2.8 0 0 0 2.8-2.8zM26.049 3.013a3.438 3.438 0 0 0-4.876 0L9.457 14.731A5.666 5.666 0 0 0 7.6 18.5v5.9h5.788a5.669 5.669 0 0 0 3.952-1.93l11.65-11.642a3.446 3.446 0 0 0 0-4.874zM13.29 21.607a2.906 2.906 0 0 0 2-1.04l8.487-8.487-3.854-3.857-8.549 8.546a2.9 2.9 0 0 0-.975 1.845V21.6zM21.9 6.244l3.859 3.856 1.251-1.251a.646.646 0 0 0 0-.914l-2.945-2.946a.638.638 0 0 0-.906 0z"
      data-name="24 / basic / pencil-edit"
      style={{
        fill: "#d6b77c",
        fillRule: "evenodd",
      }}
      transform="translate(-2 -2)"
    />
  </svg>
);
const Memo = memo(Edit);
export default Memo;
