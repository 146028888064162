import React from "react";
import { Box, Typography, Grid, Avatar } from "@mui/material";
import { cardDivider, semibold18 } from "src/theme/customStyles";
import Chart from "react-apexcharts";

export const KycStatus = ({ title, data }) => {
  const chartOptions = {
    chart: {
      type: "donut",
    },
    labels: data != [] ? data.labels : [],
    colors: data != [] ? data.colors : [],
    legend: {
      position: "bottom",
      labels: {
        colors: ["text.primary", "text.primary", "text.primary"],
      },
    },

    plotOptions: {
      pie: {
        donut: {
          size: "65%",
        },
      },
    },
  };

  const chartSeries = data != [] ? data.series : [];
  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
        backgroundColor: "background.card",
        borderRadius: "8px",
        boxShadow: 1,
      }}
    >
      <Typography sx={semibold18}> {title}</Typography>
      <Box sx={{ ...cardDivider, bgcolor: "#d6b87c", mb: "40px" }} />

      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "column" },
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="donut"
          //width="350"
        />

        {data != [] && (
          <Box>
            {data.details.map((detail, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "2vh",
                  }}
                >
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      bgcolor: detail.color,
                      mr: 1,
                    }}
                  />
                  <Box sx={{ mt: -0.5 }}>
                    <Typography variant="body2">Kişi Sayısı</Typography>
                    <Typography
                      variant="body2"
                      sx={{ ml: "auto", fontWeight: "bold" }}
                    >
                      {detail.value}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Grid>
  );
};
