// ----------------------------------------------------------------------
import global from "src/Global";

const account = {
  displayName: "Goldtag Admin",
  email: localStorage.getItem("Mail"),
  photoURL: require("../assets/Images/Avatar/Avatar2.png"),
};

export default account;
