import * as React from "react";
import { memo } from "react";
const Error = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72} {...props}>
    <path
      d="M37 73a36 36 0 1 1 36-36 36 36 0 0 1-36 36zm0-6.545A29.455 29.455 0 1 0 7.545 37 29.454 29.454 0 0 0 37 66.455zm-10.777-14.05L37 41.628l10.777 10.777 4.628-4.628L41.628 37l10.777-10.777-4.628-4.628L37 32.372 26.223 21.595l-4.628 4.628L32.372 37 21.595 47.777z"
      data-name="24 / basic / circle-x"
      style={{
        fill: "#d6b87c",
        fillRule: "evenodd",
      }}
      transform="translate(-1 -1)"
    />
  </svg>
);
const Memo = memo(Error);
export default Memo;
