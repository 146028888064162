import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

const TransactionTypeSelect = ({
  variant,
  type,
  setType,
  errorType,
  setErrorType,
}) => {
  const handleChangeType = (e) => {
    setType(e.target.value);
    setErrorType(false);
  };

  return (
    <FormControl
      sx={{ minWidth: 155 }}
      variant={variant ? variant : "outlined"}
      error={errorType}
    >
      <InputLabel id="demo-simple-select-label">İşlem Türü Seçiniz</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={type}
        label="İşlem Türü Seçiniz"
        onChange={handleChangeType}
      >
        <MenuItem value={1}>Alış</MenuItem>
        <MenuItem value={2}>Satış</MenuItem>
        <MenuItem value={3}>Transfer</MenuItem>
      </Select>
    </FormControl>
  );
};

export default TransactionTypeSelect;
