import {
  Box,
  Button,
  Card,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EmtiaSelect from "src/components/Select/EmtiaSelect";
import VendorSelect from "src/components/Select/VendorSelect";
import { AddTransferTransaction } from "src/services/api";

const AddTransferTransactionCard = ({
  setLoading,
  setOpenAlert,
  setAlertMessage,
  setAlertStatus,
}) => {
  const [vendor, setVendor] = useState("");
  const [errorVendor, setErrorVendor] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({});

  const [sendingUserId, setSendingUserId] = useState(""); //string
  const [receivingUserId, setReceivingUserId] = useState(""); //string

  const [emtia, setEmtia] = React.useState("");
  const [errorEmtia, setErrorEmtia] = useState(false);
  const [selectedEmtia, setSelectedEmtia] = useState(false);

  const [emtiaAmount, setEmtiaAmount] = useState("");

  const [responseSuccess, setResponseSuccess] = useState(false);

  const AddTransfer = async () => {
    try {
      const response = await AddTransferTransaction({
        VendorSuffix: vendor,
        SendingUserId: sendingUserId,
        ReceivingUserId: receivingUserId,
        CommoditySuffix: emtia,
        CommodityAmount: emtiaAmount,
      });

      if (response && response.Success) {
        setAlertMessage(response.Message);
        setAlertStatus(true);
        setOpenAlert(true);
        setResponseSuccess(true);
      } else {
        setAlertMessage(response.Message);
        setAlertStatus(false);
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertMessage("Bir şeyler ters gitti! | Catch ERROR");
      setAlertStatus(false);
      setOpenAlert(true);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setLoading(false);
      }, 2000);
      setTimeout(() => {
        setResponseSuccess(false);
      }, 4000);
    }
  };

  async function clearAll() {
    await setVendor("");
    await setSendingUserId("");
    await setReceivingUserId("");
    await setEmtiaAmount("");
    await setEmtia("");
  }
  useEffect(() => {
    if (responseSuccess == true) {
      clearAll();
    }
  }, [responseSuccess]);

  const handleFetch = () => {
    if (
      vendor !== "" &&
      sendingUserId !== "" &&
      receivingUserId !== "" &&
      emtiaAmount !== "" &&
      emtia !== ""
    ) {
      AddTransfer();
    }
  };

  return (
    <Card
      sx={{
        p: 3,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: { md: "30rem", xs: "20rem" },
        margin: "0 auto",
        marginTop: 5,
        minWidth: "20rem",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Transfer İşlemleri Ekle
      </Typography>
      <Divider
        sx={{
          width: "10%",
          backgroundColor: "primary.goldtag",
          height: 3,
          mb: 3,
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {/* VENDOR FORM CONTROL */}
        <VendorSelect
          vendor={vendor}
          setVendor={setVendor}
          errorVendor={errorVendor}
          setErrorVendor={setErrorVendor}
          setVendorInfo={setVendorInfo}
        />

        <TextField
          id="outlined-basic"
          label="Gönderen ID Giriniz"
          variant="outlined"
          type="text"
          value={sendingUserId}
          onChange={(e) => setSendingUserId(e.target.value)}
        />

        <TextField
          id="outlined-basic"
          label="Alıcı ID Giriniz"
          variant="outlined"
          type="text"
          value={receivingUserId}
          onChange={(e) => setReceivingUserId(e.target.value)}
        />

        {/* EMTIA FORM CONTROL */}
        <EmtiaSelect
          emtia={emtia}
          setEmtia={setEmtia}
          errorEmtia={errorEmtia}
          setErrorEmtia={setErrorEmtia}
          setSelectedEmtia={setSelectedEmtia}
        />

        <TextField
          id="outlined-basic"
          label="Emtia Miktrarını Giriniz"
          variant="outlined"
          type="number"
          value={emtiaAmount}
          onChange={(e) => setEmtiaAmount(e.target.value)}
        />

        <Button variant="contained" onClick={() => handleFetch()}>
          Ekle
        </Button>
      </Box>
    </Card>
  );
};

export default AddTransferTransactionCard;
