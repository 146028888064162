import * as React from "react";
const Wedding = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} {...props}>
    <defs>
      <filter id="a">
        <feOffset dx={10} />
        <feGaussianBlur result="blur" stdDeviation={5} />
        <feFlood floodOpacity={0.027} result="color" />
        <feComposite in="SourceGraphic" in2="blur" operator="out" />
        <feComposite in="color" operator="in" />
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs>
    <g data-name="Group 42941">
      <g data-type="innerShadowGroup" transform="translate(-451 -462)">
        <rect
          width={45}
          height={45}
          fill={props.darkMode ? "#fff" : "#383838"}
          data-name="icon-bg"
          rx={22.5}
          transform="translate(451 462)"
        />
        <g filter="url(#a)" transform="translate(451 462)">
          <rect
            width={45}
            height={45}
            fill="#fff"
            data-name="icon-bg"
            rx={22.5}
          />
        </g>
      </g>
      <g data-name="wedding-event">
        <path
          fill="#d6b87c"
          d="M18.486 27.399a8.52 8.52 0 0 1 1.629-5.041 5.672 5.672 0 0 1 1.852 1.488 6.23 6.23 0 0 0 .114 7.259.481.481 0 0 0 .045.059 6.259 6.259 0 0 0 2.388 1.91.252.252 0 0 0 .038.016 6.159 6.159 0 0 0 2.53.539 6.231 6.231 0 1 0-2.339-12.006 9.184 9.184 0 0 0-1.851-1.737 8.606 8.606 0 1 1-4.4 7.512Zm-9.487 0a8.6 8.6 0 1 1 15.564 5.043 5.691 5.691 0 0 1-1.853-1.49 6.224 6.224 0 0 0-.113-7.256.259.259 0 0 0-.046-.062 6.293 6.293 0 0 0-2.388-1.91.315.315 0 0 0-.035-.014 6.229 6.229 0 1 0-.193 11.467 9.32 9.32 0 0 0 1.851 1.737 8.6 8.6 0 0 1-12.787-7.515Zm12.512-10.985-3.343-3.17a2.485 2.485 0 0 1 3.514-3.515l.66.658.657-.66a2.486 2.486 0 1 1 3.514 3.517l-3.344 3.17a1.176 1.176 0 0 1-1.658 0Z"
          data-name="Union 13"
        />
      </g>
      <path fill="none" d="M9 9h27v27H9z" data-name="Rectangle 673" />
    </g>
  </svg>
);
export default Wedding;
