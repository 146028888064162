import { Box, Grid, Typography } from "@mui/material";
import gsap from "gsap";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Users from "src/assets/Images/User";
import { cardb, cardss, cardt, svgBgstyle } from "src/theme/customStyles";
import { getBackgroundColor } from "src/utils/helpers/helper";

function AccountCard(props) {
  const darkMode = useSelector((state) => state.darkMode.value);

  useEffect(() => {
    gsap.from(".custom-card", {
      duration: 1, // Animasyon süresi
      opacity: 0, // Başlangıçta opaklık 0
      x: -50, // Başlangıçta -100px soldan gelerek başlayacak
      ease: "power3.out", // Animasyonun hareket eğrisi
      stagger: 0.2, // Her kart arasında 0.2 saniye gecikme
    });
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            className="custom-card"
            sx={[
              cardss,
              {
                background: getBackgroundColor("4"),
                height: "100px",
                flexDirection: "row",
                alignItems: "center",
                width: "314px",
                marginLeft: "auto",
                marginRight: "auto",
                "&:hover": {
                  transition: "padding 0.5s ease",
                  padding: "12px 24px",
                },
              },
            ]}
          >
            {" "}
            <Box sx={svgBgstyle}>
              <Users color={darkMode ? "#6f6f6f" : "#181818"} />
            </Box>
            <Box sx={{ marginBottom: "auto", marginTop: "auto" }}>
              <Typography sx={[cardb]}>Toplam Hesap</Typography>
              <Typography sx={[cardt]}>134 Hesap</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            className="custom-card"
            sx={[
              cardss,
              {
                background: getBackgroundColor("1"),
                height: "100px",
                flexDirection: "row",
                alignItems: "center",
                width: "314px",
                marginLeft: "auto",
                marginRight: "auto",
                "&:hover": {
                  transition: "padding 0.5s ease",
                  padding: "12px 24px",
                },
              },
            ]}
          >
            {" "}
            <Box sx={svgBgstyle}>
              <img
                src={require("../../../assets/Images/AppImages/gold.png")}
                alt="gold"
                width={32}
                height={32}
              />
            </Box>
            <Box sx={{ marginBottom: "auto", marginTop: "auto" }}>
              <Typography sx={[cardb]}>Toplam Altın Miktarı</Typography>
              <Typography sx={[cardt]}>3253132345 gr</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            className="custom-card"
            sx={[
              cardss,
              {
                background: getBackgroundColor("2"),
                height: "100px",
                "&:hover": {
                  transition: "padding 0.5s ease",
                  padding: "12px 24px",
                },
                flexDirection: "row",
                alignItems: "center",
                width: "314px",
                marginLeft: "auto",
                marginRight: "auto",
              },
            ]}
          >
            {" "}
            <Box sx={svgBgstyle}>
              <img
                src={require("../../../assets/Images/silver.png")}
                alt="gold"
                width={32}
                height={32}
              />
            </Box>
            <Box sx={{ marginBottom: "auto", marginTop: "auto" }}>
              <Typography sx={[cardb]}>Toplam Gümüş Miktarı</Typography>
              <Typography sx={[cardt]}>3253132345 gr</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            className="custom-card"
            sx={[
              cardss,
              {
                background: getBackgroundColor("5"),
                height: "100px",
                "&:hover": {
                  transition: "padding 0.5s ease",
                  padding: "12px 24px",
                },
                flexDirection: "row",
                alignItems: "center",
                width: "314px",
                marginLeft: "auto",
                marginRight: "auto",
              },
            ]}
          >
            {" "}
            <Box sx={svgBgstyle}>
              <img
                src={require("../../../assets/Images/platinium.png")}
                alt="gold"
                width={32}
                height={32}
              />
            </Box>
            <Box sx={{ marginBottom: "auto", marginTop: "auto" }}>
              <Typography sx={[cardb]}>Toplam Platin Miktarı</Typography>
              <Typography sx={[cardt]}>3253132345 gr</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AccountCard;
