import { generalAxiosInstance } from "../axios/axiosConfig";

export async function Last10UserTransaction(userId) {
  const body = {
    userId: userId,
  };
  try {
    const response = await generalAxiosInstance.get(
      "/Last10UserTransaction",
      body
    );
    return response.data;
  } catch (error) {
    return error.response?.data || "An error occurred";
  }
}
