import React, { useEffect, useMemo, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";

import DateRangePicker from "src/components/DatePicker/DateRangePicker";
import VendorSelect from "src/components/Select/VendorSelect";
import EmtiaSelect from "src/components/Select/EmtiaSelect";
import { pageContainer } from "src/utils/customStyles";
import TransactionCards from "src/components/Cards/TransactionsCard";
import { GetAllTransactions } from "src/services/api";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import dayjs from "dayjs";
const Transactions = () => {
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [vendor, setVendor] = React.useState("");
  const [errorVendor, setErrorVendor] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({});

  const [emtia, setEmtia] = React.useState("");
  const [errorEmtia, setErrorEmtia] = useState(false);
  const [errorEnd, setErrorEnd] = useState(false);
  const [errorStart, setErrorStart] = useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [startDay, setStartDay] = React.useState("");
  const [startMonth, setStartMonth] = React.useState("");
  const [startYear, setStartYear] = React.useState("");

  const [endDay, setEndDay] = React.useState("");
  const [endMonth, setEndMonth] = React.useState("");
  const [endYear, setEndYear] = React.useState("");
  const [data, setData] = React.useState([]);
  const [selectedEmtia, setSelectedEmtia] = useState(false);

  const handleButton = () => {
    isEmptyVariable();
  };

  const isEmptyVariable = async () => {
    console.log(startDay, startMonth, startYear);
    console.log(endDay, endMonth, endYear);

    console.log(vendor);
    console.log(emtia);

    try {
      setLoading(true);
      const ans = await GetAllTransactions({
        VendorSuffix: vendor,
        CommoditySuffix: emtia,
        StartDate: `${startYear}-${startMonth}-${startDay}T00:00:00`,
        EndDate: `${endYear}-${endMonth}-${endDay}T23:59:59`,
      });
      if (ans && ans.Success) {
        setData(ans.Data);
        setAlertMessage(ans.Message);
        setAlertStatus(true);
        setOpenAlert(true);
      } else {
        setAlertMessage(ans.Message);
        setAlertStatus(false);
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertMessage("Bir şeyler ters gitti! | Catch ERROR");
      setAlertStatus(false);
      setOpenAlert(true);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setLoading(false);
      }, 1000);
      setSelectedEmtia(false);
    }

    vendor == "" && setErrorVendor(true);
    emtia == "" && setErrorEmtia(true);
    endDate == "00/00/0000" && setErrorEnd(true);
    startDate == "00/00/0000" && setErrorStart(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <Box sx={pageContainer}>
      <Typography
        variant="h3"
        sx={{
          letterSpacing: "3px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        İşlemler
        <Divider
          sx={{
            width: "100px",
            backgroundColor: "primary.goldtag",
            height: 2,
            marginBottom: "2rem",
          }}
        />
      </Typography>{" "}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          gap: "1rem",
          marginBottom: "3rem",
        }}
      >
        {/* VENDOR FORM CONTROL */}
        <VendorSelect
          vendor={vendor}
          setVendor={setVendor}
          errorVendor={errorVendor}
          setErrorVendor={setErrorVendor}
          setVendorInfo={setVendorInfo}
        />
        {/* EMTIA FORM CONTROL */}
        <EmtiaSelect
          emtia={emtia}
          setEmtia={setEmtia}
          errorEmtia={errorEmtia}
          setErrorEmtia={setErrorEmtia}
          setSelectedEmtia={setSelectedEmtia}
        />
        {/* DATE PICKER */}
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setStartDay={setStartDay}
          setStartMonth={setStartMonth}
          setStartYear={setStartYear}
          setEndDay={setEndDay}
          setEndMonth={setEndMonth}
          setEndYear={setEndYear}
          errorStart={errorStart}
          errorEnd={errorEnd}
          setErrorStart={setErrorStart}
          setErrorEnd={setErrorEnd}
          type={"range"}
        />

        <Button
          variant="contained"
          onClick={handleButton}
          sx={{ marginLeft: 3, height: 35 }}
        >
          Listele
        </Button>
      </Box>
      <TransactionCards
        emtia={emtia}
        data={data}
        loading={loading}
        selectedEmtia={selectedEmtia}
      />
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
      <LoadingOverlay isOpen={loading} />
    </Box>
  );
};

export default Transactions;
