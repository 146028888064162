import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomCard from "src/components/Cards/CustomCard";
import { pageContainer } from "src/utils/customStyles";
import { GetGatewayPrices, setActiveProviderPrice } from "src/services/api";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import {
  flxclmncntr,
  frm4123,
  pageHeader,
  titledvdr,
} from "src/theme/customStyles";

const MainPage = () => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fintagData = data.filter((item) => item.ProviderName === "Fintag");
  const kuveytTurkData = data.filter(
    (item) => item.ProviderName === "KuveytTurk"
  );
  const altindata = data.filter((item) => item.ProviderName === "Altinkaynak");

  useEffect(() => {
    GetGatewayPrices(setData);
  }, [loading]);

  const handleButtonClick = async (row) => {
    setLoading(true);
    const payload = {
      CommoditySuffix: row.CommoditySuffix,
      ProviderSuffix: row.ProviderSuffix,
    };

    try {
      const ans = await setActiveProviderPrice(payload);
      console.log(ans.Message);
      if (ans && ans.Success) {
        setAlertMessage(ans.Message);
        setAlertStatus(true);
        setOpenAlert(true);
      } else {
        setAlertMessage(ans.Message);
        setAlertStatus(false);
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertMessage("Bir şeyler ters gitti! | Catch ERROR");
      setAlertStatus(false);
      setOpenAlert(true);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setLoading(false);
      }, 1000);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const renderTable = (providerData) => (
    <StyledTableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Durum</StyledTableCell>
            <StyledTableCell align="center">Ürün Adı</StyledTableCell>
            <StyledTableCell align="center">
              Görünen Alış Fiyatı
            </StyledTableCell>
            <StyledTableCell align="center">
              Görünen Satış Fiyatı
            </StyledTableCell>
            <StyledTableCell align="center">İşlem</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {providerData.map((row) => (
            <StyledTableRow key={row.CommoditySuffix}>
              <TableCell align="center">
                <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    borderRadius: "99px",
                    backgroundColor: row.IsActive ? "success.main" : "gray",
                  }}
                  align="center"
                />
              </TableCell>

              <TableCell align="center">{row.CommodityName}</TableCell>
              <TableCell align="center">{row.ViewBuyPrice}</TableCell>
              <TableCell align="center">{row.ViewSellPrice}</TableCell>

              <TableCell align="center">
                {!row.IsActive && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleButtonClick(row)}
                    size="small"
                    sx={{ fontSize: "12px" }}
                  >
                    Aktif Et
                  </Button>
                )}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </StyledTableContainer>
  );

  return (
    <Box sx={[pageContainer]}>
      <Box style={flxclmncntr}>
        <Typography variant="h3" sx={{ letterSpacing: "3px" }}>
          Anasayfa
        </Typography>
        <Divider sx={titledvdr} />
      </Box>
      <Box sx={frm4123}>
        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
          <Box sx={{ paddingLeft: "0.5rem" }}>
            <Typography variant="h6" sx={{ letterSpacing: "3px" }}>
              Fintag
            </Typography>
            <Divider sx={[titledvdr, { marginBottom: "0.5rem" }]} />
          </Box>

          {renderTable(fintagData)}
          <Typography
            variant="h6"
            sx={{ letterSpacing: "3px", marginTop: "2rem" }}
          >
            KuveytTurk
          </Typography>
          <Divider sx={[titledvdr, { marginBottom: "0.5rem" }]} />
          {renderTable(kuveytTurkData)}

          <Typography
            variant="h6"
            sx={{ letterSpacing: "3px", marginTop: "2rem" }}
          >
            Altınkaynak
          </Typography>
          <Divider sx={[titledvdr, { marginBottom: "0.5rem" }]} />
          {renderTable(altindata)}
        </Box>

        <Box sx={{ width: { xs: "100%", md: "35%" } }}>
          <Box sx={{ paddingLeft: "0.5rem" }}>
            <Typography variant="h6" sx={{ letterSpacing: "3px" }}>
              Vendorlar
            </Typography>
            <Divider sx={titledvdr} />
          </Box>

          <CustomCard />
        </Box>
      </Box>

      <Box sx={pageHeader} />

      <LoadingOverlay isOpen={loading} />
    </Box>
  );
};

export default MainPage;

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  margin: "0",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));
