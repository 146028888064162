import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useMaterialReactTable } from "material-react-table";
import React, { useEffect, useState } from "react";
import EmtiaSelect from "src/components/Select/EmtiaSelect";
import VendorSelect from "src/components/Select/VendorSelect";
import { AddBuySellTransactions, AddVendorFeatureAPI } from "src/services/api";

const AddVendorFeature = ({
  setLoading,
  setOpenAlert,
  setAlertMessage,
  setAlertStatus,
}) => {
  const [vendor, setVendor] = useState("");
  const [errorVendor, setErrorVendor] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({});

  const [userId, setUserId] = useState(""); //string
  const [isBuy, setIsBuy] = useState(null); //string
  const [sellPipe, setSellPipe] = useState("");

  const [emtia, setEmtia] = React.useState("");
  const [errorEmtia, setErrorEmtia] = useState(false);
  const [selectedEmtia, setSelectedEmtia] = useState(false);

  const [buyPipe, setBuyPipe] = useState("");
  const [errorIsBuy, setErrorIsBuy] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(false);

  const AddBuySell = async () => {
    try {
      const response = await AddVendorFeatureAPI({
        VendorSuffix: vendor,
        CommoditySuffix: emtia,
        BuyPipe: buyPipe,
        SellPipe: sellPipe,
      });

      if (response && response.Success) {
        setAlertMessage(response.Message);
        setAlertStatus(true);
        setOpenAlert(true);
        setResponseSuccess(true);
      } else {
        setAlertMessage(response.Message);
        setAlertStatus(false);
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertMessage("Bir şeyler ters gitti! | Catch ERROR");
      setAlertStatus(false);
      setOpenAlert(true);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setLoading(false);
      }, 2000);
      setTimeout(() => {
        setResponseSuccess(false);
      }, 4000);
    }
  };

  async function clearAll() {
    setVendor("");
    setUserId("");
    setIsBuy("");
    setBuyPipe("");
    setEmtia("");
    setSellPipe("");
  }
  useEffect(() => {
    if (responseSuccess == true) {
      clearAll();
    }
  }, [responseSuccess]);

  const handleFetch = () => {
    if (
      vendor !== "" ||
      userId !== "" ||
      isBuy !== "" ||
      buyPipe !== "" ||
      emtia !== "" ||
      sellPipe !== ""
    ) {
      AddBuySell();
    }
  };

  return (
    <Card
      sx={{
        p: 3,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: { md: "30rem", xs: "20rem" },
        margin: "0 auto",
        marginTop: 5,
        minWidth: "20rem",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Add Vendor Feature
      </Typography>
      <Divider
        sx={{
          width: "10%",
          backgroundColor: "primary.goldtag",
          height: 3,
          mb: 3,
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {/* VENDOR FORM CONTROL */}
        <VendorSelect
          vendor={vendor}
          setVendor={setVendor}
          errorVendor={errorVendor}
          setErrorVendor={setErrorVendor}
          setVendorInfo={setVendorInfo}
        />

        {/* EMTIA FORM CONTROL */}
        <EmtiaSelect
          emtia={emtia}
          setEmtia={setEmtia}
          errorEmtia={errorEmtia}
          setErrorEmtia={setErrorEmtia}
          setSelectedEmtia={setSelectedEmtia}
        />
        <TextField
          id="outlined-basic"
          label="Buy Pipe"
          variant="outlined"
          type="number"
          value={buyPipe}
          onChange={(e) => {
            const value = e.target.value;
            setBuyPipe(value);
          }}
        />

        <TextField
          id="outlined-basic"
          label="Sell Pipe"
          variant="outlined"
          type="number"
          value={sellPipe}
          onChange={(e) => {
            const value = e.target.value;
            setSellPipe(value);
          }}
        />

        <Button variant="contained" onClick={() => handleFetch()}>
          Ekle
        </Button>
      </Box>
    </Card>
  );
};

export default AddVendorFeature;
