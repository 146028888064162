import * as React from "react";
const AccountsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20.659} {...props}>
    <path
      d="M16.123 1.407A2.1 2.1 0 0 1 18.8 3.426v1.758h2.1a2.1 2.1 0 0 1 2.1 2.1v12.6a2.1 2.1 0 0 1-2.1 2.1H4.1a2.1 2.1 0 0 1-2.1-2.1h.028A2.1 2.1 0 0 1 2 19.542V7.026a2.1 2.1 0 0 1 1.523-2.019zm-5.576 18.477H20.9v-8.4h-2.1v4.458a2.1 2.1 0 0 1-1.523 2.019zM20.9 7.284v2.1h-2.1v-2.1zM4.1 7.026v12.516l12.6-3.6V3.426zm10.5 2.358a1.05 1.05 0 1 1-1.05-1.05 1.05 1.05 0 0 1 1.05 1.05z"
      data-name="24 / finance / wallet"
      style={{
        fill: props.darkMode ? "#383838" : "#fff",
        fillRule: "evenodd",
      }}
      transform="translate(-2 -1.325)"
    />
  </svg>
);
export default AccountsIcon;
