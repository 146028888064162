import {
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import { flxclmncntr, titledvdr } from "src/theme/customStyles";
import { pageContainer } from "src/utils/customStyles";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { handleExportData, handleExportRows } from "src/utils/helpers/helper";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import { GetUsersTransactions } from "src/services/AdminPanelApis/UsersTransactionsApi";
import DateRangePicker from "src/components/DatePicker/DateRangePicker";
import { formatDate } from "src/utils/helpers/helper";
import AllTransactionsTable from "src/components/AppPanelComponents/AllTransactionsTable";
import {
  CommoditySuffixValue,
  TransactionTypesWithNum,
} from "src/utils/helpers";

const TransactionsPage = () => {
  const [change, setChange] = useState("tümİşlemler");
  const [startDate, setStartDate] = useState("00/00/0000");
  const [endDate, setEndDate] = useState("00/00/0000");

  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [errorEnd, setErrorEnd] = useState(false);
  const [errorStart, setErrorStart] = useState(false);

  const [startDay, setStartDay] = React.useState("");
  const [startMonth, setStartMonth] = React.useState("");
  const [startYear, setStartYear] = React.useState("");

  const [endDay, setEndDay] = React.useState("");
  const [endMonth, setEndMonth] = React.useState("");
  const [endYear, setEndYear] = React.useState("");

  const [userId, setUserId] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
  });

  const [data, setData] = useState([]);
  const [boolean, setBoolean] = useState(false);
  const isInitialRender = React.useRef(true);

  const fetchUserTransactions = async () => {
    try {
      const response = await GetUsersTransactions({
        UserId: userId,
        Page: pagination.page,
        PageSize: pagination.pageSize,
        StartDate: `${startYear}-${startMonth}-${startDay}`,
        EndDate: `${endYear}-${endMonth}-${endDay}`,
        TransactionType: transactionType,
      });
      if (response.Success) {
        const data = response.Data;
        const array = data.map((item) => ({
          UserId: item.Id,
          Name: item.SourceId,
          TransactionType: TransactionTypesWithNum[item.TransactionType],
          CommoditySuffix: CommoditySuffixValue[item.CommoditySuffix],
          CommodityAmount: `${item.CommodityAmount} gr`,
          TlAmount: `${item.TlAmount} TL`,
          TransactionDate: formatDate(item.TransactionDate),
        }));
        setData(array);
      } else {
        console.error("API error: ", response.Message);
        setOpenAlert(true);
      }
    } catch (error) {
      console.error("Error fetching GetAllUser:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlefetch = () => {
    if (userId == "") {
      setOpenAlert(true);
    } else if (transactionType == null) {
      setOpenAlert(true);
    } else if (startDay == "") {
      setOpenAlert(true);
    } else if (endDay == "") {
      setOpenAlert(true);
    } else {
      setLoading(true);
      setBoolean(true);
      fetchUserTransactions();
    }
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "UserId",
        header: "UserId",
        size: 120,
      },
      {
        accessorKey: "Name",
        header: "İsim",
        size: 180,
      },
      {
        accessorKey: "TransactionType",
        header: "İşlem Türü",
        size: 30,
      },
      {
        accessorKey: "CommoditySuffix",
        header: "Emtia Türü",
        size: 30,
      },
      {
        accessorKey: "CommodityAmount",
        header: "Emtia Miktarı",
        size: 20,
      },
      {
        accessorKey: "TlAmount",
        header: "TL Miktarı",
        size: 30,
      },
      {
        accessorKey: "TransactionDate",
        header: "İşlem Zamanı",
        size: 30,
      },
    ],
    []
  );

  const handlePaginationChange = (pagination) => {
    setPagination(pagination);
  };

  const handleBack = () => {
    const newPage = pagination.page - 1;
    if (newPage > 0) {
      setPagination({ page: newPage, pageSize: 10 });
    }
    console.log(pagination.page);
  };
  const handleNext = () => {
    const newPage = pagination.page + 1;
    setPagination({ page: newPage, pageSize: 10 });
    console.log(pagination.page);
  };

  const handleChange = (newValue) => {
    setChange(newValue);
  };

  React.useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      if (pagination.page) {
        handlefetch();
      }
    }
  }, [pagination.page]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    state: { pagination },
    enableBottomToolbar: false,
    manualPagination: true,
    onPaginationChange: handlePaginationChange,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          marginBottom: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(data, "Transferler")}
          startIcon={<FileDownloadIcon />}
        >
          Tüm Verileri Dışa Aktar
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(
              table.getPrePaginationRowModel().rows,
              "Transferler"
            )
          }
          startIcon={<FileDownloadIcon />}
        >
          Tüm Satırları Dışa Aktar
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() =>
            handleExportRows(table.getRowModel().rows, "Transferler")
          }
          startIcon={<FileDownloadIcon />}
        >
          Bu Sayfadaki Satırları Dışa Aktar
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() =>
            handleExportRows(table.getSelectedRowModel().rows, "Transferler")
          }
          startIcon={<FileDownloadIcon />}
        >
          Seçilmiş Satırları Dışa Aktar
        </Button>
      </Box>
    ),
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  return (
    <Box sx={pageContainer}>
      <Box style={flxclmncntr}>
        <Typography variant="h3" sx={{ letterSpacing: "3px" }}>
          İşlemler
        </Typography>
        <Divider sx={titledvdr} />
      </Box>

      <Box
        sx={{
          justifyContent: "space-evenly",
          display: "flex",
          marginBottom: 5,
        }}
      >
        <Button
          sx={{
            backgroundColor: change === "tümİşlemler" ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange("tümİşlemler")}
          variant="contained"
          fullWidth
        >
          Tüm İşlemler
        </Button>
        <Button
          sx={{
            backgroundColor: change === "userID" ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange("userID")}
          variant="contained"
          fullWidth
        >
          İşlemler
        </Button>
      </Box>

      {change == "tümİşlemler" && (
        <>
          <AllTransactionsTable />
        </>
      )}

      {change == "userID" && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              gap: "1rem",
              marginBottom: "3rem",
            }}
          >
            <TextField
              id="outlined-basic"
              label="UserId"
              variant="outlined"
              type="number"
              sx={{
                borderRadius: "10px",
              }}
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Transaction Type"
              variant="outlined"
              type="number"
              sx={{
                borderRadius: "10px",
              }}
              value={transactionType}
              onChange={(e) => setTransactionType(e.target.value)}
            />
            {/* DATE PICKER */}
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setStartDay={setStartDay}
              setStartMonth={setStartMonth}
              setStartYear={setStartYear}
              setEndDay={setEndDay}
              setEndMonth={setEndMonth}
              setEndYear={setEndYear}
              errorStart={errorStart}
              errorEnd={errorEnd}
              setErrorStart={setErrorStart}
              setErrorEnd={setErrorEnd}
              type={"range"}
            />

            <Button
              variant="contained"
              onClick={handlefetch}
              sx={{ marginLeft: 3, height: 35 }}
            >
              Listele
            </Button>
          </Box>

          {loading ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "500px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingOverlay isOpen={loading} />
            </Box>
          ) : boolean ? (
            <>
              <MaterialReactTable table={table} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: 2,
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleBack}
                  sx={{ marginLeft: 3, height: 35 }}
                >
                  Geri
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ marginLeft: 3, height: 35 }}
                >
                  İleri
                </Button>
              </Box>
            </>
          ) : null}
        </>
      )}

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          Tüm alanları doldurun!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TransactionsPage;
