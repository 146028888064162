import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { bold20, cardDivider, semibold18 } from "src/theme/customStyles";
import Edit from "src/assets/Icons/AppIcons/Edit";
import Gold from "src/assets/Images/AppImages/gold.png";
import Silver from "src/assets/Images/AppImages/silver.png";
import Platin from "src/assets/Images/AppImages/pllatin.png";
import { Close } from "@mui/icons-material";
import Check from "src/assets/Icons/AppIcons/Check";
import Error from "src/assets/Icons/AppIcons/Error";
import GoldCircle from "src/assets/Images/AppImages/goldCircle.webp";
import SilverCircle from "src/assets/Images/AppImages/silverCircle.webp";
import PlatinCircle from "src/assets/Images/AppImages/platinCircle.webp";
import { useTheme } from "@mui/material";
import bgStyles from "src/utils/cssStyles";
import { PriceValues } from "src/services/AdminPanelApis/PriceValuesApi";

const PriceCard = () => {
  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await PriceValues();
        if (response.Success) {
          const sortedData = response.Data.sort(
            (a, b) => a.CommoditySuffix - b.CommoditySuffix
          );
          const rates = sortedData.map((item) => ({
            currency: (
              <div
                style={{
                  ...bgStyles.gradientBackground,
                  width: "45px",
                  height: "45px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    item.CommoditySuffix == 1
                      ? GoldCircle
                      : item.CommoditySuffix == 2
                      ? SilverCircle
                      : item.CommoditySuffix == 3
                      ? PlatinCircle
                      : null
                  }
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ),
            buyRate: item.ViewBuyPrice,
            sellRate: item.ViewSellPrice,
          }));
          setPriceValue(rates);
        }
      } catch (error) {
        console.error("Error fetching PriceValues:", error);
      }
    };
    fetchPrices();
  }, []);

  const menuItems = [
    "VPosBuyPrice",
    "BuyProfitTl",
    "SellProfitTl",
    "VPosRate",
    "BuyPercentage",
    "SellPercentage",
    "BSMV",
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "row",
    borderRadius: "13px",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 300, md: 400 }, // Responsive genişlik
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    textAlign: "center", // İçeriği ortalar
  };
  const { colors } = useTheme();
  const [priceValue, setPriceValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen3 = () => setOpen3(true);
  const handleClose = () => [
    setOpen(false),
    setTextValueGold(""),
    setTextValueSilver(""),
    setTextValuePlatin(""),
  ];
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);

  const [valuesGold, setValuesGold] = React.useState({
    VPosBuyPrice: "",
    BuyProfitTl: "",
    SellProfitTl: "",
    VPosRate: "",
    BuyPercentage: "",
    SellPercentage: "",
    BSMV: "",
  });
  const [selectedTypeGold, setSelectedTypeGold] = useState("");
  const [textValueGold, setTextValueGold] = useState("");
  const handleSelectChangeGold = (e) => {
    setSelectedTypeGold(e.target.value);
    setTextValueGold("");
  };
  const handleTextChangeGold = (e) => {
    setTextValueGold(e.target.value);
  };
  const handleSaveGold = () => {
    if (selectedTypeGold) {
      setValuesGold({
        ...valuesGold,
        [selectedTypeGold]: textValueGold,
      });
    }
  };
  const [valuesSilver, setValuesSilver] = React.useState({
    VPosBuyPrice: "",
    BuyProfitTl: "",
    SellProfitTl: "",
    VPosRate: "",
    BuyPercentage: "",
    SellPercentage: "",
    BSMV: "",
  });
  const [selectedTypeSilver, setSelectedTypeSilver] = useState("");
  const [textValueSilver, setTextValueSilver] = useState("");
  const handleSelectChangeSilver = (e) => {
    setSelectedTypeSilver(e.target.value);
    setTextValueSilver("");
  };
  const handleTextChangeSilver = (e) => {
    setTextValueSilver(e.target.value);
  };
  const handleSaveSilver = () => {
    if (selectedTypeSilver) {
      setValuesSilver({
        ...valuesSilver,
        [selectedTypeSilver]: textValueSilver,
      });
    }
  };
  const [valuesPlatin, setValuesPlatin] = React.useState({
    VPosBuyPrice: "",
    BuyProfitTl: "",
    SellProfitTl: "",
    VPosRate: "",
    BuyPercentage: "",
    SellPercentage: "",
    BSMV: "",
  });
  const [selectedTypePlatin, setSelectedTypePlatin] = useState("");
  const [textValuePlatin, setTextValuePlatin] = useState("");
  const handleSelectChangePlatin = (e) => {
    setSelectedTypePlatin(e.target.value);
    setTextValuePlatin("");
  };
  const handleTextChangePlatin = (e) => {
    setTextValuePlatin(e.target.value);
  };
  const handleSavePlatin = () => {
    if (selectedTypePlatin) {
      setValuesPlatin({
        ...valuesPlatin,
        [selectedTypePlatin]: textValuePlatin,
      });
    }
  };
  return (
    <Grid
      sx={{
        p: 2,
        backgroundColor: "background.card",
        borderRadius: "8px",
        boxShadow: 1,
      }}
    >
      <div>
        <Modal open={open} sx={{}}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "row",
              borderRadius: "13px",
            }}
          >
            <Grid container spacing={2}>
              {/* {altın} */}
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    border: 1,
                    borderColor: "grey.400",
                    p: 2,
                    bgcolor: "background.price",
                    borderRadius: "9px",
                  }}
                >
                  <Typography
                    sx={{ textAlign: "center", color: "gold.main" }}
                    variant="h6"
                  >
                    Altın
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 2,
                      bgcolor: "common.white",
                      borderRadius: "9px",
                    }}
                  >
                    <InputLabel>Altın Fiyat Seçin</InputLabel>
                    <Select
                      value={selectedTypeGold}
                      onChange={handleSelectChangeGold}
                      label="Altın Fiyat Seçin"
                    >
                      {menuItems.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Değer Girin"
                    variant="outlined"
                    fullWidth
                    type="number"
                    sx={{
                      mt: 2,
                      backgroundColor: "common.white",
                      borderRadius: "10px",
                    }}
                    value={textValueGold}
                    onChange={handleTextChangeGold}
                  />
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={() => [handleSaveGold(), console.log(valuesGold)]}
                  >
                    Fiyatları Onayla
                  </Button>
                  <Box
                    sx={{
                      backgroundColor: "common.white",
                      p: "8px",
                      borderRadius: "9px",
                      mt: "16px",
                    }}
                  >
                    {menuItems.map((label) => (
                      <Box
                        key={label}
                        sx={{
                          mt: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>{label}:</Typography>
                        {valuesGold[label] == "" ? 0 : valuesGold[label]}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>

              {/* gümüş */}
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    border: 1,
                    borderColor: "grey.400",
                    p: 2,
                    bgcolor: "background.price",
                    borderRadius: "9px",
                  }}
                >
                  <Typography
                    sx={{ textAlign: "center", color: "gold.main" }}
                    variant="h6"
                  >
                    Gümüş
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 2,
                      bgcolor: "common.white",
                      borderRadius: "9px",
                    }}
                  >
                    <InputLabel>Gümüş Fiyat Seçin</InputLabel>
                    <Select
                      value={selectedTypeSilver}
                      onChange={handleSelectChangeSilver}
                      label="Gümüş Fiyat Seçin"
                    >
                      {menuItems.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Değer Girin"
                    variant="outlined"
                    fullWidth
                    type="number"
                    sx={{
                      mt: 2,
                      backgroundColor: "common.white",
                      borderRadius: "10px",
                    }}
                    value={textValueSilver}
                    onChange={handleTextChangeSilver}
                  />
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ mt: 2, bgcolor: "silver.main" }}
                    onClick={() => [
                      handleSaveSilver(),
                      console.log(valuesSilver),
                    ]}
                  >
                    Fiyatları Onayla
                  </Button>
                  <Box
                    sx={{
                      backgroundColor: "common.white",
                      p: "8px",
                      borderRadius: "9px",
                      mt: "16px",
                    }}
                  >
                    {menuItems.map((label) => (
                      <Box
                        key={label}
                        sx={{
                          mt: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>{label}:</Typography>
                        {valuesSilver[label] == "" ? 0 : valuesSilver[label]}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>
              {/* platin */}
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    border: 1,
                    borderColor: "grey.400",
                    p: 2,
                    bgcolor: "background.price",
                    borderRadius: "9px",
                  }}
                >
                  <Typography
                    sx={{ textAlign: "center", color: "gold.main" }}
                    variant="h6"
                  >
                    Platin
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 2,
                      bgcolor: "common.white",
                      borderRadius: "9px",
                    }}
                  >
                    <InputLabel>Platin Fiyat Seçin</InputLabel>
                    <Select
                      value={selectedTypePlatin}
                      onChange={handleSelectChangePlatin}
                      label="Platin Fiyat Seçin"
                    >
                      {menuItems.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Değer Girin"
                    variant="outlined"
                    fullWidth
                    type="number"
                    sx={{
                      mt: 2,
                      backgroundColor: "common.white",
                      borderRadius: "10px",
                    }}
                    value={textValuePlatin}
                    onChange={handleTextChangePlatin}
                  />
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ mt: 2, bgcolor: "platin.main" }}
                    onClick={() => [
                      handleSavePlatin(),
                      console.log(valuesPlatin),
                    ]}
                  >
                    Fiyatları Onayla
                  </Button>
                  <Box
                    sx={{
                      backgroundColor: "common.white",
                      p: "8px",
                      borderRadius: "9px",
                      mt: "16px",
                    }}
                  >
                    {menuItems.map((label) => (
                      <Box
                        key={label}
                        sx={{
                          mt: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>{label}:</Typography>
                        <Typography>
                          {valuesPlatin[label] == "" ? 0 : valuesPlatin[label]}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ cursor: "pointer", ml: "29px" }} onClick={handleClose}>
              <Close />
            </Box>
          </Box>
        </Modal>
      </div>

      {/* -------- */}
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <IconButton>
            <Check />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            Başarıyla Kaydedildi
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: 4, backgroundColor: "#d6b87c" }}
            onClick={handleClose2}
          >
            Kapat
          </Button>
        </Box>
      </Modal>
      <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <IconButton>
            <Error />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            Bir Hata Oluştu
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: 4, backgroundColor: "#d6b87c" }}
            onClick={handleClose3}
          >
            Kapat
          </Button>
        </Box>
      </Modal>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          //backgroundColor: "red",
        }}
      >
        <Box>
          <Typography sx={semibold18}>Fiyatlarımız </Typography>
          <Box sx={{ ...cardDivider, bgcolor: "#d6b87c", mb: "0px" }} />
        </Box>
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            // backgroundColor: "magenta",
            width: "40px",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => handleOpen()}
        >
          <Box
            sx={{
              display: "inline-block",
              transform: "scale(0.7)",
              width: "40px",
              height: "40px",
              // backgroundColor: "green",
            }}
          >
            <Edit />
          </Box>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableBody>
            {priceValue?.map((rate) => (
              <TableRow key={rate.currency}>
                <TableCell>{rate.currency}</TableCell>
                <TableCell sx={bold20}>
                  <Typography variant="body2">Alış Kuru</Typography>
                  {rate.buyRate}₺
                </TableCell>
                <TableCell sx={bold20}>
                  <Typography variant="body2">Satış Kuru</Typography>
                  {rate.sellRate}₺
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default PriceCard;
const visible = {
  visibility: {
    xs: "visible",
    sm: "hidden",
    md: "visible",
    lg: "visible",
  },
};
