import * as React from "react";
const G = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} {...props}>
    <g transform="translate(12268 10937)">
      <rect
        width={45}
        height={45}
        fill={props.color ? props.color : props.darkMode ? "#fff" : "#383838"}
        rx={22.5}
        transform="translate(-12268 -10937)"
      />
      <g fill="#d6b77c" data-name="ICON GRAY">
        <path
          d="M-12231.987-10914.411a13.637 13.637 0 0 1-.172 2.156 13.52 13.52 0 0 1-6.862 9.619 13.435 13.435 0 0 1-4.314 1.474 13.661 13.661 0 0 1-4.314 0 13.522 13.522 0 0 1-11.191-15.5 13.3 13.3 0 0 1 .526-2.149 13.541 13.541 0 0 1 14.974-9.028l.04 2.184a11.937 11.937 0 0 0-2.2-.2 11.506 11.506 0 0 0-2.157.2 11.378 11.378 0 0 0-8.358 6.84 11.263 11.263 0 0 0-.644 2.156 11.5 11.5 0 0 0 0 4.311 11.368 11.368 0 0 0 9 9 11.519 11.519 0 0 0 4.314 0 11.146 11.146 0 0 0 2.157-.644 11.379 11.379 0 0 0 6.826-8.261h-12.169a1.132 1.132 0 0 1-1.134-1.133v-.01a1.013 1.013 0 0 1 1.014-1.015Z"
          data-name="Path 53140"
        />
        <path
          d="M-12245.612-10920.034h2.157v2.157h-2.157z"
          data-name="Rectangle 1299"
        />
        <path
          d="M-12241.298-10912.254h2.157v2.157h-2.157z"
          data-name="Rectangle 1300"
        />
        <path
          d="M-12234.22-10915.722h-12.48a.964.964 0 0 1-.964-.964v-.149a1.045 1.045 0 0 1 1.044-1.044h11.937a11.136 11.136 0 0 1 .335 1.315c.053.278.095.558.128.842Z"
          data-name="Path 53141"
        />
        <path
          d="M-12241.298-10920.035h2.157v5.629h-2.157z"
          data-name="Rectangle 1301"
        />
        <path
          d="M-12245.612-10915.725h2.157v5.629h-2.157z"
          data-name="Rectangle 1302"
        />
      </g>
      <path
        fill="none"
        d="M-12259.014-10928.011h27.022v27.022h-27.022z"
        data-name="Rectangle 1311"
      />
    </g>
  </svg>
);
export default G;
