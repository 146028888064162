import { generalAxiosInstance } from "../axios/axiosConfig";

export async function GetKycStatus() {
  const raw = {
    Page: 1,
    PageSize: 15,
  };

  try {
    const response = await generalAxiosInstance.post(
      "/GetUserKycStatuses",
      raw
    );
    return response.data;
  } catch (error) {
    return error.response?.data || "An error occurred";
  }
}
