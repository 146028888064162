import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, List, ListItemText } from "@mui/material";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import { cloneElement } from "react";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
  darkMode: PropTypes.bool,
};

export default function NavSection({ data = [], darkMode, ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} darkMode={darkMode} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  darkMode: PropTypes.bool,
};

function NavItem({ item, darkMode }) {
  const { title, path, icon, info, subMenu } = item;

  return (
    <>
      <StyledNavItem
        component={subMenu ? "div" : RouterLink} // Tıklanabilirliğini kaldırmak için component'i 'div' olarak ayarlıyoruz
        to={subMenu ? undefined : path} // Submenu varsa 'to' prop'unu kaldırıyoruz
        sx={{
          color: "text.navsec",
          "&.active": {
            color: "navText",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }}
      >
        <StyledNavItemIcon>
          {icon && cloneElement(icon, { darkMode })}
        </StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {info && info}
      </StyledNavItem>
      {subMenu && (
        <List component="div" disablePadding sx={{ pl: 4 }}>
          {subMenu.map((subItem) => (
            <StyledNavItem
              key={subItem.title}
              component={RouterLink}
              to={subItem.path}
              sx={{
                color: "text.navsec",
                "&.active": {
                  color: "navText",
                  bgcolor: "action.selected",
                  fontWeight: "fontWeightBold",
                },
              }}
            >
              <StyledNavItemIcon>
                {subItem.icon && cloneElement(subItem.icon, { darkMode })}
              </StyledNavItemIcon>
              <ListItemText disableTypography primary={subItem.title} />
            </StyledNavItem>
          ))}
        </List>
      )}
    </>
  );
}
