import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import { flxclmncntr, titledvdr } from "src/theme/customStyles";
import { pageContainer } from "src/utils/customStyles";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { handleExportData, handleExportRows } from "src/utils/helpers/helper";
import { CreateVendorManagers } from "src/services/DrawerApis.js/CreateVendorManagersApis";
import { GetVendorManagers } from "src/services/DrawerApis.js/VendorManagersApis";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";

const VendorManagers = () => {
  const [vendor, setVendor] = useState({
    VendorSuffix: "",
    VendorEmail: "",
    VendorUserName: "",
  });

  const [modalVisibility, setModalVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([
    {
      name: "İsim Soy İsim",
      mail: "isim@goldtag.org",
    },
    {
      name: "Ad Soyad",
      mail: "adsoyad@goldtag.org",
    },
  ]);

  const [change, setChange] = useState("vendor");

  const handleChangeVendor = (prop) => (event) => {
    setVendor({ ...vendor, [prop]: event.target.value });
    console.log(vendor);
    console.log("----");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Submitting", vendor);
  };

  const handleModal = (e) => {
    setModalVisibility(false);
    setVendor({ VendorSuffix: "", VendorEmail: "", VendorUserName: "" });
    console.log(vendor);
  };

  const handleChange = (newValue) => {
    setChange(newValue);
  };

  const fetchCreateVendorManager = async () => {
    try {
      const response = await CreateVendorManagers(
        vendor.VendorSuffix,
        vendor.VendorEmail,
        vendor.VendorUserName
      );

      if (response.Success) {
        setModalVisibility(true);
        console.log("Create Vendor Manager:", vendor);
      }
    } catch (error) {
      console.error("Error fetching Create Vendor Manager:", error);
    }
  };

  const fetchVendorManager = async () => {
    try {
      const response = await GetVendorManagers();

      if (response.Success) {
        const data = response.Data;

        setData(data);
        console.log("Vendor Manager: xxxxxxx", data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching Vendor Manager:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchVendorManager();
  }, [change]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "VendorManagerName",
        header: "İsim",
        size: 200,
      },
      {
        accessorKey: "Email",
        header: "Mail",
        size: 200,
      },
      {
        accessorKey: "VendorSuffix",
        header: "Vendor",
        size: 200,
      },
      {
        accessorKey: "ModifiedDate",
        header: "Oluşturma Tarihi",
        size: 200,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          marginBottom: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(data)}
          startIcon={<FileDownloadIcon />}
        >
          Tüm Verileri Dışa Aktar
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Tüm Satırları Dışa Aktar
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Bu Sayfadaki Satırları Dışa Aktar
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Seçilmiş Satırları Dışa Aktar
        </Button>
      </Box>
    ),
  });

  return (
    <Box sx={pageContainer}>
      <Typography
        variant="h3"
        sx={{
          letterSpacing: "3px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
          paddingLeft: "3rem",
        }}
      >
        Vendor Yöneticileri
        <Divider
          sx={{
            width: "100px",
            backgroundColor: "primary.goldtag",
            height: 2,
          }}
        />
      </Typography>

      <Box
        sx={{
          justifyContent: "space-evenly",
          display: "flex",
          gap: 0.5,
        }}
      >
        <Button
          sx={{
            backgroundColor: change === "vendor" ? "#7A4F01" : "default",
            // width: "585px",
          }}
          onClick={() => handleChange("vendor")}
          variant="contained"
          fullWidth
        >
          Vendor Yöneticisi Ekle
        </Button>
        <Button
          sx={{
            backgroundColor: change === "kullanıcılar" ? "#7A4F01" : "default",
            //width: "585px",
          }}
          onClick={() => handleChange("kullanıcılar")}
          variant="contained"
          fullWidth
        >
          Kullanıcılar Tablosu
        </Button>
      </Box>
      {change === "kullanıcılar" && (
        <Box sx={{ mt: 5 }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "500px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingOverlay isOpen={loading} />
            </Box>
          ) : (
            <MaterialReactTable table={table} />
          )}
        </Box>
      )}
      {change === "vendor" && (
        <Card
          sx={{
            width: { md: "30rem", xs: "20rem" },
            mx: "auto",
            mt: 5,
          }}
        >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Vendor Yöneticisi Ekle
            </Typography>
            <Divider
              sx={{
                width: "10%",
                backgroundColor: "primary.goldtag",
                height: 3,
              }}
            />
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 4 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Vendor Suffix"
                    value={vendor.VendorSuffix}
                    onChange={handleChangeVendor("VendorSuffix")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Vendor Email"
                    value={vendor.VendorEmail}
                    onChange={handleChangeVendor("VendorEmail")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Vendor Kullanıcı İsmi"
                    value={vendor.VendorUserName}
                    onChange={handleChangeVendor("VendorUserName")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => fetchCreateVendorManager()}
                  >
                    Vendor Yöneticisi Ekle
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      )}
      <Modal
        open={modalVisibility}
        onClose={() => handleModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{
          backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.5)" } },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 400,
            height: 200,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 3,
            p: 4,
            borderRadius: "13px",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Yönetici Başarıyla Oluşturuldu!
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default VendorManagers;
