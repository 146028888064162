import { generalAxiosInstance } from "../axios/axiosConfig";

export async function CreateVendorManagers(
  VendorSuffix,
  VendorEmail,
  VendorUserName
) {
  const raw = {
    VendorSuffix: VendorSuffix,
    VendorManagerEmail: VendorEmail,
    VendorManagerName: VendorUserName,
  };
  try {
    const response = await generalAxiosInstance.post(
      "/CreateVendorManager",
      raw
    );
    return response.data;
  } catch (error) {
    return error.response?.data || "An error occurred";
  }
}
