import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import gsap from "gsap"; // GSAP kütüphanesini ekledik
import { useSelector } from "react-redux";
import {
  bold14,
  cardss,
  semibold12,
  semibold16,
  semibold18,
} from "src/theme/customStyles";
import { getBackgroundColor } from "src/utils/helpers/helper";

const CustomCards = ({ item, index }) => {
  const darkMode = useSelector((state) => state.darkMode.value);

  useEffect(() => {
    if (item.length > 0) {
      gsap.from(".custom-card", {
        duration: 1,
        opacity: 0,
        y: -40,
        ease: "power3.out",
        stagger: 0.2,
      });
    }
  }, [item]);

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={11}
      key={index}
      sx={[
        cardss,
        {
          background: getBackgroundColor(0),
          webkitBoxShadow: !darkMode
            ? "0px 15px 26px 0px #76716a99"
            : "0px 15px 26px 0px #f6f6f65c",
          mozBoxShadow: !darkMode
            ? "0px 15px 26px 0px #76716a99"
            : "0px 15px 26px 0px #f6f6f65c",
          boxShadow: !darkMode
            ? "0px 15px 26px 0px #76716a99"
            : "0px 15px 26px 0px #f6f6f65c",
          width: { xs: "100%", sm: "100%", md: "100%" },
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          padding: "16px",
          minHeight: "140px",
          height: "145px",
        },
      ]}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "120px",
          //backgroundColor: "blue",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            fontWeight: "bold",
            textAlign: "left",
            color: "common.white",
            fontSize: "16px",
            marginTop: "-15px",
            marginBottom: "10px",
            wordWrap: "break-word",
            width: { lg: "160px", md: "100px", xs: "180px" },
          }}
        >
          <Typography sx={bold14}>{item.title}</Typography>
          <Box
            sx={{
              width: "50px",
              height: "2px",
              mt: "10px",
              bgcolor: "#eecd8a",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "50px",
            // backgroundColor: "red",
            mt: "10px",
          }}
        >
          <Box sx={{ mr: "17px" }}>{item.icon}</Box>
          <Typography
            sx={{
              color: "#5a5a5a",
              fontWeight: "600",
              // backgroundColor: "yellow",
              textAlign: "left",
              marginRight: "40px",
              width: "100px",
              wordBreak: "break-word", /////////
              whiteSpace: "normal",
              wordWrap: "break-word",
              fontSize: item.num.length > 7 ? "20px" : "25px",
            }}
          >
            {item.num}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default CustomCards;
