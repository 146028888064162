import {
  Box,
  Button,
  Card,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TransactionTypeSelect from "src/components/Select/TransactionTypeSelect";
import VendorSelect from "src/components/Select/VendorSelect";
import { CancelTransactionByGoldtag } from "src/services/api";

const CancelTransactionCard = ({
  setLoading,
  setOpenAlert,
  setAlertMessage,
  setAlertStatus,
}) => {
  const [vendor, setVendor] = useState("");
  const [errorVendor, setErrorVendor] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({});

  const [transactionId, setTransactionId] = useState("");

  const [typeOfTransaction, setTypeOfTransaction] = useState("");
  const [errorType, setErrorType] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(false);

  const isCanceledTransaction = async () => {
    try {
      setLoading(true);
      const response = await CancelTransactionByGoldtag({
        VendorSuffix: vendor,
        TransactionId: Number(transactionId),
        TypeOfTransaction: typeOfTransaction,
      });
      if (response && response.Success) {
        setAlertMessage(response.Message);
        setAlertStatus(true);
        setOpenAlert(true);
        setResponseSuccess(true);
      } else {
        setAlertMessage(response.Message);
        setAlertStatus(false);
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertMessage("Bir şeyler ters gitti! | Catch ERROR");
      setAlertStatus(false);
      setOpenAlert(true);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setLoading(false);
      }, 2000);
      setTimeout(() => {
        setResponseSuccess(false);
      }, 4000);
      console.log("ID : " + transactionId);
    }
  };

  async function clearAll() {
    await setVendor("");
    await setTransactionId("");
    await setTypeOfTransaction("");
  }
  useEffect(() => {
    if (responseSuccess == true) {
      clearAll();
    }
  }, [responseSuccess]);

  const handleFetch = () => {
    if (vendor !== "" && transactionId !== "" && typeOfTransaction !== "") {
      isCanceledTransaction();
    }
  };

  return (
    <Card
      sx={{
        p: 3,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: { md: "30rem", xs: "20rem" },
        margin: "0 auto",
        marginTop: 5,
        minWidth: "20rem",
      }}
    >
      <Typography variant="h6" gutterBottom>
        İptal İşlemleri
      </Typography>
      <Divider
        sx={{
          width: "10%",
          backgroundColor: "primary.goldtag",
          height: 3,
          mb: 3,
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {/* VENDOR FORM CONTROL */}
        <VendorSelect
          vendor={vendor}
          setVendor={setVendor}
          errorVendor={errorVendor}
          setErrorVendor={setErrorVendor}
          setVendorInfo={setVendorInfo}
        />
        <TransactionTypeSelect
          type={typeOfTransaction}
          setType={setTypeOfTransaction}
          errorType={errorType}
          setErrorType={setErrorType}
        />
        <TextField
          id="outlined-basic"
          label="İşlem ID Giriniz"
          variant="outlined"
          type="number"
          value={transactionId}
          onChange={(e) => setTransactionId(e.target.value)}
        />

        <Button variant="contained" onClick={() => handleFetch()}>
          İptal Et
        </Button>
      </Box>
    </Card>
  );
};

export default CancelTransactionCard;
