import * as React from "react";
const IslmlerIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} {...props}>
    <path
      d="M12.5 4.1a7.284 7.284 0 0 1 6.1 3.15h-4v2.1h7.35V2h-2.1v3.434A9.359 9.359 0 0 0 12.5 2 10.5 10.5 0 0 0 2 12.5h2.1a8.4 8.4 0 0 1 8.4-8.4zm0 16.8a7.284 7.284 0 0 1-6.1-3.15h4v-2.1H3.05V23h2.1v-3.434A9.359 9.359 0 0 0 12.5 23 10.5 10.5 0 0 0 23 12.5h-2.1a8.4 8.4 0 0 1-8.4 8.4z"
      data-name="24 / arrows / refresh-cw"
      style={{
        fill: props.darkMode ? "#383838" : "#fff",
        fillRule: "evenodd",
      }}
      transform="translate(-2 -2)"
    />
  </svg>
);
export default IslmlerIcon;
