import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EmtiaSelect from "src/components/Select/EmtiaSelect";
import VendorSelect from "src/components/Select/VendorSelect";
import { AddBuySellTransactions } from "src/services/api";

const AddBuySellTransactionCard = ({
  setLoading,
  setOpenAlert,
  setAlertMessage,
  setAlertStatus,
}) => {
  const [vendor, setVendor] = useState("");
  const [errorVendor, setErrorVendor] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({});

  const [userId, setUserId] = useState(""); //string
  const [isBuy, setIsBuy] = useState(null); //string
  const [tlAmount, setTlAmount] = useState("");

  const [emtia, setEmtia] = React.useState("");
  const [errorEmtia, setErrorEmtia] = useState(false);
  const [selectedEmtia, setSelectedEmtia] = useState(false);

  const [emtiaAmount, setEmtiaAmount] = useState("");
  const [errorIsBuy, setErrorIsBuy] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(false);

  const AddBuySell = async () => {
    try {
      const response = await AddBuySellTransactions({
        VendorSuffix: vendor,
        UserId: userId,
        IsBuy: isBuy,
        CommoditySuffix: emtia,
        CommodityAmount: emtiaAmount,
        TlAmount: tlAmount,
      });

      if (response && response.Success) {
        setAlertMessage(response.Message);
        setAlertStatus(true);
        setOpenAlert(true);
        setResponseSuccess(true);
      } else {
        setAlertMessage(response.Message);
        setAlertStatus(false);
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertMessage("Bir şeyler ters gitti! | Catch ERROR");
      setAlertStatus(false);
      setOpenAlert(true);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setLoading(false);
      }, 2000);
      setTimeout(() => {
        setResponseSuccess(false);
      }, 4000);
    }
  };

  async function clearAll() {
    setVendor("");
    setUserId("");
    setIsBuy("");
    setEmtiaAmount("");
    setEmtia("");
    setTlAmount("");
  }
  useEffect(() => {
    if (responseSuccess == true) {
      clearAll();
    }
  }, [responseSuccess]);

  const handleFetch = () => {
    if (
      vendor !== "" ||
      userId !== "" ||
      isBuy !== "" ||
      emtiaAmount !== "" ||
      emtia !== "" ||
      tlAmount !== ""
    ) {
      AddBuySell();
    }
  };

  const handleChangeIsBuy = (e) => {
    setIsBuy(e.target.value);
    setErrorIsBuy(false);
  };

  const handleEmtiaAmountChange = (e) => {
    const value = e.target.value;
    setEmtiaAmount(value);
    if (value > 0) {
      setTlAmount(0);
    }
  };

  const handleTlAmountChange = (e) => {
    const value = e.target.value;
    setTlAmount(value);
    if (value > 0) {
      setEmtiaAmount(0);
    }
  };

  return (
    <Card
      sx={{
        p: 3,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: { md: "30rem", xs: "20rem" },
        margin: "0 auto",
        marginTop: 5,
        minWidth: "20rem",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Alım ve Bozdurma İşlemleri Ekle
      </Typography>
      <Divider
        sx={{
          width: "10%",
          backgroundColor: "primary.goldtag",
          height: 3,
          mb: 3,
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {/* VENDOR FORM CONTROL */}
        <VendorSelect
          vendor={vendor}
          setVendor={setVendor}
          errorVendor={errorVendor}
          setErrorVendor={setErrorVendor}
          setVendorInfo={setVendorInfo}
        />

        <TextField
          id="outlined-basic"
          label="Kullanıcı ID Giriniz"
          variant="outlined"
          type="text"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
        />

        <FormControl
          sx={{ minWidth: 155 }}
          variant={"outlined"}
          error={errorIsBuy}
        >
          <InputLabel id="demo-simple-select-label">
            İşlem Türü Seçiniz
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={isBuy}
            label="İşlem Türü Seçiniz"
            onChange={handleChangeIsBuy}
          >
            <MenuItem value={true}>Alım</MenuItem>
            <MenuItem value={false}>Bozdurma</MenuItem>
          </Select>
        </FormControl>

        {/* EMTIA FORM CONTROL */}
        <EmtiaSelect
          emtia={emtia}
          setEmtia={setEmtia}
          errorEmtia={errorEmtia}
          setErrorEmtia={setErrorEmtia}
          setSelectedEmtia={setSelectedEmtia}
        />
        <TextField
          id="outlined-basic"
          label="Emtia Miktarını Giriniz"
          variant="outlined"
          type="number"
          value={emtiaAmount}
          onChange={handleEmtiaAmountChange}
        />

        <TextField
          id="outlined-basic"
          label="TL Miktarını Giriniz"
          variant="outlined"
          type="number"
          value={tlAmount}
          onChange={handleTlAmountChange}
        />

        <Button variant="contained" onClick={() => handleFetch()}>
          Ekle
        </Button>
      </Box>
    </Card>
  );
};

export default AddBuySellTransactionCard;
