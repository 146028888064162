import { Box, Button, Snackbar, Alert } from "@mui/material";
import React, { useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { handleExportData, handleExportRows } from "src/utils/helpers/helper";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import DateRangePicker from "src/components/DatePicker/DateRangePicker";
import { formatDate } from "src/utils/helpers/helper";
import { GetUserEvents } from "src/services/AdminPanelApis/EventsApi";
import dayjs from "dayjs";

const UserEventTable = () => {
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [errorEnd, setErrorEnd] = useState(false);
  const [errorStart, setErrorStart] = useState(false);

  const [startDate, setStartDate] = useState(dayjs("01/01/2018"));
  const [endDate, setEndDate] = useState(dayjs("09/09/2024"));

  const [startDay, setStartDay] = React.useState("");
  const [startMonth, setStartMonth] = React.useState("");
  const [startYear, setStartYear] = React.useState("");

  const [endDay, setEndDay] = React.useState("");
  const [endMonth, setEndMonth] = React.useState("");
  const [endYear, setEndYear] = React.useState("");

  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
  });

  const [data, setData] = useState([]);
  const [boolean, setBoolean] = useState(false);

  const fetchUserEvents = async () => {
    try {
      const response = await GetUserEvents({
        UserId: "-1",
        Page: pagination.page,
        PageSize: pagination.pageSize,
        StartDate: startDate
          ? startDate
          : `${startYear}-${startMonth}-${startDay}`,
        EndDate: endDate ? endDate : `${endYear}-${endMonth}-${endDay}`,
      });
      if (response.Success) {
        const data = response.Data;
        const array = data.map((item) => ({
          Id: item.Id,
          EventName: item.EventName,
          EventDescription: item.EventDescription,
          EventDate: formatDate(item.EventDate),
          IsActive: item.IsActive == true ? "true" : "false",
        }));
        setData(array);
      } else {
        console.error("API error: ", response.Message);
        setOpenAlert(true);
      }
    } catch (error) {
      console.error("Error fetching EventsApi:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlefetch = () => {
    if (startDate == null) {
      setOpenAlert(true);
    } else if (endDate == null) {
      setOpenAlert(true);
    } else {
      setLoading(true);
      setBoolean(true);
      fetchUserEvents();
    }
  };

  const handlePaginationChange = (pagination) => {
    setPagination(pagination);
  };

  const handleBack = () => {
    const newPage = pagination.page - 1;
    if (newPage > 0) {
      setPagination({ page: newPage, pageSize: 10 });
    }
    console.log(pagination.page);
  };

  const handleNext = () => {
    const newPage = pagination.page + 1;
    setPagination({ page: newPage, pageSize: 10 });
    console.log(pagination.page);
  };

  React.useEffect(() => {
    if (pagination.page) {
      handlefetch();
    }
  }, [pagination.page]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "Id",
        header: "Id",
        size: 220,
      },
      {
        accessorKey: "EventName",
        header: "Etkinlik İsmi",
        size: 80,
      },
      {
        accessorKey: "EventDescription",
        header: "Etkinlik Açıklaması",
        size: 180,
      },
      {
        accessorKey: "EventDate",
        header: "Etkinlik Tarihi",
        size: 80,
      },
      {
        accessorKey: "IsActive",
        header: "Aktiflik Durumu",
        size: 20,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    state: { pagination },
    enableBottomToolbar: false,
    manualPagination: true,
    onPaginationChange: handlePaginationChange,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          marginBottom: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(data, "Transferler")}
          startIcon={<FileDownloadIcon />}
        >
          Tüm Verileri Dışa Aktar
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(
              table.getPrePaginationRowModel().rows,
              "Transferler"
            )
          }
          startIcon={<FileDownloadIcon />}
        >
          Tüm Satırları Dışa Aktar
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() =>
            handleExportRows(table.getRowModel().rows, "Transferler")
          }
          startIcon={<FileDownloadIcon />}
        >
          Bu Sayfadaki Satırları Dışa Aktar
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() =>
            handleExportRows(table.getSelectedRowModel().rows, "Transferler")
          }
          startIcon={<FileDownloadIcon />}
        >
          Seçilmiş Satırları Dışa Aktar
        </Button>
      </Box>
    ),
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <Box>
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            gap: "1rem",
            marginBottom: "3rem",
          }}
        >
          {/* DATE PICKER */}
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setStartDay={setStartDay}
            setStartMonth={setStartMonth}
            setStartYear={setStartYear}
            setEndDay={setEndDay}
            setEndMonth={setEndMonth}
            setEndYear={setEndYear}
            errorStart={errorStart}
            errorEnd={errorEnd}
            setErrorStart={setErrorStart}
            setErrorEnd={setErrorEnd}
            type={"range"}
          />

          <Button
            variant="contained"
            onClick={handlefetch}
            sx={{ marginLeft: 3, height: 35 }}
          >
            Listele
          </Button>
        </Box>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "500px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingOverlay isOpen={loading} />
          </Box>
        ) : boolean ? (
          <>
            <MaterialReactTable table={table} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: 2,
              }}
            >
              <Button
                variant="contained"
                onClick={handleBack}
                sx={{ marginLeft: 3, height: 35 }}
              >
                Geri
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ marginLeft: 3, height: 35 }}
              >
                İleri
              </Button>
            </Box>
          </>
        ) : null}
      </>
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          Tüm alanları doldurun!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserEventTable;
