import * as React from "react";
const VendorIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} {...props}>
    <path
      fill={props.darkMode ? "#383838" : "#fff"}
      fillRule="evenodd"
      d="M10.5 21A10.5 10.5 0 1 1 21 10.5 10.5 10.5 0 0 1 10.5 21Zm7.063-5.608a8.591 8.591 0 1 0-14.126 0c1.074-1.419 3.6-2.028 7.063-2.028s5.989.609 7.063 2.028Zm-1.37 1.542c-.279-1-2.286-1.661-5.694-1.661s-5.414.656-5.694 1.661a8.591 8.591 0 0 0 11.387 0Zm-5.693-3.57c-2.137 0-3.818-1.5-3.818-4.773A3.676 3.676 0 0 1 10.5 4.773a3.819 3.819 0 0 1 3.818 4.009c0 3.13-1.701 4.582-3.818 4.582ZM8.591 8.591c0 2.166.781 2.864 1.909 2.864s1.909-.67 1.909-2.673a1.927 1.927 0 0 0-1.909-2.1 1.779 1.779 0 0 0-1.909 1.909Z"
    />
  </svg>
);
export default VendorIcon;
